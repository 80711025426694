import React from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

import { Column, Content, Text, scrollToTop } from '../../components'
import { LightTheme } from '../../theme'
import { Locale, useLocale } from '../../providers'
import { ContactUs } from '../main'
import { EnPrivacy } from './en-privacy'

import { useStyle } from './privacy.styles'
import { Helmet } from 'react-helmet'

export const Privacy = () => {
  const classes = useStyle()
  const theme: LightTheme = useTheme()
  const { currentLocale } = useLocale()
  const isEN = currentLocale === Locale.EN

  const PRIVACY = [
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.title',
      preset: 'badge'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.head',
      preset: 'badge'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.extra',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.firstTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.first',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.secondTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.second',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.thirdTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.third',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.fourthTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.fourth',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.fifthTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.fifth',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.sixthTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.sixth',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.seventhTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.seventh',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.eighthTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.eighth',
      preset: 'markTitle'
    },
    {
      classes: classes.item,
      text: '',
      tx: 'privacy.ninthTitle',
      preset: 'badge'
    },
    {
      classes: classes.text,
      text: '',
      tx: 'privacy.ninth',
      preset: 'markTitle'
    }
  ]

  return (
    <>
      <Helmet>
        <title>Privacy</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="noindex, nofollow"/>
        <link rel="canonical" href="https://holovach.pro/privacy" />
        <link
          rel="alternate"
          href="https://holovach.pro/privacy/"
          hrefLang="en-US"
        />
      </Helmet>
      <Content className={classes.privacy}>
        <Column className={classes.container} alignItems="flex-start">
          {!isEN ? (
            PRIVACY.map((el: any, id: number) => (
              <Column key={`privacy-key-el-${id}`} fullWidth>
                <Text
                  className={el.classes}
                  text={el.text}
                  tx={el.tx}
                  preset={el.preset}
                  color="moderateBlue"
                />
              </Column>
            ))
          ) : (
            <EnPrivacy />
          )}
        </Column>
        <FontAwesomeIcon
          className={classes.up}
          icon={faArrowUp}
          height="50"
          width="50"
          color={theme.colors.text}
          onClick={scrollToTop}
        />
      </Content>
      <ContactUs />
    </>
  )
}
