import React, { useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import {
  Column,
  Row,
  Content,
  GradientTitle,
  ImageModal
} from '../../../../components'
import { useOutsideAlerter } from '../../../../hooks'
import { Review } from './review'

import { REVIEWS, REVIEWS_IMAGES } from './testimonials.types'
import { useStyle } from './testimonials.styles'

export const Testimonials = () => {
  const classes = useStyle()

  const [isImageModal, changeIsImageModal] = useState(false)
  const [modalImageSrc, changeModalImageSrc] = useState('')
  const ref = useRef<HTMLDivElement>(null)

  const handleOnOpenModal = (id: REVIEWS) => (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    changeModalImageSrc(REVIEWS_IMAGES[id])
    changeIsImageModal(true)
  }
  const handleOnCloseModal = () => changeIsImageModal(false)

  useOutsideAlerter(ref, isImageModal, handleOnCloseModal)

  return (
    <Column id="testimonials" className={classes.testimonials} fullWidth>
      <Content className={classes.container}>
        <Column fullWidth>
          <GradientTitle tx="testimonials.title" text="Testimonials" />
          <Row className={classes.list} fullWidth justifyContent="flex-start">
            <Review
              title="Project Manager"
              name="Tea T."
              review="Iryna joined our team at an entry-level position and wasted no time to show us that she had tremendous potential. We were all impressed with how quickly she was able to learn new complex workflows and technologies. She is hardworking and diligent while also being a great asset to the team and always available to help others. Working with her was a wonderful experience, as she is well organized, determined, and yet fun to be around."
              onModalOpen={handleOnOpenModal(REVIEWS.REVIEW_1)}
            />
            <Review
              title="Co-Founder"
              name="Andriy F."
              reviewTx="testimonials.review2"
              review="У нас інтернет-магазин спортивного взуття та одягу. Співпрацюємо з Іриною по товарній рекламі. Прискіпливий та уважний спеціаліст. Завжди надає зворотній зв'язок. Рухаємося далі."
              onModalOpen={handleOnOpenModal(REVIEWS.REVIEW_2)}
            />
            <Review
              title="Project Manager"
              name="Evgeniya O."
              reviewTx="testimonials.review3"
              review="Вдячна Ірині за її надихаючу роботу! Як кар'єрний консультант, вона проявила дивовижне розуміння моїх професійних цілей! Ірина створила підтримуючу та довірливу атмосферу, допомагаючи мені зрозуміти, який шлях для мене є більш важливим на даному етапі. Її щира зацікавленість і експертиза зробили цей досвід для мене неймовірно цінним. Однозначно продовжую співпрацю!"
              onModalOpen={handleOnOpenModal(REVIEWS.REVIEW_3)}
            />
            <Review
              title="Senior Business Analyst Lead"
              name="Michael K."
              reviewTx="testimonials.review4"
              review=""
              onModalOpen={handleOnOpenModal(REVIEWS.REVIEW_4)}
            />
            <Review
              title="Director, Delivery organization"
              name="Denys B."
              reviewTx="testimonials.review5"
              review=""
              onModalOpen={handleOnOpenModal(REVIEWS.REVIEW_5)}
            />
          </Row>
        </Column>

        {isImageModal && (
          <ImageModal ref={ref} onClose={handleOnCloseModal}>
            <LazyLoadImage
              className={classes.img}
              src={modalImageSrc}
              width="100%"
              height="100%"
              alt="Прискіпливий та уважний спеціаліст. Завжди надає зворотній зв'язок. Рухаємося далі. - holovach.pro"
            />
          </ImageModal>
        )}
      </Content>
    </Column>
  )
}
