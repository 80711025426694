import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  indicators: {
    marginTop: 'calc(80px - 32px)',
    padding: 0
  },
  content: {},
  circleIcon: {
    width: 70,
    height: 70,
    border: '2px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '50%'
  },
  description: {
    marginTop: '-16px',
    minHeight: 48
  },
  stars: {
    marginTop: 28
  },
  number: {
    textShadow: '1px 1px 1px #00000066'
  },
  img: {
    width: 400,
    borderRadius: '0 30px 0 0'
  },
  text: {
    textAlign: 'center'
  },
  container: {
    '& > *:not(:first-child)': {
      marginLeft: 24
    },
    flexWrap: 'wrap',
    rowGap: '20px',
    columnGap: '24px'
  },
  box: {
    minWidth: 186,
    width: 220
  },
  shadow: {
    boxShadow: '2px 117px 400px 92px #4e428e',
    filter: 'blur(60px)'
  },
  '@media screen and (min-width: 1590px)': {
    box: {
      minWidth: 186
    }
  },
  '@media screen and (max-width: 1200px)': {
    container: {
      '& > *:not(:first-child)': {
        marginLeft: 0
      },
      gap: 100,
      justifyContent: 'center'
    }
  },
  '@media screen and (max-width: 1004px)': {
    content: {
      flexDirection: 'column'
    },
    container: {
      gap: 78
    }
  },
  '@media screen and (max-width: 640px)': {
    container: {
      gap: 44
    }
  },
})
