import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { images } from '../../../assets'
import {
  Column,
  Content,
  Row,
  Text,
  concatClassNames
} from '../../../components'

import { useStyle } from './qualities.styles'

export const Qualities = () => {
  const classes = useStyle()

  return (
    <Column className={classes.qualities} fullWidth>
      <Content id="qualities" className={classes.indicators}>
        <Row>
          <Text
            className={classes.title}
            text="Why should you choose my services?"
            tx="advantages.title"
            preset="button"
            color="darkBlue"
          />
        </Row>
        <Row className={classes.content} fullWidth alignItems="flex-start">
          <Column
            className={classes.container}
            fullWidth
            alignItems="flex-start"
            justifyContent="space-around"
          >
            <Row className={classes.box}>
              <Row
                className={concatClassNames(
                  classes.description,
                  classes.rightIconPosition
                )}
              >
                <Text
                  className={concatClassNames(classes.text, classes.extra)}
                  tx="advantages.accountable.text"
                  text="Daily workflow updates"
                  preset="mark"
                  color="white"
                  values={{
                    accountable: (
                      <Text
                        className={concatClassNames(classes.text)}
                        tx="advantages.accountable"
                        text="Accountable"
                        color="white"
                      />
                    )
                  }}
                />
                <LazyLoadImage
                  className={concatClassNames(classes.img, classes.left)}
                  src={images.Emojii1}
                  width={'100%'}
                  height={'100%'}
                  alt="Web development service. Daily workflow updates"
                />
              </Row>
            </Row>

            <Row className={classes.box}>
              <Row
                className={concatClassNames(
                  classes.description,
                  classes.withoutIcon
                )}
              >
                <Text
                  className={concatClassNames(classes.text, classes.extra)}
                  tx="advantages.accessible.text"
                  text="Available daily for consultations in business time"
                  preset="mark"
                  color="white"
                  values={{
                    accessible: (
                      <Text
                        className={classes.text}
                        tx="advantages.accessible"
                        text="Accessible"
                        color="white"
                      />
                    )
                  }}
                />
              </Row>
            </Row>

            <Row className={classes.box}>
              <Row
                className={concatClassNames(
                  classes.description,
                  classes.leftIconPosition
                )}
              >
                <LazyLoadImage
                  className={concatClassNames(classes.img, classes.right)}
                  src={images.Emojii5}
                  width={'100%'}
                  height={'100%'}
                  alt="Creation of high-converting landing pages with a clean code base"
                />
                <Text
                  className={concatClassNames(classes.text, classes.extra)}
                  tx="advantages.meticulous.text"
                  text="Clean code for easy future scalability"
                  preset="mark"
                  color="white"
                  values={{
                    meticulous: (
                      <Text
                        className={concatClassNames(classes.text)}
                        tx="advantages.meticulous"
                        text="Meticulous"
                        color="white"
                      />
                    )
                  }}
                />
              </Row>
            </Row>

            <Row className={classes.box}>
              <Row
                className={concatClassNames(
                  classes.description,
                  classes.withoutIcon
                )}
              >
                <Text
                  className={concatClassNames(classes.text, classes.extra)}
                  tx="advantages.transparent.text"
                  text="Interested in long-term cooperation"
                  preset="mark"
                  color="white"
                  values={{
                    transparent: (
                      <Text
                        className={concatClassNames(classes.text)}
                        tx="advantages.transparent"
                        text="Transparent"
                        color="white"
                      />
                    )
                  }}
                />
              </Row>
            </Row>
          </Column>
        </Row>
      </Content>
    </Column>
  )
}
