import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  title: {
    padding: '18px 0',
    '& > *': {
      width: '100%'
    }
  },
  content: {
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  },
  checkIcon: {
    height: 50,
    width: 50
  },
  message: {
    textAlign: 'center'
  },
  close: {
    cursor: 'pointer'
  },
  cancel: {},
  agree: {
    marginLeft: 16
  },
  '@media screen and (max-width: 480px)': {
    cancel: {
      width: 128
    }
  }
})
