import React, { forwardRef } from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { LightTheme } from '../../theme'
import { Button } from '../button'
import { Column } from '../column'
import { Modal } from '../modal'
import { Text } from '../text'
import { Row } from '../row'

import { AgreeModalProps } from './agree-modal.types'
import { useStyle } from './agree-modal.styles'
import { Spinner } from '../spinner'

export const AgreeModal = forwardRef<HTMLDivElement, AgreeModalProps>(
  (
    {
      className = '',
      children,
      titleProps,
      isLoaded = false,
      isSent = false,
      onSubmit,
      onClose,
      onAutoClose = onClose
    },
    ref
  ) => {
    const theme: LightTheme = useTheme()
    const classes = useStyle({ theme })
    // TODO: add succesfull submit view

    const handleOnSubmit = () => {
      if (onSubmit) {
        onSubmit()
      }
      onClose()
    }

    return (
      <Modal ref={ref} onClose={onAutoClose}>
        <Column fullWidth fullHeight justifyContent="space-between">
          {!isLoaded ? (
            <>
              <Column className={classes.title} fullWidth>
                {titleProps && children && (
                  <Text {...titleProps} preset="body" color="background" />
                )}
              </Column>
              <Column
                className={`${className} ${classes.content}`}
                justifyContent="flex-start"
                fullHeight
                fullWidth
              >
                {children ||
                  (titleProps && (
                    <>
                      <Text {...titleProps} preset="body" color="background" />
                      {isSent && (
                        <Column fullHeight>
                          <Spinner />
                        </Column>
                      )}
                    </>
                  ))}
              </Column>
              <Row justifyContent="space-around" fullWidth>
                <Button
                  className={classes.cancel}
                  name="cancel"
                  preset="cancel"
                  onClick={onAutoClose}
                >
                  <Text text="Cancel" tx="btn.cancel" preset="badge" />
                </Button>
                <Button
                  className={classes.agree}
                  name="Ok"
                  type="submit"
                  preset="secondary"
                  onClick={handleOnSubmit}
                >
                  <Text text="Ok" tx="btn.ok" preset="badge" />
                </Button>
              </Row>
            </>
          ) : (
            <Column fullHeight>
              <Text
                className={classes.message}
                tx="modal.successMessage"
                text="Message was sent"
                preset="boldHeader"
                color="burgundi"
              />
              <Row>
                <FontAwesomeIcon
                  className={classes.checkIcon}
                  icon={faCheck}
                  color={theme.colors.violet}
                />
              </Row>
            </Column>
          )}
        </Column>
      </Modal>
    )
  }
)
