import React from 'react'

import { Column } from '../column'

import { GradientBoxProps } from './gradient-box.types'
import { useStyle } from './gradient-box.styles'

export const GradientBox = ({ className }: GradientBoxProps) => {
  const classes = useStyle()

  return (
    <Column
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    />
  )
}
