import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  background: {
    background: theme.colors.white
  },
  price: {
    zIndex: 2,
    padding: '80px 0'
  },
  container: {
    '& >:not(:first-child)': {
      marginLeft: 16
    }
  },
  values: {
    marginTop: 20,
    padding: 16
  },
  text: {
    textAlign: 'center'
  },
  value: {
    padding: '8px 12px'
  },
  description: {
    position: 'absolute',
    top: 48,
    whiteSpace: 'pre'
  },
  orderButton: {
    marginTop: 36
  },
  content: {},
  button: {
    height: 52,
    width: 200,
    background: theme.colors.straitGreen,
    borderRadius: 32,
    textDecoration: 'none',
    transition: 'all 300ms ease-in-out',
    cursor: theme.cursor,
    textAlign: 'center',
    lineHeight: '50px',
    '& > *': {
      padding: '0 8px',
      color: theme.colors.white,
      cursor: theme.cursor,
      textDecoration: 'none',
      whiteSpace: 'break-spaces'
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      cursor: 'default',
      background: theme.colors.straitGreen,
      '& > *': {
        color: theme.colors.text,
        cursor: 'default'
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.text
        }
      }
    },
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      transition: 'all 0.5s ease-in',
      outline: 'none',
      '& > *': {
        color: theme.colors.white
      }
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.white,
      '& > *': {
        color: theme.colors.white
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.white
        }
      }
    }
  },
  '@media screen and (max-width: 770px)': {
    content: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > *:not(:first-child)': {
        marginTop: 16
      }
    }
  }
}))
