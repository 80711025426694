import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Column, Text, Row, Content, GradientTitle, GradientRectangle } from '../../../components'
import { images } from '../../../assets'

import { useStyle } from './performance-example.styles'

export const PerformanceExample = () => {
  const classes = useStyle()

  return (
    <Column id="performance" className={classes.background} fullWidth>
      <Content className={classes.performance}>
        <Row className={classes.content} alignItems="flex-start" justifyContent="space-around">
          <Row className={classes.imgContainer}>
            <LazyLoadImage
              className={classes.img}
              src={images.PerformanceTest}
              width={'100%'}
              height={'100%'}
              alt="Analytics"
            />
            <GradientRectangle className={classes.gradient} />
          </Row>
          <Column className={classes.info}>
            <GradientTitle tx="services.performance.title" text="Performance" />
            <Text
              className={classes.text}
              text="Web application performance optimization"
              tx="services.performance.optimization"
              preset="lightBody"
            />
            <Text
              className={classes.text}
              text="Web application performance optimization"
              tx="services.performance.extra"
              preset="mark"
              color="text"
            />
          </Column>
        </Row>
      </Content>
    </Column>
  )
}
