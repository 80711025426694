import React, { FC } from 'react'

import { IconProps } from '../types'

import { images } from '../../../assets/images'

export const PersonalPreview: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={1910}
      height={926}
      viewBox="0 0 1910 926"
      fill="none"
      {...props}
    >
      <image width="100%" height="100%" xlinkHref={images.PersonalPreview} />
    </svg>
  )
}
