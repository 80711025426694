import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { scrollToTop } from '../components'

export const useScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' 
    })

    scrollToTop()
  }, [pathname])
}
