import React, { FC } from 'react'

import { IconProps } from '../types'

import { images } from '../../../assets/images'

export const Dots: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={1317}
      height={785}
      viewBox="0 0 1317 785"
      fill="none"
      {...props}
    >
      <image width="100%" height="100%" xlinkHref={images.Dots} />
    </svg>
  )
}
