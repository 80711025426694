import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    background: theme.colors.mostlyBlack
  },
  separator: {
    borderTop: '2px solid rgb(60 57 74 / 41%)',
    marginTop: 80
  },
  footer: {
    padding: '16px 0 8px'
  },
  copyright: {
    marginTop: 24
  },
  social: {
    '& > *:not(:first-child)': {
      marginLeft: 18
    }
  },
  link: {
    '& > *': {
      transition: 'color 0.3s ease-in-out'
    },
    '&:hover > *': {
      color: theme.colors.white,
      transition: 'color 0.3s ease-in-out'
    }
  },
  columnItems: {
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  },
  '@media screen and (max-width: 920px)': {
    container: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
}))
