import React from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faKey,
  faCubes
} from '@fortawesome/free-solid-svg-icons'

import { LightTheme } from '../../../../theme'
import { Column, Row, Text, Content } from '../../../../components'
import { useResponsive } from '../../../../providers'

import { useStyle } from './technologies.styles'

export const Technologies = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const { isTablet } = useResponsive()
  const titlePreset = isTablet ? 'boldTitle' : 'title'

  return (
    <Column fullHeight fullWidth className={classes.content}>
        <Content id="technologies" className={classes.container}>
          <Row
            className={classes.services}
            fullWidth
            justifyContent="space-around"
          >
            <Column className={`${classes.service} ${classes.filledService}`}>
              <Row fullWidth>
                <Row className={classes.circleIcon}>
                  <FontAwesomeIcon
                    icon={faCubes}
                    height="30"
                    width="30"
                    color={theme.colors.amethyst}
                  />
                </Row>
              </Row>
              <Text
                className={classes.title}
                preset={titlePreset}
                tx="technologies.title"
                text="Technologies"
                color="white"
              />
              <ul className={classes.list}>
                <li className={classes.item}>
                  <Text
                    text="HTML, CSS, JSS, Styled Components, JS, React, TS, Next.js"
                    color="text"
                    preset="mark"
                  />
                </li>
                <li className={classes.item}>
                  <Text
                    text="Redux, Saga/Thunk, NestJS"
                    color="text"
                    preset="mark"
                  />
                </li>
                <li className={classes.item}>
                  <Text
                    text="Google API, Facebook API"
                    color="text"
                    preset="mark"
                  />
                </li>
                <li className={classes.item}>
                  <Text text="Mongoose, Postman, Swagger" color="text" preset="mark" />
                </li>
                <li className={classes.item}>
                  <Text text="MySQL, MongoDB" color="text" preset="mark" />
                </li>
                <li className={classes.item}>
                  <Text text="Google Tools: Ads, Analytics, Search Console, Merchant Center, Tag Manager" color="text" preset="mark" />
                </li>
              </ul>
            </Column>
            <Column className={classes.service}>
              <Row fullWidth>
                <Row className={`${classes.circleIcon} ${classes.filledIcon}`}>
                  <FontAwesomeIcon
                    icon={faKey}
                    height="30"
                    width="30"
                    color={theme.colors.white}
                  />
                </Row>
              </Row>
              <Text
                className={classes.title}
                preset={titlePreset}
                tx="technologies.domains"
                text="Domains"
                color="white"
              />
              <ul className={classes.list}>
                <li className={classes.item}>
                  <Text
                    tx="technologies.domainsList"
                    text="Travel, Online services, E-commerce, AdTech."
                    color="text"
                    preset="mark"
                  />
                </li>
                <li className={classes.item}>
                  <Text
                    tx="technologies.welcome"
                    text="Always welcome: IoT, Logistics, Fintech"
                    color="text"
                    preset="mark"
                  />
                </li>
                <li className={classes.item}>
                  <Text
                    tx="technologies.exceptions"
                    text="Excluding: Gaming, Dating, Gambling, Betting, Cryptocurrency, Cybersport and Securities trading"
                    color="text"
                    preset="mark"
                  />
                </li>
              </ul>
            </Column>
          </Row>
        </Content>
    </Column>
  )
}
