import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  button: {
    padding: '20px 12px',
    marginTop: 14,
    height: 'auto',
    width: '268px!important',
    transition: 'height 0.3s ease-in-out'
  }
})
