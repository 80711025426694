import React, { useMemo } from 'react'

import { Column, Content, Text } from '../../components'
import {
  useNavigateLocation,
  useScrollToTop,
  useStoredLocale
} from '../../hooks'
import { ContactUs } from '../main'

import { useStyle } from './refund.styles'
import { Helmet } from 'react-helmet'

export const Refund = () => {
  const classes = useStyle()

  const REFUND = useMemo(
    () => [
      {
        classes: classes.item,
        text: '',
        tx: 'refund.title',
        preset: 'badge'
      },
      {
        classes: classes.item,
        text: '',
        tx: 'refund.lastUpdated',
        preset: 'lightCaption'
      },
      {
        classes: classes.item,
        text: '',
        tx: 'refund.firstTitle',
        preset: 'badge'
      },
      {
        classes: classes.text,
        text: '',
        tx: 'refund.first',
        preset: 'lightMark'
      },
      {
        classes: classes.item,
        text: '',
        tx: 'refund.secondTitle',
        preset: 'badge'
      },
      {
        classes: classes.text,
        text: '',
        tx: 'refund.second',
        preset: 'lightMark'
      },
      {
        classes: classes.item,
        text: '',
        tx: 'refund.thirdTitle',
        preset: 'badge'
      },
      {
        classes: classes.text,
        text: '',
        tx: 'refund.third',
        preset: 'lightMark'
      },
      {
        classes: classes.item,
        text: '',
        tx: 'refund.fourthTitle',
        preset: 'badge'
      },
      {
        classes: classes.text,
        text: '',
        tx: 'refund.fourth',
        preset: 'lightMark'
      },
      {
        classes: classes.text,
        text: '',
        tx: 'refund.notes',
        preset: 'lightMark'
      }
    ],
    []
  )

  useScrollToTop()
  useNavigateLocation()
  useStoredLocale()

  return (
    <>
      <Helmet>
        <title>Refund Policy</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://holovach.pro/refund" />
        <link
          rel="alternate"
          href="https://holovach.pro/refund/"
          hrefLang="en-US"
        />
      </Helmet>
      <Content className={classes.refund}>
        <Column className={classes.container} alignItems="flex-start">
          {REFUND.map((el: any, id: number) => (
            <Column key={`refund-key-el-${id}`} fullWidth>
              <Text
                className={el.classes}
                text={el.text}
                tx={el.tx}
                preset={el.preset}
                color="moderateBlue"
              />
            </Column>
          ))}
        </Column>
      </Content>
      <ContactUs />
    </>
  )
}
