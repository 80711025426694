import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles(() => ({
  arrowAnim: {
    position: 'absolute',
    bottom: 0,
    width: '200px',
    height: '86px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(-90deg)',
    cursor: 'pointer'
  },
  arrow: {
    width: '24px',
    height: '24px',
    border: '10px solid',
    borderColor: '#5544a6 transparent transparent #5544a6',
    transform: 'rotate(-45deg)'
  },
  arrowSliding: {
    position: 'absolute',
    '-webkit-animation': '$slide 4s linear infinite',
    animation: '$slide 4s linear infinite'
  },
  delay1: {
    '-webkit-animation-delay': '1s',
    animationDelay: '1s'
  },
  delay2: {
    '-webkit-animation-delay': '2s',
    animationDelay: '2s'
  },
  delay3: {
    '-webkit-animation-delay': '3s',
    animationDelay: '3s'
  },
  '@-webkit-keyframes slide': {
    from: { opacity: 0, transform: 'translateX(125px)' },
    '20%': { opacity: 1, transform: 'translateX(79px)' },
    '80%': { opacity: 1, transform: 'translateX(-79px)' },
    to: { opacity: 0, transform: 'translateX(-125px)' }
  },
  '@keyframes slide': {
    from: { opacity: 0, transform: 'translateX(125px)' },
    '20%': { opacity: 1, transform: 'translateX(79px)' },
    '80%': { opacity: 1, transform: 'translateX(-79px)' },
    to: { opacity: 1, transform: 'translateX(-125px)' }
  }
}))
