import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons'

import {
  Column,
  Row,
  Text,
  Content,
  Link,
  Button
} from '../../../../components'
import { useResponsive } from '../../../../providers'

import { useStyle } from './banner.styles'

export const Banner = () => {
  const classes = useStyle()
  const { isTablet, isMobile } = useResponsive()

  const mainTitlePreset = isTablet ? 'h3' : 'h2'
  const titlePreset = isMobile ? 'description' : mainTitlePreset

  return (
    <Column id="main" fullHeight fullWidth className={classes.content}>
      <Column fullWidth className={classes.container}>
        <Content className={classes.brief}>
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              className={classes.h1}
              preset="h1"
              tx="seo.main.description"
              text="Services from web developer - website development, corrections, and adding new functional elements, as well as maintenance. Development and advertising."
            />
            <Text
              className={classes.about}
              preset={titlePreset}
              tx="main.tagline"
              text="Software development within budget and timeline"
              color="white"
            />
            <Text
              className={classes.about}
              preset="mark"
              tx="main.description"
              text="Connect with your customers online and provide them \nwith the tools to make informed decisions through superior service quality."
              color="grayishBlue"
            />
            {/* <Link name="Go to services" href="#promo-price">
              <Row className={classes.link}>
                <Text
                  preset="largeBadge"
                  color="violet"
                  tx="btn.goToServices"
                  text="Go to services"
                />
                <Row className={classes.arrowRight}>
                  <FontAwesomeIcon
                    icon={faLongArrowRight}
                    height="30"
                    width="30"
                  />
                </Row>
              </Row>
            </Link> */}

            <Button
              className={classes.link}
              name="Go to services"
              preset="accent"
            >
              <Link name="Go to services" href="#promo-price">
                <Text
                  preset="largeBadge"
                  color="white"
                  tx="btn.goToServices"
                  text="Go to services"
                />
                <FontAwesomeIcon
                  className={classes.arrowRight}
                  icon={faLongArrowRight}
                  height="30"
                  width="30"
                />
              </Link>
            </Button>
          </Column>
        </Content>
      </Column>
      <Row className={classes.shadow} />
    </Column>
  )
}
