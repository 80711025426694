import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  faCircleInfo,
  faHandPointer,
  faWindowMaximize
} from '@fortawesome/free-solid-svg-icons'

import { Column, Row, concatClassNames } from '../../../../../components'
import { useResponsive } from '../../../../../providers'

import { Orientation, ScreenProps } from './screen.types'
import { useStyle } from './screen.styles'

export const Screens = ({
  isFullDisabled = false,
  src,
  alt,
  orientation = Orientation.HORIZONTAL,
  children,
  onModalOpen,
}: ScreenProps) => {
  const classes = useStyle()
  const { isTablet } = useResponsive()
  const icon = isTablet ? faHandPointer : faCircleInfo
  const imageOrientationClass =
    orientation === Orientation.HORIZONTAL
      ? classes.horizontal
      : classes.vertical

  return (
    <Column className={classes.item}>
      <Row
        className={concatClassNames(
          imageOrientationClass,
          classes.imgContainer
        )}
      >
        <FontAwesomeIcon
          className={classes.handIcon}
          icon={icon}
          height={30}
          width={30}
        />
        {!isFullDisabled && (
          <FontAwesomeIcon
            className={classes.modalIcon}
            icon={faWindowMaximize}
            height={30}
            width={30}
            onClick={onModalOpen}
          />
        )}
        <LazyLoadImage
          className={classes.img}
          src={src}
          width="100%"
          height="100%"
          alt={alt}
        />
        <Column className={classes.overlay} />
      </Row>
      {children}
    </Column>
  )
}
