import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  background: {
    background: theme.colors.white
  },
  indent: {
    marginTop: 140
  },
  consultation: {
    zIndex: 2,
    padding: '80px 0',
    scrollMarginTop: '50px'
  },
  container: {
    '& >:not(:first-child)': {
      marginLeft: 16
    }
  },
  free: {
    background: theme.colors.cyan,
    borderRadius: 6,
    padding: 6
  },
  call: {
    textAlign: 'center',
    lineHeight: 1.5
  },
  phoneInput: {
    marginTop: 24,
    '& > *': {
      width: 230,
      '&::-webkit-inner-spin-button': {
        display: 'none'
      }
    }
  },
  send: {
    borderRadius: 10,
    '& > *': {
      height: '1.8em'
    },
    '&:hover': {
      cursor: 'pointer'
    },
    '&:hover > *': {
      color: theme.colors.violet
    }
  },
  text: {
    position: 'absolute',
    bottom: '-4px',
    left: '22px'
  }
}))
