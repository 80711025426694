import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../../theme'
import { IconProps } from '../types'

export const CheckboxInactive: FC<IconProps> = (props) => {
  const theme: LightTheme = useTheme()
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2667 1C13.3285 1 15 2.67147 15 4.73333V11.2667C15 13.3285 13.3285 15 11.2667 15H4.73333C2.67147 15 1 13.3285 1 11.2667V4.73333C1 2.67147 2.67147 1 4.73333 1H11.2667Z"
        fill={theme.colors.white}
        stroke={props.fill || theme.colors.button}
        strokeWidth={2}
      />
    </svg>
  )
}
