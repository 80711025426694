import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  item: {
    padding: 14
  },
  handIcon: {
    position: 'absolute',
    right: 12,
    top: 12,
    zIndex: 3,
    color: theme.colors.mostlyBlack
  },
  modalIcon: {
    position: 'absolute',
    right: 12,
    bottom: 12,
    zIndex: 4,
    color: theme.colors.mostlyBlack,
    cursor: 'pointer'
  },
  vertical: {
    height: 416,
    width: 250
  },
  horizontal: {
    height: 250,
    width: 416
  },
  imgContainer: {
    textAlign: 'center',
    transition: '.5s ease-in-out',
    boxShadow: '2px 4px 6px 1px #00000033',
    borderRadius: 12,
    '&:hover > $overlay': {
      opacity: 0.94,
      transition: '.5s ease-in-out'
    },
    '&:hover > $handIcon': {
      color: theme.colors.darkBlue,
      transition: '.5s ease-in-out'
    },
    '&:hover > $modalIcon': {
      color: theme.colors.white,
      transition: '.5s ease-in-out'
    },
    '&:hover + span': {
      visibility: 'visible',
      transition: '.5s ease-in-out'
    },
    '&:hover': {
      transition: '.5s ease-in-out',
      boxShadow: '0px 0px 30px 0px rgb(0 0 0 / 20%)'
    }
  },
  overlay: {
    position: 'absolute',
    borderRadius: 10,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    transition: '.5s ease-in-out',
    backgroundColor: theme.colors.darkBlue,
    boxShadow: '1px 2px 4px rgb(0, 0, 0, 0.6)',
    zIndex: 2,
    overflow: 'hidden'
  },
  img: {
    borderRadius: 10,
    zIndex: 2,
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  '@media screen and (max-width: 1180px)': {
    list: {
      flexDirection: 'column'
    }
  },
  '@media screen and (max-width: 890px)': {
    vertical: {
      height: 400,
      width: 'auto'
    },
    item: {
      padding: '14px 0'
    }
  },
  '@media screen and (max-width: 630px)': {
    horizontal: {
      width: 330,
      height: 240
    },
    vertical: {
      width: 250,
      height: 368
    }
  }
}))
