import { TNavigationProps } from './navigation.types'

export const HOME_NAVIGATION: TNavigationProps = [
  {
    tx: 'nav.home',
    text: 'Main',
    id: '#main',
  },
  {
    tx: 'nav.about',
    text: 'About',
    id: '#about-me'
  },
  { tx: 'projects.title', text: 'Long-term projects', id: '#projects' },
  { tx: 'projects.screens', text: 'Created Projects', id: '#screens' },
  { tx: 'header.services', text: 'Services', id: '/services' },
  { tx: 'testimonials.title', text: 'Testimonials', id: '#testimonials' },
]

export const SERVICES_NAVIGATION: TNavigationProps = [
  { tx: 'nav.dev', text: 'Development', id: '#development' },
  { tx: 'nav.performance', text: 'Performance', id: '#performance' },
  { tx: 'nav.ads', text: 'Advertising', id: '#advertising' },
  { tx: 'nav.intro', text: 'Intro', id: '#advantages' },
  { tx: 'nav.price', text: 'Price', id: '#price' },
  { tx: 'nav.steps', text: 'Steps', id: '#steps' },
  { tx: 'projects.screens', text: 'Created Projects', id: '#screens' },
  { tx: 'nav.advantages', text: 'Advantages', id: '#qualities' },
  { tx: 'nav.consultation', text: 'Free call', id: '#consultation' },
  { tx: 'faq.title', text: 'Who is it suitable for?', id: '#questions' }
]
