import React, { useState, useCallback, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from 'react-jss'
import emailjs from '@emailjs/browser'

import { LightTheme } from '../../../theme'
import {
  Column,
  Row,
  Content,
  Text,
  Button,
  concatClassNames,
  AgreeModal,
  Input,
  GradientTitle,
  getIsAllFilled,
  Checkbox,
  Spinner
} from '../../../components'
import { useOutsideAlerter } from '../../../hooks'

import { OffersProps } from './offers.types'
import { useStyle } from './offers.styles'

export enum OfferIds {
  REVIEW = 'review',
  LANDING = 'landing',
  FIX = 'fix',
  SHOPPING_ADS = 'shoppingAds',
  SEARCH_ADS = 'searchAds',
  FULL_PACK = 'fullPack',
  MIN_LANDING = 'minLanding'
}

export const OffersList = {
  1: OfferIds.REVIEW,
  2: OfferIds.LANDING,
  3: OfferIds.FIX,
  4: OfferIds.SHOPPING_ADS,
  5: OfferIds.SEARCH_ADS,
  6: OfferIds.FULL_PACK,
  7: OfferIds.MIN_LANDING
}

export type OffersListIds = {
  1: OfferIds.REVIEW
  2: OfferIds.LANDING
  3: OfferIds.FIX
  4: OfferIds.SHOPPING_ADS
  5: OfferIds.SEARCH_ADS
  6: OfferIds.FULL_PACK
  7: OfferIds.MIN_LANDING
}

export const OFFER_AVAILABILITY = {
  [OfferIds.REVIEW]: true,
  [OfferIds.LANDING]: true,
  [OfferIds.FIX]: true,
  [OfferIds.SHOPPING_ADS]: true,
  [OfferIds.SEARCH_ADS]: true,
  [OfferIds.FULL_PACK]: true,
  [OfferIds.MIN_LANDING]: true
}

export type OfferTypes = [
  {
    id: 1
    type: 1
    text: OfferIds.REVIEW
  },
  {
    id: 2
    type: ''
    text: OfferIds.LANDING
  },
  {
    id: 3
    type: ''
    text: OfferIds.FIX
  },
  {
    id: 4
    type: ''
    text: OfferIds.SHOPPING_ADS
  },
  {
    id: 5
    type: ''
    text: OfferIds.SEARCH_ADS
  },
  {
    id: 6
    type: ''
    text: OfferIds.FULL_PACK
  },
  {
    id: 7
    type: ''
    text: OfferIds.MIN_LANDING
  }
]

const SETTINGS = {
  SERVICE_ID: 'service_moomeii',
  TEMPLATE_ID: 'template_zjz0zns',
  PUBLIC_KEY: 'ahm2XV3-mtCbBzA20'
}

export const BASIC_USER_INFO = {
  email: '',
  userName: '',
  phone: '',
  agree: false
}

export const BASIC_ORDER_INFO = {
  id: '',
  name: ''
}

export const Offers = ({ isVisible = true }: OffersProps) => {
  const classes = useStyle()
  const theme: LightTheme = useTheme()
  const form = useRef<HTMLFormElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const [isLoaded, changeIsLoaded] = useState(false)
  const [isSent, changeIsSent] = useState(false)
  const [orderInfo, changeOrderInfo] = useState(BASIC_ORDER_INFO)
  const [openModal, changeOpenModal] = useState(false)
  const [userInfo, changeUserInfo] = useState(BASIC_USER_INFO)

  const handleOnChange = (id: string) => (value: any) => {
    changeUserInfo({
      ...userInfo,
      [id]: value
    })
  }

  const handleOnCheckboxChange = (id: string) => (status: boolean) => {
    // TODO: move to separate event repeated code
    changeUserInfo({
      ...userInfo,
      [id]: status
    })
  }

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    []
  )

  const handleOnAutoClose = useCallback(() => {
    handleOnOpenModal(false)()
    changeUserInfo(BASIC_USER_INFO)
  }, [handleOnOpenModal])

  const sendEmail = () => {
    emailjs
      .sendForm(
        SETTINGS.SERVICE_ID,
        SETTINGS.TEMPLATE_ID,
        form.current || '',
        SETTINGS.PUBLIC_KEY
      )
      .then(
        () => {
          // console.log(result.text)
          changeIsLoaded(true)
          changeIsSent(false)
        },
        () => {
          changeIsLoaded(true)
          changeIsSent(false)
          // console.log(error.text)
        }
      )
  }

  const handleOnSubmit = () => {
    // TODO: align email
    // const lowerCaseEmail = email.toLowerCase()
    const isFilled = getIsAllFilled(userInfo)
    if (!isFilled) {
      return
    }

    changeIsSent(true)
    sendEmail()
    changeUserInfo(BASIC_USER_INFO)

    if (form) {
      form?.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
    }
  }

  const handleOnClick = (key: keyof OffersListIds) => () => {
    // @ts-ignore
    changeOrderInfo({ ...orderInfo, id: Number(key), name: OffersList[key] })
    handleOnOpenModal(true)()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoaded) {
        handleOnOpenModal(false)()
        changeIsLoaded(false)
      }
    }, 1500)

    return () => clearTimeout(timer)
  }, [isLoaded])

  useOutsideAlerter(ref, openModal, handleOnAutoClose)

  if (!isVisible) {
    return <></>
  }

  // TODO: REWRITE COMPONENT
  return (
    <Row fullWidth className={classes.container}>
      <Content id="pricing" className={classes.pricing}>
        <Column fullWidth>
          <GradientTitle tx="offers.price" text="Price list" />
          <Text
            className={classes.choose}
            tx="offers.choose"
            text="Choose service you need"
            preset="button"
            color="white"
          />
          <Row justifyContent="space-between" className={classes.offers}>
            <Column className={classes.offer}>
              <Row className={classes.gradient} fullHeight fullWidth />
              <Row className={classes.badge}>
                <Text
                  tx="offers.type1"
                  text="Review"
                  preset="regularMark"
                  color="burgundi"
                />
              </Row>
              <Row className={classes.price}>
                <Text
                  tx="from"
                  text="from"
                  preset="lightBody"
                  color="burgundi"
                />
                <Text
                  className={classes.priceIndent}
                  text="$60"
                  preset="header"
                  color="mostlyBlack"
                />
                <Text
                  className={classes.priceIndent}
                  tx="offers.forOneApp"
                  text="per app"
                  preset="lightBody"
                  color="burgundi"
                />
              </Row>
              <Text
                className={classes.text}
                tx="offers.type1.desc"
                text={`Incorrect page display, trouble with functionality, 
                hosting placement issues.`}
                color="burgundi"
                preset="mark"
              />
              <Column className={classes.text} fullWidth>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type1.item0"
                    text="1h call - requirements clarification"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type1.item2"
                    text="List of items to change, improve, remove"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
              </Column>
              <Row className={classes.orderButton} fullWidth>
                <Button
                  name="Order now"
                  preset="secondary"
                  className={classes.text}
                  onClick={handleOnClick(1)}
                >
                  <Text tx="btn.orderNow" text="Order now" />
                </Button>
              </Row>
            </Column>

            <Column
              className={concatClassNames(classes.offer, classes.largeOffer)}
            >
              <Row className={classes.gradient} fullHeight fullWidth />
              <Row className={classes.badge}>
                <Text
                  tx="offers.type2"
                  text="Landing Page Creation"
                  preset="regularMark"
                  color="straitGreen"
                />
              </Row>
              <Row className={classes.price}>
                <Text
                  className={classes.priceIndent}
                  text="$650"
                  preset="header"
                  color="mostlyBlack"
                />
              </Row>
              <Text
                className={classes.text}
                tx="offers.type2.desc"
                text={`Your design will be used to create a landing page.\n Privacy Policy, Terms and Conditions pages and launch assistance. \n
                Additional pages are available as an optional service.`}
                color="burgundi"
                preset="mark"
              />
              <Column className={classes.text} fullWidth>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type2.item1"
                    text="1,5h call - requirements clarification"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type2.item2"
                    text="Personal page, blocks, basic animation effect"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type2.item3"
                    text="0,5h call - demo, minor edits (alignments, indents, text, images)"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type2.item4"
                    text="Simple image editing in Photoshop"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
              </Column>
              <Row className={classes.orderButton} fullWidth>
                <Button
                  name="Order now"
                  preset="secondary"
                  className={classes.text}
                  onClick={handleOnClick(2)}
                >
                  <Text tx="btn.orderNow" text="Order now" />
                </Button>
              </Row>
            </Column>

            <Column className={classes.offer}>
              <Column className={classes.gradient} fullHeight fullWidth />
              <Row className={classes.badge}>
                <Text
                  tx="offers.type3"
                  text="Editing"
                  preset="regularMark"
                  color="cyanGradient"
                />
              </Row>
              <Row className={classes.price}>
                <Text
                  tx="from"
                  text="from"
                  preset="lightBody"
                  color="burgundi"
                />
                <Text
                  className={classes.priceIndent}
                  text="$80"
                  preset="header"
                  color="mostlyBlack"
                />
                <Text
                  className={classes.priceIndent}
                  tx="offers.forOneApp"
                  text="per app"
                  preset="lightBody"
                  color="burgundi"
                />
              </Row>
              <Text
                className={classes.text}
                tx="offers.type3.desc"
                text={`Incorrect page display, trouble with functionality, 
                hosting placement issues.`}
                color="burgundi"
                preset="mark"
              />
              <Column className={classes.text} fullWidth>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type3.item0"
                    text="1h call - requirements clarification"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type3.item2"
                    text="List of items to change, improve, remove"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type3.item1"
                    text="1,5h review"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type3.item3"
                    text="Applying suggested improvements"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
              </Column>
              <Row className={classes.orderButton} fullWidth>
                <Button
                  name="Order now"
                  preset="secondary"
                  className={classes.text}
                  onClick={handleOnClick(3)}
                >
                  <Text tx="btn.orderNow" text="Order now" />
                </Button>
              </Row>
            </Column>

            <Column className={classes.offer}>
              <Row className={classes.gradient} fullHeight fullWidth />
              <Row className={classes.badge}>
                <Text
                  tx="offers.type5"
                  text="Google Advertising"
                  preset="regularMark"
                  color="cyanGradient"
                />
              </Row>
              <Row className={classes.price}>
                <Text
                  className={classes.priceIndent}
                  text="$100"
                  preset="header"
                  color="mostlyBlack"
                />
                <Text
                  className={classes.priceIndent}
                  tx="offers.oneTimeSetting"
                  text="one time setting"
                  preset="lightBody"
                  color="burgundi"
                />
              </Row>
              <Text
                className={classes.text}
                tx="offers.type5.desc"
                text="Google Search advertising"
                preset="mark"
                color="burgundi"
              />
              <Column className={classes.text} fullWidth>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type5.item0"
                    text="Google Analytics, GTM, Search Console settings"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type5.item1"
                    text="Niche research"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type5.item2"
                    text="Product research"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                {/* <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type5.item3"
                    text="Competitor research"
                    preset="mark"
                    color="burgundi"
                  />
                </Row> */}
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type5.item4"
                    text="Creation and 1 month maintenance"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
              </Column>
              <Row className={classes.orderButton} fullWidth>
                <Button
                  name="Order now"
                  disabled={!OFFER_AVAILABILITY[OfferIds.SEARCH_ADS]}
                  preset="secondary"
                  className={classes.text}
                  onClick={handleOnClick(5)}
                >
                  {OFFER_AVAILABILITY[OfferIds.SEARCH_ADS] ? (
                    <Text tx="btn.orderNow" text="Order now" />
                  ) : (
                    <Text tx="btn.completedSet" text="Completed set" />
                  )}
                </Button>
              </Row>
            </Column>

            <Column
              className={concatClassNames(classes.offer, classes.largeOffer)}
            >
              <Row className={classes.gradient} fullHeight fullWidth />
              <Row className={classes.badge}>
                <Text
                  tx="offers.type7"
                  text="Minimal Landing Page"
                  preset="regularMark"
                  color="straitGreen"
                />
              </Row>
              <Row className={classes.price}>
                <Text
                  className={classes.priceIndent}
                  text="$500"
                  preset="header"
                  color="mostlyBlack"
                />
              </Row>
              <Text
                className={classes.text}
                tx="offers.type7.desc"
                text={`Your design will be used to create a landing page.\n Privacy Policy, Terms and Conditions pages and launch assistance. \n
                Additional pages are available as an optional service.`}
                color="burgundi"
                preset="mark"
              />
              <Column className={classes.text} fullWidth>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type7.item1"
                    text="1,5h call - requirements clarification"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type7.item2"
                    text="Personal page, blocks, basic animation effect"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type7.item3"
                    text="0,5h call - demo, minor edits (alignments, indents, text, images)"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type7.item4"
                    text="Simple image editing in Photoshop"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
              </Column>
              <Row className={classes.orderButton} fullWidth>
                <Button
                  name="Order now"
                  preset="secondary"
                  className={classes.text}
                  onClick={handleOnClick(2)}
                >
                  <Text tx="btn.orderNow" text="Order now" />
                </Button>
              </Row>
            </Column>

            <Column className={classes.offer}>
              <Row className={classes.gradient} fullHeight fullWidth />
              <Row className={classes.badge}>
                <Text
                  tx="offers.type6"
                  text="Creating + Advertising"
                  preset="regularMark"
                  color="straitGreen"
                />
              </Row>
              <Row className={classes.price}>
                <Text
                  text="from"
                  tx="from"
                  preset="lightBody"
                  color="burgundi"
                />
                <Text
                  className={classes.priceIndent}
                  text="$750"
                  preset="header"
                  color="mostlyBlack"
                />
              </Row>
              <Text
                className={classes.text}
                tx="offers.type6.desc"
                text="Creation of landing page or business webite or web application with 8 to 10 pages plus a listing and promotion with Google Ads."
                preset="mark"
                color="burgundi"
              />
              <Column className={classes.text} fullWidth>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type6.item0"
                    text="Web development"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
                <Row
                  fullWidth
                  justifyContent="flex-start"
                  className={classes.item}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    height="30"
                    width="30"
                    color={theme.colors.burgundi}
                  />
                  <Text
                    className={classes.service}
                    tx="offers.type6.item1"
                    text="Google advertising setup"
                    preset="mark"
                    color="burgundi"
                  />
                </Row>
              </Column>
              <Row className={classes.orderButton} fullWidth>
                <Button
                  name="Order now"
                  preset="secondary"
                  className={classes.text}
                  onClick={handleOnClick(6)}
                >
                  <Text tx="btn.orderNow" text="Order now" />
                </Button>
              </Row>
            </Column>

          </Row>

          {openModal && (
            <AgreeModal
              ref={ref}
              isLoaded={isLoaded}
              titleProps={{
                text: 'Please, fill in the form to order a service.',
                tx: 'modal.communication'
              }}
              onSubmit={handleOnSubmit}
              onClose={handleOnOpenModal}
              onAutoClose={handleOnAutoClose}
            >
              {/* @ts-ignore */}
              <form className={classes.form} ref={form} method="post">
                <Input
                  name="name"
                  className={classes.inputContainer}
                  classNameInput={classes.input}
                  isColored={false}
                  type="name"
                  placeholder="Name *"
                  placeholderTx="order.name"
                  defaultValue={userInfo.userName}
                  onChange={handleOnChange('userName')}
                />
                <Input
                  name="email"
                  className={classes.inputContainer}
                  classNameInput={classes.input}
                  isColored={false}
                  type="email"
                  placeholder="Email *"
                  placeholderTx="order.email"
                  defaultValue={userInfo.email}
                  onChange={handleOnChange('email')}
                />
                <Input
                  name="phone"
                  className={classes.inputContainer}
                  classNameInput={classes.input}
                  isColored={false}
                  type="number"
                  placeholder="Phone *"
                  placeholderTx="order.phone"
                  defaultValue={userInfo.phone}
                  onChange={handleOnChange('phone')}
                />
                <Checkbox
                  width="24"
                  height="24"
                  id="privacy"
                  text="I agree to the Privacy Policy and Public Offer."
                  tx="contact.privacy"
                  color="black"
                  preset="lightCaption"
                  defaultChecked={userInfo.agree}
                  disabled={false}
                  onChange={handleOnCheckboxChange('agree')}
                />
                <Input
                  name="order_id"
                  className={classes.invisible}
                  classNameInput={classes.input}
                  type="text"
                  defaultValue={orderInfo.id}
                />
                <Input
                  name="order_name"
                  className={classes.invisible}
                  classNameInput={classes.input}
                  type="text"
                  defaultValue={orderInfo.name}
                />
                {isSent && <Spinner className={classes.spinner} />}
              </form>
            </AgreeModal>
          )}
        </Column>
      </Content>
    </Row>
  )
}
