import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  values: {
    background: theme.colors.white
  },
  indicators: {
    padding: '60px 0',
    zIndex: 2,
    scrollMarginTop: '40px'
  },
  title: {
    textAlign: 'center'
  },
  content: {
    marginTop: 28
  },
  circleIcon: {
    borderRadius: '50%',
    transition: 'all ease 2s',
    '&:hover': {
      transform: 'rotateY(180deg)'
    }
  },
  icon: {
    height: 30,
    width: 30
  },
  container: {
    borderRadius: 12,
    border: `2px solid ${theme.colors.border}`,
    padding: '0 24px',
    background: '-webkit-linear-gradient(90deg, #392C76 1.06%, rgba(45, 18, 151, 0.89) 100%)',
    boxShadow: '2px 4px 6px 1px #00000033',
  },
  extra: {
    marginTop: 16
  },
  text: {
    textAlign: 'start'
  },
  description: {
    textAlign: 'start',
    marginLeft: 12
  },
  box: {
    flex: 1,
    padding: 6
  },
  shadow: {
    boxShadow: '2px 117px 400px 92px #6f62b5',
    filter: 'blur(60px)'
  },
  image: {},
  '@media screen and (max-width: 900px)': {
    container: {
      flexDirection: 'column',
      padding: '24px 24px 0'
    },
    image: {
      borderTop: '2px solid #392C76',
      width: '100%'
    }
  }
}))
