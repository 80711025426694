const LatoRegular = require('./lato-v24-latin_latin-ext-regular.woff2')
const LatoBold = require('./lato-v24-latin_latin-ext-700.woff2')
const MontserratRegular = require('./montserrat-v26-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2')
const MontserratSemiBold = require('./montserrat-v26-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-600.woff2')
const MontserratBold = require('./montserrat-v26-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700.woff2')

export {
  LatoRegular,
  LatoBold,
  MontserratRegular,
  MontserratSemiBold,
  MontserratBold
}
