import { SetStateAction, useEffect } from 'react'

import { Locale, useLocale } from '../providers'
import { useCurrentPage } from './useCurrentPage'

export const useStoredLocale = () => {
  const { isUALocale, isENLocale } = useCurrentPage()
  const { changeCurrentLocale } = useLocale()

  const setLocate = (locale: SetStateAction<Locale>) => {
    localStorage.setItem('locale', JSON.stringify(locale))
    changeCurrentLocale(locale)
  }

  const checkLocale = () => {
    if (isUALocale) {
      setLocate(Locale.UA)
    }
    if (isENLocale) {
      setLocate(Locale.EN)
    }
  }

  useEffect(() => {
    checkLocale()
  }, [])
}
