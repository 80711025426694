import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 0,
    width: '88%'
  },
  col: {
    '& > :not(:first-child)': {
      marginTop: 32
    }
  },
  navigation: {
    marginTop: 60,
    '& > *:not(:first-child)': {
      marginLeft: 40
    }
  },
  links: {
    scrollBehavior: 'smooth',
    marginTop: 30,
    '& > *': {
      cursor: 'pointer'
    },
    '& > *:hover, & > a > *:hover': {
      color: theme.colors.amethyst
    },
    '& > *:not(:first-child)': {
      marginTop: 4
    }
  },
  link: {
    scrollBehavior: 'smooth'
  },
  socialContainer: {
    width: 50,
    height: 30
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    width: 40,
    height: 40,
    borderRadius: 12,
    border: `1px solid ${theme.colors.violet}`,
    '&': {
      transition: 'height 0.3s ease-in-out, width 0.3s ease-in-out'
    },
    '&:hover': {
      width: 45,
      height: 45,
      transition: 'height 0.3s ease-in-out, width 0.3s ease-in-out',
      background: theme.colors.violet,
      '& > *': {
        color: theme.colors.white
      }
    }
  },
  list: {
    padding: '12px 0',
    marginLeft: '-8px',
    '& > :not(:first-child)': {
      marginLeft: 6
    }
  },
  '@media screen and (max-width: 970px)': {
    container: {
      width: 'auto'
    }
  },
  '@media screen and (max-width: 640px)': {
    navigation: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginLeft: 0,
        marginTop: 32
      }
    }
  }
}))
