import React from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMaximize, faStar } from '@fortawesome/free-solid-svg-icons'

import { LightTheme } from '../../../../../theme'
import { Column, Row, Text, GradientTitle } from '../../../../../components'

import { ReviewProps } from './review.types'
import { useStyle } from './review.styles'

export const Review = ({
  isFullDisabled = false,
  title,
  titleTx,
  name,
  nameTx,
  review,
  reviewTx,
  onModalOpen
}: ReviewProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()

  return (
    <Column className={classes.container} justifyContent="flex-start">
      <GradientTitle text={title} tx={titleTx} />
      <Row justifyContent="flex-start" fullWidth>
        <Text
          className={classes.name}
          text={name}
          tx={nameTx}
          preset="boldTitle"
          color="cyan"
        />
      </Row>
      <Row justifyContent="flex-start" fullWidth>
        <FontAwesomeIcon
          color={theme.colors.amethyst}
          icon={faStar}
          height={30}
          width={30}
        />
        <FontAwesomeIcon
          color={theme.colors.amethyst}
          icon={faStar}
          height={30}
          width={30}
        />
        <FontAwesomeIcon
          color={theme.colors.amethyst}
          icon={faStar}
          height={30}
          width={30}
        />
        <FontAwesomeIcon
          color={theme.colors.amethyst}
          icon={faStar}
          height={30}
          width={30}
        />
        <FontAwesomeIcon
          color={theme.colors.amethyst}
          icon={faStar}
          height={30}
          width={30}
        />
      </Row>
      <Text
        className={classes.review}
        text={review}
        tx={reviewTx}
        preset="mark"
        color="white"
      />
      {!isFullDisabled && (
        <FontAwesomeIcon
          className={classes.modalIcon}
          icon={faWindowMaximize}
          height={30}
          width={30}
          name={review}
          onClick={onModalOpen}
        />
      )}
    </Column>
  )
}
