import { SVGProps } from 'react'

export enum IconName {
  LOGO = 'logo',
  LOGO_CSE = 'logo-cse',
  LOGO_TEXT = 'logo-text',
  CHECK_BOX_ACTIVE = 'check-box-active',
  CHECK_BOX_INACTIVE = 'check-box-inactive',
  CLEAR = 'clear',
  PROGRAMMING = 'programming',
  SOLAR_PREVIEW = 'solar-preview',
  BUCKET_LIST_PREVIEW = 'bucket-list-preview',
  LOGIN_UI_PREVIEW = 'login-ui-preview',
  PHOTO_GALLERY = 'photo-gallery-preview',
  GENERATE_USERS = 'generate-users-preview',
  PERSONAL_PREVIEW = 'personal-preview',
  WHY_ME = 'why-me',
  EXPERIENCE = 'experience',
  DOTS = 'dots',
  INTRO = 'intro',
  REVIEW_1 = 'review-1'
}

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName
  value?: string
}
