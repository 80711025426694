import React from 'react'

import { Text } from '../../components'
import { useStyle } from './privacy.styles'

export const EnPrivacy = () => {
  const classes = useStyle()

  return (
    <>
      <Text
        className={classes.item}
        text="PRIVACY POLICY"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.item}
        text="Last updated: May 20, 2023"
        preset="lightCaption"
        color="black"
      />
      <Text
        className={classes.text}
        text="
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="
        We use your Personal data to provide and improve the Service. By using
        the service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
        ."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="1. INTERPRETATION AND DEFINITIONS"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.item}
        text="INTERPRETATION"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
        "
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.item}
        text="DEFINITIONS"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="For the purposes of this Privacy Policy:"
        preset="lightMark"
        color="black"
      />
      <ul>
        <li className={classes.text}>
          <strong>Account</strong>
          <Text
            text=" means a unique account created for You to
            access our service or parts of our service."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>Affiliate</strong>
          <Text
            text=' means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.'
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>Company</strong>
          <Text
            text=' (referred to as either "the
              Company", "We", "Us" or "Our" in
            this Agreement) refers to Iryna Holovach, selo Borozenka, Ukraine.
            '
            preset="lightMark"
            color="black"
          />
        </li>
        {/* <li>
          <p>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </p>
        </li> */}
        <li className={classes.text}>
          <strong>Country</strong>
          <Text text=" refers to: Ukraine" preset="lightMark" color="black" />
        </li>
        <li className={classes.text}>
          <strong>Device</strong>
          <Text
            text=" means any device that can access the Service
            such as a computer, a cellphone or a digital tablet."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>Personal Data</strong>
          <Text
            text=" is any information that relates to an
            identified or identifiable individual."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>Service</strong>
          <Text
            text=" refers to the Website."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>Service Provider</strong>
          <Text
            text=" means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>Usage Data</strong>
          <Text
            text=" refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit)."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>Website</strong>
          <Text
            text=" refers to Iryna Holovach, accessible from https://holovach.pro"
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>You</strong>
          <Text
            text=" means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable."
            preset="lightMark"
            color="black"
          />
        </li>
      </ul>

      <Text
        className={classes.item}
        text="2. COLLECTING AND USING YOUR PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.item}
        text="TYPES OF DATA COLLECTED"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.item}
        text="PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:"
        preset="lightMark"
        color="black"
      />
      <ul>
        <li>
          <Text
            className={classes.text}
            text="Email address"
            preset="lightMark"
            color="black"
          />
        </li>
        <li>
          <Text
            className={classes.text}
            text="First name and last name"
            preset="lightMark"
            color="black"
          />
        </li>
        <li>
          <Text
            className={classes.text}
            text="Usage Data"
            preset="lightMark"
            color="black"
          />
        </li>
      </ul>

      <Text
        className={classes.item}
        text="USAGE DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="Usage Data is collected automatically when using the Service."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
        "
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
        "
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="USE OF YOUR PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="The Company may use Personal Data for the following purposes:"
        preset="lightMark"
        color="black"
      />
      <ul>
        <li className={classes.text}>
          <strong>To provide and maintain our Service</strong>
          <Text
            text=", including to
            monitor the usage of our Service."
            preset="lightMark"
            color="black"
          />
        </li>
        {/* <li className={classes.text}>
          <strong>To manage Your Account:</strong>
          <Text
            text=" to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user."
            preset="lightMark"
            color="black"
          />
        </li> */}
        <li className={classes.text}>
          <strong>For the performance of a contract:</strong>
          <Text
            text=" the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>To contact You:</strong>
          <Text
            text=" To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding
            updates or informative communications related to the
            functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their
            implementation."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>To provide You</strong>
          <Text
            text=" with news, special offers and
            general information about other goods, services and events which we
            offer that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>To manage Your requests:</strong>
          <Text
            text=" To attend and manage Your requests to Us."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>For business transfers:</strong>
          <Text
            text=" We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>For other purposes:</strong>
          <Text
            text=" We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience."
            preset="lightMark"
            color="black"
          />
        </li>
      </ul>
      <Text
        className={classes.text}
        text="We may share Your personal information in the following situations:"
        preset="lightMark"
        color="black"
      />
      <ul>
        <li className={classes.text}>
          <strong>With Service Providers:</strong>
          <Text
            text=" We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to contact You."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>For business transfers:</strong>
          <Text
            text=" We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of Our business to another company."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>With Affiliates:</strong>
          <Text
            text=" We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>With business partners:</strong>
          <Text
            text=" We may share Your information
          with Our business partners to offer You certain products, services or
          promotions."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>With other users:</strong>
          <Text
            text=" when You share personal information
          or otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside."
            preset="lightMark"
            color="black"
          />
        </li>
        <li className={classes.text}>
          <strong>With Your consent:</strong>
          <Text
            text=" We may disclose Your personal
          information for any other purpose with Your consent."
            preset="lightMark"
            color="black"
          />
        </li>
      </ul>

      <Text
        className={classes.item}
        text="RETENTION OF YOUR PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="TRANSFER OF YOUR PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="Your information, including Personal Data, is processed at the
        Company's operating offices and in any other places where the
        parties involved in the processing are located. It means that this
        information may be transferred to — and maintained on — computers
        located outside of Your state, province, country or other governmental
        jurisdiction where the data protection laws may differ than those from
        Your jurisdiction."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="DELETE YOUR PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="You may contact Us to request delete any personal information that You have provided to Us."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="DISCLOSURE OF YOUR PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.item}
        text="BUSINESS TRANSACTIONS"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="LAW ENFORCEMENT"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency)."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="OTHER LEGAL REQUIREMENTS"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:"
        preset="lightMark"
        color="black"
      />
      <ul>
        <li>
          <Text
            className={classes.text}
            text="Comply with a legal obligation"
            preset="lightMark"
            color="black"
          />
        </li>
        <li>
          <Text
            className={classes.text}
            text="Protect and defend the rights or property of the Company"
            preset="lightMark"
            color="black"
          />
        </li>
        <li>
          <Text
            className={classes.text}
            text="Prevent or investigate possible wrongdoing in connection with the Service"
            preset="lightMark"
            color="black"
          />
        </li>
        <li>
          <Text
            className={classes.text}
            text="Protect the personal safety of Users of the Service or the public"
            preset="lightMark"
            color="black"
          />
        </li>
        <li>
          <Text
            className={classes.text}
            text="Protect against legal liability"
            preset="lightMark"
            color="black"
          />
        </li>
      </ul>

      <Text
        className={classes.item}
        text="SECURITY OF YOUR PERSONAL DATA"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="3. CHILDREN'S PRIVACY"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our storages."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="4. LINKS TO OTHER WEBSITES"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy
        Policy of every site You visit."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="5. CHANGES TO THIS PRIVACY POLICY"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page."
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text='We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last
        updated" date at the top of this Privacy Policy.'
        preset="lightMark"
        color="black"
      />
      <Text
        className={classes.text}
        text="You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page."
        preset="lightMark"
        color="black"
      />

      <Text
        className={classes.item}
        text="6. CONTACT US"
        preset="badge"
        color="black"
      />
      <Text
        className={classes.text}
        text="If you have any questions about this Privacy Policy, You can contact us:"
        preset="lightMark"
        color="black"
      />
      <ul>
        <li>
          <Text
            text="By email: i@holovach.pro"
            preset="lightMark"
            color="black"
          />
        </li>
        <li>
          <Text
            text="By visiting the bottom part of this page on our website: "
            preset="lightMark"
            color="black"
          />
          <a href="https://holovach.pro" rel="noopener noreferrer" target="_blank">
            holovach.pro
          </a>
        </li>
      </ul>
    </>
  )
}
