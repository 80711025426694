import React from 'react'
import { useTheme } from 'react-jss'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSkype, faLinkedin, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { MainRoutes } from '../../../../routes'
import { useCurrentPage } from '../../../../hooks'
import { LightTheme } from '../../../../theme'

import { concatClassNames } from '../../../helpers'
import { Content } from '../../../content'
import { Row } from '../../../row'
import { Column } from '../../../column'
import { Text } from '../../../text'
import { Link } from '../../../link'
import { HOME_NAVIGATION, SERVICES_NAVIGATION } from './mocks'

import { INavigationItemProps } from './navigation.types'
import { useStyle } from './navigation.styles'

export const Navigation = () => {
  const { isHome, isServices } = useCurrentPage()
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const navigate = useNavigate()
  const OFFER = process.env.REACT_APP_PUBLIC_OFFER_LINK || ''

  const onNavigate = (link: MainRoutes) => () => {
    navigate(link)
  }

  return (
    <Content className={classes.container}>
      <Row
        className={classes.navigation}
        fullWidth
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Column className={classes.col} alignItems="flex-start">
          <Column alignItems="flex-start">
            <Text
              tx="nav.categories"
              text="Categories"
              color="white"
              preset="body"
            />
            <Column className={classes.links} alignItems="flex-start">
              <Text
                className={classes.link}
                tx="nav.privacy"
                text="Privacy Policy"
                color="text"
                preset="mark"
                onClick={onNavigate(MainRoutes.PRIVACY)}
              />
              <Link
                name="Public Offer"
                rel="nofollow"
                target="_blank"
                href={OFFER}
              >
                <Text
                  tx="nav.publicOffer"
                  text="Public Offer"
                  color="text"
                  preset="mark"
                />
              </Link>
              <Text
                className={classes.link}
                tx="nav.refund"
                text="Refund Policy"
                color="text"
                preset="mark"
                onClick={onNavigate(MainRoutes.REFUND)}
              />
            </Column>
          </Column>

          <Column alignItems="flex-start">
            <Text
              tx="footer.contacts"
              text="Contacts"
              color="white"
              preset="body"
            />
            <Column className={classes.links} alignItems="flex-start">
              <Row>
                <Link
                  name="Email"
                  title="Email"
                  className={classes.link}
                  href="mailto:mail@irynaholovach.pro"
                >
                  <Text text="i@holovach.pro" preset="mark" />
                </Link>
              </Row>

              <Row className={classes.list}>
                <Row className={classes.socialContainer}>
                  <Link
                    className={concatClassNames(classes.social, classes.link)}
                    title="LinkedIn"
                    name="LinkedIn"
                    rel="nofollow"
                    target="_blank"
                    href="https://www.linkedin.com/in/irynaih/"
                  >
                    <FontAwesomeIcon
                      // @ts-ignore
                      icon={faLinkedin}
                      height="40"
                      width="40"
                      color={theme.colors.violet}
                    />
                  </Link>
                </Row>
                <Row className={classes.socialContainer}>
                  <Link
                    className={concatClassNames(classes.social, classes.link)}
                    title="Skype"
                    name="Skype"
                    rel="nofollow"
                    target="_blank"
                    href="skype:live:.cid.1e174900b5ed17bd?chat"
                  >
                    <FontAwesomeIcon
                      // @ts-ignore
                      icon={faSkype}
                      height="40"
                      width="40"
                      color={theme.colors.violet}
                    />
                  </Link>
                </Row>
                <Row className={classes.socialContainer}>
                  <Link
                    className={concatClassNames(classes.social, classes.link)}
                    title="Telegram"
                    name="Telegram"
                    rel="nofollow"
                    target="_blank"
                    href="https://t.me/holovachpro"
                  >
                    <FontAwesomeIcon
                      // @ts-ignore
                      icon={faTelegram}
                      height="40"
                      width="40"
                      color={theme.colors.violet}
                    />
                  </Link>
                </Row>
                <Row className={classes.socialContainer}>
                  <Link
                    className={concatClassNames(classes.social, classes.link)}
                    title="WhatsApp"
                    name="WhatsApp"
                    rel="nofollow"
                    target="_blank"
                    href="https://wa.me/+380989073147"
                  >
                    <FontAwesomeIcon
                      // @ts-ignore
                      icon={faWhatsapp}
                      height="40"
                      width="40"
                      color={theme.colors.violet}
                    />
                  </Link>
                </Row>
              </Row>
            </Column>
          </Column>
        </Column>

        {isHome && (
          <Column alignItems="flex-start">
            <>
              <Text
                tx="nav.navigation"
                text="Navigation"
                color="white"
                preset="body"
              />
              <Column className={classes.links} alignItems="flex-start">
                {HOME_NAVIGATION.map((item: INavigationItemProps) => (
                  <Link key={item.text} name={item.text} href={`${item.id}`}>
                    <Text
                      tx={item.tx}
                      text={item.text}
                      color="text"
                      preset="mark"
                    />
                  </Link>
                ))}
              </Column>
            </>
          </Column>
        )}

        {isServices && (
          <Column alignItems="flex-start">
            <>
              <Text
                tx="nav.navigation"
                text="Navigation"
                color="white"
                preset="body"
              />
              <Column className={classes.links} alignItems="flex-start">
                {SERVICES_NAVIGATION.map((item: any) => (
                  <Link key={item.text} name={item.text} href={`${item.id}`}>
                    <Text
                      tx={item.tx}
                      text={item.text}
                      color="text"
                      preset="mark"
                    />
                  </Link>
                ))}
              </Column>
            </>
          </Column>
        )}
      </Row>
    </Content>
  )
}
