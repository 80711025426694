import React, { forwardRef } from 'react'

import { Column } from '../column'

import { ContentProps, ContentTypeName } from './content.types'
import { useStyle } from './content.styles'

export const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ className, children, preset = 'large', id = '' }, ref) => {
    const width = window.outerWidth
    const isMiddleScreen = width < 1600 || preset === ContentTypeName.settings
    const alignedPreset = isMiddleScreen ? preset : ContentTypeName.extraLarge

    const classes = useStyle()
    const { [alignedPreset]: modifier } = classes

    return (
      <Column
        ref={ref}
        id={id}
        className={`${className} ${classes.container} ${modifier}`}
        justifyContent="flex-start"
      >
        {children}
      </Column>
    )
  }
)
