import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  privacy: {
    width: '100%',
    marginTop: 100
  },
  enPrivacy: {
    '& > *': {
      ...theme.typography.badge
    }
  },
  container: {
    padding: '32px 0'
  },
  item: {
    marginTop: 24,
    width: '100%',
    textAlign: 'center'
  },
  text: {
    marginTop: 12
  },
  up: {
    width: 30,
    height: 30,
    padding: 10,
    position: 'absolute',
    right: '-60px',
    bottom: 20,
    zIndex: 3,
    cursor: 'pointer',
    color: theme.colors.violet
  }
}))
