import React from 'react'

import {
  Column,
  Row,
  Content,
  Text,
  concatClassNames
} from '../../../../components'

import { useStyle } from './indicators.styles'

export const Indicators = () => {
  const classes = useStyle()

  return (
    <>
      <Content className={classes.indicators}>
        <Content className={classes.shadow} />
        <Row className={classes.content} fullWidth alignItems="flex-start">
          <Row
            className={classes.container}
            fullWidth
            alignItems="flex-start"
            justifyContent="space-around"
          >
            <Column className={classes.box}>
              <Text
                className={classes.number}
                text="21+"
                preset="largeText"
                color="amethyst"
              />
              <Text
                className={classes.description}
                tx="indicators.projects"
                text="projects"
                color="white"
              />
              <Text
                className={concatClassNames(classes.text, classes.stars)}
                tx="indicators.projects.desc"
                text={
                  'Creating application architecture, front-end, full-stack application development, markups, ongoing project support.'
                }
                preset="mark"
                color="text"
              />
            </Column>

            <Column className={classes.box}>
              <Text
                className={classes.number}
                text="6"
                preset="largeText"
                color="amethyst"
              />
              <Text
                className={concatClassNames(classes.description, classes.text)}
                tx="indicators.years"
                text="years of professional education"
                color="white"
              />
              <Text
                className={concatClassNames(classes.text, classes.stars)}
                tx="indicators.years.desc"
                text={
                  'Engineering, creation and\n analysis of computer systems'
                }
                preset="mark"
                color="text"
              />
            </Column>

            <Column className={classes.box}>
              <Text
                className={classes.number}
                text="10+"
                preset="largeText"
                color="amethyst"
              />
              <Text
                className={concatClassNames(classes.description, classes.text)}
                tx="indicators.experience"
                text="years of experience"
                color="white"
              />
              <Text
                className={concatClassNames(classes.text, classes.stars)}
                tx="indicators.experience.desc"
                text="Product, outsourcing and outstaffing companies"
                preset="mark"
                color="text"
              />
            </Column>

            <Column className={classes.box}>
              <Text
                className={classes.number}
                text="4"
                preset="largeText"
                color="amethyst"
              />
              <Text
                className={concatClassNames(classes.description, classes.text)}
                tx="indicators.lang"
                text="languages"
                color="white"
              />
              <Text
                className={concatClassNames(classes.text, classes.stars)}
                tx="indicators.lang.desc"
                text="UA, RU, EN, DE"
                preset="mark"
                color="text"
              />
            </Column>
          </Row>
        </Row>
      </Content>
    </>
  )
}
