import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  testimonials: {
    background: 'linear-gradient(0deg, #4e428e 50%, rgb(138 133 163 / 0%)) 50.25%',
  },
  container: {
    padding: '80px 0'
  },
  gradientBox: {
    marginLeft: 8
  },
  list: {
    marginTop: 28,
    flexWrap: 'wrap',
    rowGap: '22px',
    columnGap: '22px'
  },
  review: {
    borderRadius: 10
  },
  img: {
    zIndex: 2,
    height: '100%',
    width: '60%',
    objectFit: 'cover'
  },
  '@media screen and (max-width: 720px)': {
    review: {
      borderRadius: 8,
      width: '90%'
    }
  },
  '@media screen and (max-width: 500px)': {
    img: {
      width: '80%'
    }
  },
})
