export enum Locale {
  EN = 'en',
  UA = 'ua',
  RU = 'ru'
}

export type StructuredMessages = Record<string, string>

export type LocaleProviderProps = {
  children: JSX.Element
  locale?: Locale
}

export type LocaleContextValue = {
  currentLocale: Locale
  localeData: Record<Locale, Record<string, string>>
  changeCurrentLocale: React.Dispatch<React.SetStateAction<Locale>>
}
