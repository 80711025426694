import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.button,
    bottom: 0,
    flex: 'none',
    height: 180,
    left: 0,
    position: 'absolute',
    right: 'auto',
    top: 'auto',
    width: 240,
    zIndex: 0,
    maxWidth: '100%'
  }
}))
