import React, { FC } from 'react'

import { IconName, IconProps } from './types'

import {
  Logo,
  CheckboxActive,
  CheckboxInactive,
  Clear,
  Programming,
  SolarPreview,
  BucketListPreview,
  LoginUIPreview,
  PhotoGalleryPreview,
  GenerateUsersPreview,
  PersonalPreview,
  WhyMe,
  Experience,
  Dots,
  Intro,
  Review1
} from './icons'

export const Icon: FC<IconProps> = (props) => {
  switch (props.name) {
    case IconName.LOGO:
      return <Logo {...props} />
    case IconName.CHECK_BOX_ACTIVE:
      return <CheckboxActive {...props} />
    case IconName.CHECK_BOX_INACTIVE:
      return <CheckboxInactive {...props} />
    case IconName.CLEAR:
      return <Clear {...props} />
    case IconName.PROGRAMMING:
      return <Programming {...props} />
    case IconName.SOLAR_PREVIEW:
      return <SolarPreview {...props} />
    case IconName.BUCKET_LIST_PREVIEW:
      return <BucketListPreview {...props} />
    case IconName.LOGIN_UI_PREVIEW:
      return <LoginUIPreview {...props} />
    case IconName.PHOTO_GALLERY:
      return <PhotoGalleryPreview {...props} />
    case IconName.GENERATE_USERS:
      return <GenerateUsersPreview {...props} />
    case IconName.PERSONAL_PREVIEW:
      return <PersonalPreview {...props} />
    case IconName.WHY_ME:
      return <WhyMe {...props} />
    case IconName.EXPERIENCE:
      return <Experience {...props} />
    case IconName.DOTS:
      return <Dots {...props} />
    case IconName.INTRO:
      return <Intro {...props} />
    case IconName.REVIEW_1:
      return <Review1 {...props} />

    default:
      return <div />
  }
}
