import Main from './main.webp'
import MainMobile from './main-mobile.webp'
import About from './about.webp'
import Review1 from './review-1.webp'
import Review2 from './review-2.webp'
import Review3 from './review-3.webp'
import Review4 from './review-4.webp'
import Review5 from './review-5.webp'
import Background2 from './background-2.jpg'
import PerformanceTest from './performance-test.webp'
import Code from './code.webp'
import Analytics from './analytics.webp'
import Programming from './programming.webp'
import BucketListApp from './bucket-list-app.webp'
import BucketListPreview from './bucket-list-preview.webp'
import SolarPreview from './solar-preview.webp'
import SolarLanding from './solar-landing.webp'
import LoginUIPreview from './login-ui-preview.webp'
import PhotoGalleryPreview from './photo-gallery-preview.webp'
import GenerateUsersPreview from './generate-users-preview.webp'
import PersonalPreview from './personal-preview.webp'
import PersonalLanding from './personal-landing.webp'
import WhyMe from './why-me.webp'
import Experience from './experience.webp'
import Dots from './dots.webp'
import Intro from './intro.webp'
import Emojii1 from './emojii-1.webp'
import Emojii2 from './emojii-2.webp'
import Emojii3 from './emojii-3.webp'
import Emojii4 from './emojii-4.webp'
import Emojii5 from './emojii-5.webp'

export const images: any = {
  Main,
  MainMobile,
  About,
  Review1,
  Review2,
  Review3,
  Review4,
  Review5,
  Background2,
  PerformanceTest,
  Code,
  Analytics,
  Programming,
  BucketListApp,
  SolarPreview,
  SolarLanding,
  BucketListPreview,
  LoginUIPreview,
  PhotoGalleryPreview,
  GenerateUsersPreview,
  PersonalPreview,
  PersonalLanding,
  WhyMe,
  Experience,
  Dots,
  Intro,
  Emojii1,
  Emojii2,
  Emojii3,
  Emojii4,
  Emojii5
}
