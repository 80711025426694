import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  projects: {
    marginTop: 80
  },
  gradientBox: {
    marginLeft: 8
  },
  background: {
    position: 'absolute',
    background: theme.colors.mostlyBlack,
    height: 800,
    zIndex: 1
  },
  content: {
    zIndex: 2,
    marginTop: 80,
    padding: 0
  },
  brief: {
    width: '30%',
    '& > *:not(:first-child)': {
      marginTop: 28
    }
  },
  list: {
    width: '70%',
    flexWrap: 'wrap',
    rowGap: '24px',
    columnGap: '44px'
  },
  project: {
    flex: 0,
    background: theme.colors.background,
    borderRadius: 10,
    padding: '44px 34px',
    width: 284,
    minWidth: 284,
    boxShadow: '2px 4px 6px 1px #00000033',
    transition: 'all ease 1.5s',
    '&:hover': {
      transform: 'translateY(-8px)'
    }
  },
  description: {
    marginTop: 30
  },
  location: {
    marginTop: 20
  },
  extra: {
    marginTop: 24
  },
  small: {
    padding: '40px 30px'
  },
  container: {},
  '@media screen and (min-width: 1200px) and (max-width: 1400px)': {
    brief: {
      width: 300
    },
    project: {
      padding: '30px 20px 30px'
    },
    list: {
      width: 'calc(100% - 300px)'
    }
  },
  '@media screen and (max-width: 1200px)': {
    container: {
      flexDirection: 'column'
    },
    brief: {
      width: '100%'
    },
    list: {
      width: '100%',
      marginTop: 24,
      gap: '86px',
      rowGap: '20px'
    },
    project: {
      padding: 28,
      minWidth: 240,
      width: 240
    }
  },
  '@media screen and (max-width: 1200px) and (min-width: 700px)': {
    project: {
      padding: 28,
      minWidth: 326,
      width: 275
    }
  },
  '@media screen and (max-width: 970px)': {
    list: {
      gap: 24
    },
    project: {
      flex: 1
    }
  }
}))
