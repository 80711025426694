import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  qualities: {
    background: theme.colors.white
  },
  indicators: {
    padding: 0,
    marginBottom: '-80px',
    paddingTop: 60,
    zIndex: 2,
    scrollMarginTop: '40px'
  },
  content: {
    marginTop: 28
  },
  circleIcon: {
    borderRadius: '50%',
    height: 74,
    minWidth: 74,
    background: theme.colors.button,
    transition: 'all ease 2s',
    boxShadow: '1px 1px 1px 1px #00000066',
    '&:hover': {
      transform: 'rotateY(180deg)'
    }
  },
  icon: {
    height: '1.5em'
  },
  container: {
    maxWidth: 700
  },
  extra: {
    whiteSpace: 'pre-line'
  },
  title: {
    textAlign: 'center'
  },
  text: {
    textAlign: 'start'
  },
  description: {
    marginLeft: 14,
    border: `2px solid ${theme.colors.border}`,
    borderRadius: 12,
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    padding: 24,
    background: '-webkit-linear-gradient(90deg, #392C76 1.06%, rgba(45, 18, 151, 0.89) 100%)',
    boxShadow: '2px 4px 6px 1px #00000033'
  },
  leftIconPosition: {
    paddingLeft: 140
  },
  rightIconPosition: {
    paddingRight: 140
  },
  withoutIcon: {
    marginLeft: 80
  },
  box: {
    padding: 24
  },
  img: {
    position: 'absolute',
    width: 140,
    height: 130,
    bottom: -2
  },
  left: {
    right: 0,
  },
  right: {
    left: 0,
  },
  '@media screen and (max-width: 900px)': {
    box: {
      padding: '16px 0'
    }
  }
}))
