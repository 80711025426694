import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Column, Row, Text, Content, GradientTitle } from '../../../components'
import { images } from '../../../assets'

import { useStyle } from './development-service.styles'

export const DevelopmentService = () => {
  const classes = useStyle()

  return (
    <Column id="development" className={classes.background} fullWidth>
      <Content className={classes.services}>
        <GradientTitle tx="header.services" text="Сервіси" color="darkBlue" />
        <Row
          className={classes.container}
          justifyContent="space-around"
          alignItems="flex-start"
          fullHeight
          fullWidth
        >
          <Column className={classes.suggestion}>
            <Row>
              <Text
                className={classes.number}
                text="1"
                preset="letter"
                color="cancel"
              />
              <Column>
                <Column className={classes.item}>
                  <Text
                    text="Web development"
                    tx="services.dev"
                    preset="buttonSmall"
                    color="burgundi"
                  />
                  <Text
                    className={classes.label}
                    text="from 1 week"
                    tx="services.dev.feature"
                    preset="lightCaption"
                    color="white"
                  />
                </Column>
              </Column>
            </Row>
            <Column fullWidth alignItems="flex-start">
              <Text
                className={classes.serviceTitle}
                text="New projects"
                tx="services.newProjects"
                color="burgundi"
              />
              <Text
                className={classes.serviceText}
                text="Landing pages, complex and multi-page web applications development, SPA (Single Page Applications, websites on Wordpress"
                tx="services.newProjects.description"
                preset="mark"
                color="mostlyBlack"
              />

              <Text
                className={classes.serviceTitle}
                text="Ongoing project fixes"
                tx="services.ongoingProjects"
                color="burgundi"
              />
              <Text
                className={classes.serviceText}
                text="Non-working functional component fixes, optimization, download speed increase, creation of new UI elements, UX improvement"
                tx="services.ongoingProjects.description"
                preset="mark"
                color="mostlyBlack"
              />
            </Column>
          </Column>
          <Row className={classes.imgContainer}>
            <LazyLoadImage
              className={classes.img}
              src={images.Code}
              width={'100%'}
              height={'100%'}
              alt="Code"
            />
          </Row>
        </Row>
      </Content>
    </Column>
  )
}
