import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  fullWidth: {
    width: '100%'
  },
  input: {
    ...theme.typography.markTitle,
    padding: '15px 25px 15px',
    outline: 0,
    borderRadius: '10px',
    '-moz-appearance': 'textfield',
    '&::placeholder': {
      color: theme.colors.grayishBlue
    },
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
      {
        backgroundColor: 'transparent!important',
        '-webkit-box-shadow': `0 0 0px 1000px ${theme.colors.mostlyBlack} inset`
      },
    '&:focus': {
      border: `2px solid ${theme.colors.amethyst} `
    },
    '&::-ms-reveal': {
      display: 'none'
    }
  },
  filled: {
    border: `2px solid ${theme.colors.border}`,
    backgroundColor: theme.colors.mostlyBlack,
    color: theme.colors.white,
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
      {
        color: `${theme.colors.white}`
      },
    '&:focus': {
      border: `2px solid ${theme.colors.amethyst} `,
      '& + span': {
        color: theme.colors.white
      }
    }
  },
  unfilled: {
    border: `2px solid ${theme.colors.violet}`,
    backgroundColor: theme.colors.white,
    color: theme.colors.background,
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
      {
        color: `${theme.colors.violet}`
      },
    '&:focus': {
      border: `2px solid ${theme.colors.background} `,
      '& + span': {
        color: theme.colors.violet
      }
    }
  },
  icon: {
    position: 'absolute',
    right: 13,
    cursor: 'pointer'
  },
  error: {
    height: 18,
    marginLeft: 46
  }
}))
