import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  modal: {
    width: '45%'
  },
  content: {
    marginTop: 16,
    height: '100%', // 400,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 9,
      height: 9
    },
    '&::-webkit-scrollbar-track': {
      /* Track */
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      /* Handle */
      background: '#d7d7d7',
      borderRadius: 14
    },
    '&::-webkit-scrollbar-thumb:hover': {
      /* Handle on hover */
      background: '#aba7c3',
    }
  },
  '@media screen and (max-width: 880px)': {
    modal: {
      width: '78%'
    }
  }
})
