import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  promo: {
    overflow: 'hidden'
  },
  container: {
    position: 'absolute',
    padding: 0,
    height: 400,
    right: 60,
    alignItems: 'end',
    justifyContent: 'flex-end',
    zIndex: 2
  },
  background: {
    height: 546,
    width: '100%',
    transform: 'scale(1.3)',
    '-webkit-transform': 'scale(1.3)',
    filter: 'blur(2px)'
  },
  info: { },
  shadow: {
    alignItems: 'center',
    background: theme.colors.lightBackground,
    flex: 'none',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '101%',
    justifyContent: 'flex-end',
    left: 0,
    margin: '0 0 0 0',
    position: 'absolute',
    top: 0,
    width: '100%',
    maxWidth: '100%'
  },
  '@media screen and (max-width: 1000px)': {
    background: {
      height: 440
    }
  },
  '@media screen and (max-width: 740px)': {
    background: {
      height: 296
    },
    container: {
      height: 220
    }
  },
  '@media screen and (max-width: 440px)': {
    background: {
      height: 220
    }
  }
}))
