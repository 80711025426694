import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 178,
    height: 52,
    borderRadius: 32,
    border: 'none',
    fontWeight: 500,
    outline: 'none',
    textDecoration: 'none',
    transition: 'all 300ms ease-in-out',
    cursor: theme.cursor,
    '& > *': {
      padding: '0 8px',
      cursor: theme.cursor,
      textDecoration: 'none',
      whiteSpace: 'nowrap'
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      cursor: 'default',
      '& > *': {
        cursor: 'default'
      }
    },
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      transition: 'all 0.5s ease-in'
    }
  },
  accent: {
    background: theme.colors.button,
    '& > *': {
      color: theme.colors.white
    },
    '&:hover': {
      background: theme.colors.button,
      '& > *': { color: theme.colors.white }
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      background: theme.colors.disabledGradient,
      '& > *': {
        color: theme.colors.text
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.text
        }
      }
    },
    '&:active, &[active]': {
      background: theme.colors.button,
      color: theme.colors.white,
      '& > *': {
        color: theme.colors.white
      }
    }
  },
  secondary: {
    width: 200,
    background: theme.colors.straitGreen,
    '& > *': {
      color: theme.colors.white
    },
    '&:hover': {
      outline: 'none',
      '& > *': {
        color: theme.colors.white
      }
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      background: theme.colors.straitGreen,
      '& > *': {
        color: theme.colors.text
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.text
        }
      }
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.white,
      '& > *': {
        color: theme.colors.white
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.white
        }
      }
    }
  },
  cancel: {
    background: theme.colors.cancel,
    '& > *': {
      color: theme.colors.white
    },
    '&:hover': {
      background: theme.colors.cancel,
      '& > *': { color: theme.colors.white }
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      background: theme.colors.disabledGradient,
      '& > *': {
        color: theme.colors.text
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.text
        }
      }
    },
    '&:active, &[active]': {
      background: theme.colors.cancel,
      color: theme.colors.white,
      '& > *': {
        color: theme.colors.white
      }
    }
  },
  disabled: {
    background: theme.colors.disabledGradient,
    cursor: '',
    '& > *': {
      color: theme.colors.text
    },
    '&:hover': {
      color: theme.colors.text,
      border: `1px solid ${theme.colors.text}`
    }
  },
  empty: {
    width: 88,
    height: 40,
    border: `1px solid ${theme.colors.violet}`,
    background: theme.colors.white,
    '& > *': {
      color: theme.colors.violet
    }
  },
  transparent: {
    width: 'auto',
    height: 40,
    border: 'none',
    background: 'transparent'
  },
  small: {
    height: 30
  }
}))
