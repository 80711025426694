import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '16px 0',
    width: '100%',
    maxWidth: 'calc(100% - 300px)'
  },
  basic: {},
  large: {},
  extraLarge: {},
  settings: {
    width: 688
  },
  '@media screen and (max-width: 1365px)': {
    container: {
      padding: '8px 0',
      maxWidth: 'calc(100% - 120px)'
    }
  },
  '@media screen and (max-width: 800px)': {
    container: {
      padding: '8px 0',
      maxWidth: 'calc(100% - 80px)'
    }
  }
})
