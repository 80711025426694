import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  screens: {
    background: theme.colors.white
  },
  container: {
    padding: '80px 0'
  },
  gradientBox: {
    marginLeft: 8
  },
  title: {
    textAlign: 'center'
  },
  description: {
    marginTop: 28
  },
  list: {
    marginTop: 28,
    flexWrap: 'wrap',
    columnGap: 50,
    rowGap: 26
  },
  text: {
    width: 'calc(100% - 64px)',
    height: '100%',
    top: 34,
    position: 'absolute',
    visibility: 'hidden',
    zIndex: 3,
    pointerEvents: 'none',
    '&:hover': {
      visibility: 'visible',
      transition: '.4s ease-in-out',
    },
  },
  img: {
    zIndex: 2,
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  '@media screen and (max-width: 630px)': {
    list: {
      columnGap: 50
    }
  }
}))
