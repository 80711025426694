import React from 'react'

import {
  Column,
  Row,
  Content,
  Text,
  GradientRectangle,
  GradientTitle,
  GoBtn
} from '../../../../components'
import { useResponsive } from '../../../../providers'

import { useStyle } from './about-me.styles'

export const AboutMe = () => {
  const classes = useStyle()
  const { isMobile, isTablet } = useResponsive()
  const titlePreset = isMobile || isTablet ? 'subTitle' : 'boldTitle'

  return (
    <Content id="about-me" className={classes.about}>
      <Row className={classes.content} fullWidth justifyContent="space-between">
        <Column className={classes.leftContainer} alignItems="flex-start">
          <Row className={classes.imgContainer}>
            <Column className={classes.img} />
            <GradientRectangle />
          </Row>
        </Column>
        <Column className={classes.rightContainer} alignItems="flex-start">
          <GradientTitle tx="about.title" text="About me" />
          <Text
            className={classes.title}
            tx="about.info"
            text={'Web Software Engineer.\nReact Developer'}
            preset={titlePreset}
            color="white"
          />
          <Text
            className={classes.descr}
            tx="about.tagline"
            text="Full service from one person - development and promotion in web. Dedicated plan prepared for your product."
            preset="mark"
            color="text"
          />
          <Text
            className={classes.descr}
            tx="about.nda"
            text="According to the contracts and privacy policy..."
            preset="mark"
            color="text"
          />
          <Text
            className={classes.descr}
            tx="about.marketing"
            text="Currently I am studying Google marketing and working on several e-commerce projects. Digital marketing via Google Ads..."
            preset="mark"
            color="text"
          />
          <Text
            className={classes.descr}
            tx="about.more"
            text="Other info about me you can find on my LinkedIn profile."
            preset="mark"
            color="text"
          />
          <GoBtn name="Services" tx="btn.services" text="Services" />
        </Column>
      </Row>
    </Content>
  )
}
