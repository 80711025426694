import React from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockFour, faLocationDot } from '@fortawesome/free-solid-svg-icons'

import { LightTheme } from '../../../../../../theme'
import { Row, Column, Text } from '../../../../../../components'

import { DetailsProps } from './details.types'
import { useStyle } from './details.styles'

export const Details = ({ remote = false }: DetailsProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()

  return (
    <Row className={classes.location}>
      <Row>
        <FontAwesomeIcon
          icon={faClockFour}
          height="30"
          width="30"
          color={theme.colors.text}
        />
        <Column alignItems="flex-start">
          <Text
            tx="projects.fullTime"
            text="Full Time Job"
            color="text"
            preset="lightCaption"
          />
          {remote && (
            <Text
              tx="projects.remote"
              text="(Remote)"
              color="text"
              preset="lightCaption"
            />
          )}
        </Column>
      </Row>
      <Row>
        <FontAwesomeIcon
          icon={faLocationDot}
          height="30"
          width="30"
          color={theme.colors.text}
        />
        <Text
          tx="projects.location"
          text="Kharkiv, UA"
          color="text"
          preset="lightCaption"
        />
      </Row>
    </Row>
  )
}
