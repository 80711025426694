import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  badge: {
    width: 'auto',
    padding: '5px 20px 5px',
    borderRadius: 50,
    background: theme.colors.lightWhite
  },
  button: {
    '& > *': {
      color: theme.colors.white
    }
  },
  accent: {
    '& > *': {
      color: theme.colors.amethyst
    }
  },
  secondary: {
    '& > *': {
      color: theme.colors.cyan
    }
  }
}))
