import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  background: {
    background: theme.colors.white
  },
  content: {},
  performance: {
    zIndex: 2,
    padding: '80px 32px',
    background: 'linear-gradient(111deg, #392C76 42.54%, #1F1840 99.42%)',
    boxShadow: '2px 4px 6px 1px #00000033',
    borderRadius: 12
  },
  container: {
    '& >:not(:first-child)': {
      marginLeft: 16
    }
  },
  gradient: {},
  imgContainer: {
    width: 540,
    height: 'auto',
    textAlign: 'center',
    padding: 14,
    overflow: 'hidden'
  },
  img: {
    boxShadow: '1px 1px 3px 1px #00000066',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    flex: 1,
    height: '100%',
    width: '100%',
    borderRadius: '0 30px 0 0',
    zIndex: 2
  },
  info: {
    width: '30%',
    marginLeft: 24
  },
  text: {
    marginTop: 24
  },
  '@media screen and (max-width: 890px)': {
    content: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    info: {
      width: '100%',
      marginLeft: 0,
      marginTop: 24
    },
    imgContainer: {
      width: 400,
      height: 'auto'
    },
    gradient: {
      height: 100
    }
  },
  '@media screen and (max-width: 480px)': {
    imgContainer: {
      width: 300,
      height: 'auto'
    }
  },
  '@media screen and (max-width: 360px)': {
    imgContainer: {
      width: 250,
      height: 'auto'
    }
  }
}))
