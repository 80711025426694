import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  suggestion: {
    background: theme.colors.white
  },
  indent: {
    // marginTop: 140
  },
  imageAnchor: {
    position: 'absolute',
    bottom: -64
  },
  experience: {
    borderRadius: 50
  },
  content: {
    zIndex: 2,
    padding: '80px 0',
    scrollMarginTop: '50px'
  },
  container: {
    '& >:not(:first-child)': {
      marginTop: 32
    }
  },
  text: {
    textAlign: 'center',
    lineHeight: 1.5
  }
}))
