import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { Row, Link, concatClassNames } from '..'

import { IRoundedArrowLinkProps } from './rounded-arrow-link.types'
import { useStyle } from './rounded-arrow-link.styles'

export const RoundedArrowLink = ({
  className,
  href,
  name,
  onClick
}: IRoundedArrowLinkProps) => {
  const classes = useStyle()

  return (
    <Link className={concatClassNames(className, classes.link)} name={name} href={href} onClick={onClick}>
      <Row className={classes.arrowDown}>
        <FontAwesomeIcon icon={faChevronDown} height="30" width="30" />
      </Row>
    </Link>
  )
}
