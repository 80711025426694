import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  background: {
    background: theme.colors.white
  },
  services: {
    zIndex: 2,
    padding: '80px 0'
  },
  container: {
    '& >:not(:first-child)': {
      marginLeft: 16
    }
  },
  number: {
    position: 'absolute',
    left: 14,
    textShadow: '1px 1px 1px #00000066'
  },
  label: {
    position: 'absolute',
    right: -30,
    top: 50,
    padding: '6px 8px',
    borderRadius: 6,
    background: theme.colors.straitGreen,
    boxShadow: '2px 4px 6px 0px #00000033',
    transform: 'rotate(-10deg)',
    transition: 'all ease 1s',
    '&:hover': {
      boxShadow: '4px 6px 8px 0px #00000033',
      transition: 'all ease 1s'
    }
  },
  suggestion: {
    width: 400
  },
  item: {
    padding: '28px 32px',
    borderRadius: 10
  },
  imgContainer: {
    width: 370,
    height: 270,
    textAlign: 'center',
    padding: 14,
    transition: 'all ease 1.5s',
    '&:hover': {
      transform: 'translateY(-8px)',
      transition: 'all ease 1.5s'
    }
  },
  img: {
    boxShadow: '1px 1px 3px 1px #00000066',
    borderRadius: 12,
    zIndex: 2
  },
  serviceTitle: {
    marginTop: 20
  },
  serviceText: {
    marginTop: 12
  },
  '@media screen and (max-width: 870px)': {
    container: { 
      flexDirection: 'column'
    },
    suggestion: {
      width: '100%'
    },
    img: {
      backgroundSize: 'cover'
    },
    imgContainer: {
      marginTop: 24
    }
  },
  '@media screen and (max-width: 480px)': {
    imgContainer: {
      height: 200,
      width: 'auto'
    }
  }
}))
