import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    scrollMarginTop: '40px',
    scrollBehavior: 'smooth',
    background: '-webkit-linear-gradient(270deg, #392C76 0%, rgba(45, 18, 151, 0.89) 100%)',
  },
  gradientBox: {
    marginLeft: 8
  },
  pricing: {
    padding: '80px 0',
    zIndex: 2
  },
  offers: {
    flexWrap: 'wrap',
    rowGap: '12px',
    columnGap: '32px',
    justifyContent: 'flex-start'
  },
  offer: {
    alignItems: 'flex-start',
    border: `2px solid ${theme.colors.border}`,
    borderRadius: 10,
    flex: '0 0 26%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    marginTop: 24,
    padding: '40px 30px',
    height: 640,
    transitionDuration: '3s',
    width: 'auto',
    maxWidth: 'calc(100% - 10px)',
    boxShadow: '0px 0px 30px 0px rgb(0 0 0 / 15%)',
    background: theme.colors.white
  },
  largeOffer: {
    height: 730
  },
  gradient: {
    position: 'absolute',
    borderRadius: 10,
    top: 0,
    left: 0,
    background: theme.colors.white,
    cursor: 'pointer',
    opacity: 0,
    zIndex: 0
  },
  circleIcon: {
    width: 70,
    height: 70,
    border: '2px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '50%'
  },
  service: {
    marginLeft: 12,
    lineHeight: 1.1
  },
  price: {
    marginTop: 24
  },
  text: {
    marginTop: 14,
    zIndex: 3
  },
  filledIcon: {
    background: 'linear-gradient(90deg, #7a63d2, #b266d2)'
  },
  badge: {
    background: theme.colors.border,
    padding: '5px 20px 5px',
    borderRadius: '50px'
  },
  item: {
    borderBottom: `1px solid ${theme.colors.burgundi}`,
    padding: '10px 0px 6px'
  },
  filledItem: {
    background: 'linear-gradient(45deg, #7a63d2, #b266d2)',
    transition: 'background 0.25s ease',
    padding: 40,
    '& > *': {
      color: theme.colors.white
    }
  },
  form: {
    width: '100%'
  },
  icon: {
    width: 16,
    height: 16
  },
  orderButton: {
    position: 'absolute',
    bottom: 40,
    left: 0
  },
  priceIndent: {
    marginLeft: 12
  },
  inputContainer: {
    width: '100%',
    height: 'auto'
  },
  input: {
    width: 'calc(100% - 50px)',
    '&::-webkit-inner-spin-button': {
      display: 'none'
    }
  },
  invisible: {
    display: 'none'
  },
  choose: {
    marginTop: 18
  },
  spinner: {
    margin: '24px auto 0'
  },
  '@media screen and (min-width: 1168px) and (max-width: 1350px)': {
    largeOffer: {
      flex: '0 0 28%!important'
    },
    offer: {
      flex: '0 0 24%'
    }
  },
  '@media screen and (max-width: 1168px)': {
    offers: {
      flexDirection: 'column'
    },
    offer: {
      width: 'calc(100% - 80px)'
    },
    text: {
      marginTop: 24
    },
    orderButton: {
      position: 'relative',
      bottom: 0
    }
  },
  '@media screen and (min-width: 1400px)': {
    offer: {
      height: 570
    },
    largeOffer: {
      height: 720
    }
  }
}))
