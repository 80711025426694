import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  background: {
    background: '-webkit-linear-gradient(90deg, #392C76 1.06%, rgba(45, 18, 151, 0.89) 100%)',
  },
  steps: {
    zIndex: 2,
    padding: '80px 0'
  },
  container: {
    '& >:not(:first-child)': {
      marginLeft: 16
    }
  },
  row: {
    flexWrap: 'wrap',
    rowGap: '20px',
    columnGap: '18px'
  },
  content: {
    '& > *:first-child': {
      marginTop: 28
    }
  },
  number: {
    position: 'absolute',
    left: '-14px',
    textShadow: '1px 1px 1px #00000066'
  },
  item: {
    padding: '10px 12px'
  },
  cell: {
    padding: '18px 12px',
    width: 278
  },
  text: {
    textAlign: 'center'
  },
  description: {
    height: 100
  },
  '@media screen and (max-width: 870px)': {
    imgContainer: {
      height: 'auto'
    }
  }
})
