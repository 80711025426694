import React from 'react'

import { Column, Row, Text, Content, Icon, IconName } from '../../../components'

import { useStyle } from './suggestion.styles'

export const Suggestion = () => {
  const classes = useStyle()

  return (
    <Column className={classes.suggestion} fullWidth>
      <Row className={classes.indent} />
      <Content id="suggestion" className={classes.content}>
        <Column className={classes.container} fullHeight fullWidth>
          <Row fullWidth>
            <Text
              className={classes.text}
              text=""
              tx="services.suggestion.request"
              preset="body"
              color="mostlyBlack"
            />
          </Row>
          <Row>
            <Text
              className={classes.text}
              text=""
              tx="services.suggestion.response"
              preset="lightBody"
              color="mostlyBlack"
            />
          </Row>
        </Column>
        <Column className={classes.imageAnchor} fullWidth alignItems="flex-end">
            <Icon
              className={classes.experience}
              name={IconName.EXPERIENCE}
              height={150}
              width={150}
            />
          </Column>
      </Content>
    </Column>
  )
}
