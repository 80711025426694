import React, { FC, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { Button } from '../button'
import { Content } from '../content'

import { CookiesBannerProps } from './cookies-banner.types'
import { useStyle } from './cookies-banner.styles'

export const CookiesBanner: FC<CookiesBannerProps> = ({ className }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [cookies, setCookie] = useCookies(['holovachPro'])
  const [isBannerVisible, changeIsBannerVisible] = useState(
    !cookies.holovachPro
  )

  const handleOnClose = () => {
    changeIsBannerVisible(false)
  }

  const handleOnSetCookies = () => {
    setCookie('holovachPro', true, { path: '/' })
    handleOnClose()
  }

  return isBannerVisible ? (
    <Column
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
      fullWidth
    >
      <Content className={classes.content}>
        {/* This website uses cookies to ensure you get the best experience on our website. */}
        {/* Choose what cookies you allow us to use.  */}
        <Row fullWidth justifyContent="flex-start">
          <Text
            text="We use cookies to ensure you get the best experience on our website. You can read more about our Cookie Policy in our - {privacy}"
            tx="cookies.details"
            preset="caption"
            values={{
              privacy: (
                <Text
                  text="Privacy Policy"
                  tx="nav.privacy"
                  color="violet"
                  preset="caption"
                />
              )
            }}
          />
          <Button
            className={classes.button}
            name="Cookies"
            preset="empty"
            onClick={handleOnSetCookies}
          >
            <Text text="Ok" preset="badge" />
          </Button>
        </Row>
      </Content>
    </Column>
  ) : null
}
