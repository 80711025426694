import React, { useState, useRef, useEffect } from 'react'
import { useTheme } from 'react-jss'
import emailjs from '@emailjs/browser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import { useResponsive } from '../../../providers'
import { LightTheme } from '../../../theme'
import {
  Column,
  Row,
  Input,
  Text,
  Content,
  Button,
  Spinner
} from '../../../components'

import { useStyle } from './consultation.styles'

const SETTINGS = {
  SERVICE_ID: 'service_moomeii',
  TEMPLATE_ID: 'template_t3lv4bt',
  PUBLIC_KEY: 'ahm2XV3-mtCbBzA20'
}

export const Consultation = () => {
  const classes = useStyle()
  const theme: LightTheme = useTheme()
  const form = useRef<HTMLFormElement>(null)
  const [isLoaded, changeIsLoaded] = useState(false)
  const [isSent, changeIsSent] = useState(false)
  const [data, changeData] = useState({ phone: '' })
  const { isTablet, isMobile } = useResponsive()

  const handlePhoneChange = (value: any) => {
    changeData({ phone: value })
  }

  const sendEmail = () => {
    emailjs
      .sendForm(
        SETTINGS.SERVICE_ID,
        SETTINGS.TEMPLATE_ID,
        form.current || '',
        SETTINGS.PUBLIC_KEY
      )
      .then(
        () => {
          // console.log(result.text)
          changeIsLoaded(true)
          changeIsSent(false)
        },
        () => {
          changeIsLoaded(true)
          changeIsSent(false)
          // console.log(error.text)
        }
      )
  }

  const handleOnSubmit = () => {
    if (data.phone) {
      changeData({ phone: '' })
      sendEmail()
      changeIsSent(true)

      if (form) {
        form?.current?.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        )
      }
    }
  }

  const handleOnPointerSubmit = () => {
    if (isTablet || isMobile) {
      handleOnSubmit()
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoaded) {
        changeIsLoaded(false)
      }
    }, 1500)

    return () => clearTimeout(timer)
  }, [isLoaded])

  return (
    <Column className={classes.background} fullWidth>
      <Row className={classes.indent} />
      <Content id="consultation" className={classes.consultation}>
        <Column className={classes.container} fullHeight fullWidth>
          <Column fullWidth>
            <Text
              className={classes.call}
              text="Book a {free} 15 minute call to get a quotation and find out how to get {results} by working with me"
              tx="services.call"
              preset="lightBody"
              color="mostlyBlack"
              values={{
                free: (
                  <Text
                    className={classes.free}
                    tx="services.free"
                    text="free"
                    color="mostlyBlack"
                  />
                ),
                results: (
                  <Text
                    className={classes.free}
                    tx="services.results"
                    text="results"
                    color="mostlyBlack"
                  />
                )
              }}
            />
          </Column>
          {/* @ts-ignore */}
          <form ref={form} method="post">
            <Row fullWidth>
              <Row fullWidth>
                <Input
                  className={classes.phoneInput}
                  name="message"
                  type="number"
                  isColored={false}
                  placeholderTx="services.callPlaceholder"
                  placeholder="Enter your phone number"
                  defaultValue={data.phone}
                  onChange={handlePhoneChange}
                />
                {!isSent ? (
                  <Button
                    name="Send"
                    className={classes.send}
                    preset="transparent"
                    onClick={handleOnSubmit}
                    onPointerEnter={handleOnPointerSubmit}
                  >
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      height="45"
                      width="45"
                      color={theme.colors.darkBlue}
                    />
                  </Button>
                ) : (
                  <Spinner />
                )}
              </Row>
              {isLoaded && (
                <Text
                  className={classes.text}
                  text="Message was sent"
                  tx="modal.success"
                  color="mostlyBlack"
                />
              )}
            </Row>
          </form>
        </Column>
      </Content>
    </Column>
  )
}
