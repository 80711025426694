import React, { FC } from 'react'

import { IconProps } from '../types'

import { images } from '../../../assets/images'

export const PhotoGalleryPreview: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={356}
      height={720}
      viewBox="0 0 356 720"
      fill="none"
      {...props}
    >
      <image
        width="100%"
        height="100%"
        xlinkHref={images.PhotoGalleryPreview}
      />
    </svg>
  )
}
