import React, { MouseEvent, forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { useTheme } from 'react-jss'

import { usePortal } from '../../hooks'
import { Column } from '../column'
import { Icon, IconName } from '../icon'
import { LightTheme } from '../../theme'

import { ModalProps } from './modal.types'
import { useStyle } from './modal.styles'

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  ({ className, modal = 'modal', children, onClose }, ref) => {
    const theme: LightTheme = useTheme()
    const classes = useStyle({ theme })
    const target = usePortal(modal)

    const handleOnPropagationBackground = (
      event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
    ) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return ReactDOM.createPortal(
      <Column className={classes.container}>
        <Column
          ref={ref}
          className={`${classes.modal} ${className}`}
          onClick={handleOnPropagationBackground}
        >
          <Icon
            className={classes.close}
            name={IconName.CLEAR}
            onClick={onClose}
            fill="black"
          />
          {children}
        </Column>
      </Column>,
      target
    )
  }
)
