import { createUseStyles } from 'react-jss'

import { images } from '../../assets'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    scrollMarginTop: 100,
    height: '764px',
    '& > *' : {
      textAlign: 'center'
    }
  },
  h1: {
    display: 'none'
  },
  title: {
    position: 'absolute',
    zIndex: 3
  },
  shadow: {
    alignItems: 'center',
    background: theme.colors.lightBackground,
    flex: 'none',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '101%',
    justifyContent: 'flex-end',
    left: 0,
    margin: '0 0 0 0',
    position: 'absolute',
    top: 0,
    width: '100%',
    maxWidth: '100%'
  },
  item: {},
  ctaBtns: {
    zIndex: 3,
    position: 'absolute',
    bottom: 240,
    '& > *:not(:first-child)': {
      marginLeft: 18
    }
  },
  arrow: {
    zIndex: 3,
    position: 'absolute',
    bottom: 60,
  },
  container: {
    backgroundImage: `url(${images.Background2})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    flex: 1,
    opacity: 0.4
  }
}))
