import { createUseStyles } from 'react-jss'

import { images } from '../../../../assets'

export const useStyle = createUseStyles({
  about: {
    marginTop: 80,
    scrollMarginTop: '50px',
    scrollBehavior: 'smooth',
    padding: 0
  },
  content: {},
  leftContainer: {
    width: '40%'
  },
  rightContainer: {
    width: '60%'
  },
  circleIcon: {
    width: 70,
    height: 70,
    border: '2px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '50%'
  },
  imgContainer: {
    maxWidth: 300,
    width: 300,
    height: 370,
    textAlign: 'center',
    padding: 14,
    transition: 'all ease 1.5s',
    '&:hover': {
      transform: 'translateY(-8px)',
      transition: 'all ease 1.5s'
    }
  },
  img: {
    backgroundImage: `url(${images.About})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    boxShadow: '2px 4px 6px 1px #00000033',
    flex: 1,
    height: '100%',
    width: '100%',
    borderRadius: '0 30px 0 0',
    zIndex: 2
  },
  gradientBox: {
    marginLeft: 8
  },
  title: {
    marginTop: 32
  },
  descr: {
    marginTop: 32
  },
  '@media screen and (max-width: 1100px)': {
    rightContainer: {
      marginLeft: 30
    },
    imgContainer: {
      maxWidth: 250
    }
  },
  '@media screen and (max-width: 700px)': {
    rightContainer: {
      width: '100%',
      marginLeft: 0,
      marginTop: 24
    },
    leftContainer: {
      width: '100%',
      marginLeft: 0
    },
    content: {
      flexDirection: 'column'
    }
  },
  '@media screen and (min-width: 1590px)': {
    leftContainer: {
      alignItems: 'center'
    }
  }
})
