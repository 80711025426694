import React, { FC } from 'react'

import { IconProps } from '../types'

import { images } from '../../../assets/images'

export const WhyMe: FC<IconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={1536}
      height={1536}
      viewBox="0 0 1536 1536"
      fill="none"
      {...props}
    >
      <image width="100%" height="100%" xlinkHref={images.WhyMe} />
    </svg>
  )
}
