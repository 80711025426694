import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { HeaderStyleProps } from './header.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  header: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '12px',
    justifyContent: 'space-between',
    padding: 0
  },
  navContainer: {
    '& + &': {
      marginLeft: 48
    }
  },
  langList: {
    '& > *:not(:first-child)': {
      marginLeft: 10
    }
  },
  langs: {
    padding: '16px 0 0'
  },
  lang: {
    cursor: 'pointer'
  },
  navText: {
    padding: 0,
    textAlign: 'center'
  },
  icon: {
    cursor: theme.cursor
  },
  complexContainer: {
    marginLeft: 32
  },
  menu: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 3,
    minWidth: 200
  },
  logo: {
    cursor: theme.cursor
  },
  filledBackground: {
    backgroundColor: ({ backgroundColor = 'white' }: HeaderStyleProps) =>
      theme.colors[backgroundColor]
  },
  item: {
    '&:not(:first-child)': {
      marginLeft: 30
    },
    '&::after': {
      display: 'block',
      content: '""',
      width: 0,
      height: '2px',
      background: theme.colors.white,
      transition: 'width 0.3s'
    },
    '&:hover::after': {
      width: '100%'
    }
  },
  link: {
    marginLeft: 30
  },
  links: {},
  customHeader: {
    background: 'transparent',
    zIndex: '3!important'
  },
  rightContainer: {},
  '@media screen and (max-width: 640px)': {
    header: {
      justifyContent: 'space-between',
      marginTop: 16
    }
  },
  '@media screen and (max-width: 900px)': {
    item: {
      marginLeft: 20,
      marginTop: 12,
      textAlign: 'end'
    },
    logo: {
      marginLeft: '-44px'
    },
    links: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end'
    },
    rightContainer: {
      width: '52%'
    }
  }
}))
