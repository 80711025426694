import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons'
import emailjs from '@emailjs/browser'

import { useOutsideAlerter } from '../../../../hooks'
import {
  Column,
  Row,
  Content,
  Text,
  Input,
  Checkbox,
  Button,
  AgreeModal,
  Textarea,
  getIsAllFilled
} from '../../../../components'

import { Navigation } from '../../../../components/footer/components'
import { useStyle } from './contact-us.styles'

const SETTINGS = {
  SERVICE_ID: 'service_moomeii',
  TEMPLATE_ID: 'template_t3lv4bt',
  PUBLIC_KEY: 'ahm2XV3-mtCbBzA20'
}

const BASIC_CONTACT = {
  name: '',
  email: '',
  // skype: '',
  message: '',
  agree: false
}

export const ContactUs = () => {
  const classes = useStyle()
  const form = useRef<string | HTMLFormElement>('')
  const ref = useRef<HTMLDivElement>(null)
  const [isButtonDisabled, changeIsButtonDisabled] = useState(true)
  const [contact, changeContact] = useState(BASIC_CONTACT)
  const [isLoaded, changeIsLoaded] = useState(false)
  const [isSent, changeIsSent] = useState(false)
  const [openModal, changeOpenModal] = useState(false)
  const [fieldError, changeFieldError] = useState(false)

  const handleOnChange = (id: string) => (value: any) => {
    changeContact({
      ...contact,
      [id]: value
    })
  }

  const handleOnCheckboxChange = (id: string) => (status: boolean) => {
    // TODO: move to separate event repeated code
    changeContact({
      ...contact,
      [id]: status
    })
  }

  const handleOnOpenModal = useCallback(
    (state?: boolean) => () => {
      if (typeof state === 'boolean') {
        changeOpenModal(state)
      } else {
        changeOpenModal(!openModal)
      }
    },
    []
  )

  const handleOnAutoClose = useCallback(() => {
    handleOnOpenModal(false)()
  }, [handleOnOpenModal])

  const sendEmail = () => {
    // TODO: check
    // e.preventDefault()
    // if (contact.botTest) {
    //   return
    // }

    emailjs
      .sendForm(
        SETTINGS.SERVICE_ID,
        SETTINGS.TEMPLATE_ID,
        form.current,
        SETTINGS.PUBLIC_KEY
      )
      .then(() => {
        changeIsLoaded(true)
        changeIsSent(false)
      })
  }

  const handleOnSubmit = () => {
    changeContact(BASIC_CONTACT)
    sendEmail()
    changeIsSent(true)
  }

  const handleOnClick = () => {
    handleOnOpenModal(true)()
  }

  const handleOnBlur = (flag: boolean) => {
    changeFieldError(flag)
  }

  useEffect(() => {
    // TODO: skype is not important
    const isFilled = getIsAllFilled(contact)

    if (!isFilled || fieldError) {
      changeIsButtonDisabled(true)
      return
    }
    const isStatusChanged = isFilled !== !isButtonDisabled
    const isDisabled = isStatusChanged ? fieldError : false

    if (isStatusChanged) {
      changeIsButtonDisabled(isDisabled)
    }
  }, [contact, fieldError, isButtonDisabled])

  // TODO: create a hook from it, pass callback
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoaded) {
        handleOnOpenModal(false)()
        changeIsLoaded(false)
        changeIsSent(false)
      }
    }, 1500)

    return () => clearTimeout(timer)
  }, [isLoaded])

  useOutsideAlerter(ref, openModal, handleOnAutoClose)

  return (
    <Column id="contact" fullWidth className={classes.contact}>
      <Content className={classes.container}>
        <Row justifyContent="space-between">
          <Row className={classes.row} fullWidth alignItems="flex-start">
            {/* @ts-ignore */}
            <form className={classes.form} ref={form} method="post">
              <Text
                tx="contact.title"
                text="Contact"
                preset="title"
                color="white"
              />
              <Row
                className={classes.brief}
                fullWidth
                justifyContent="flex-start"
              >
                <Text
                  tx="contact.brief"
                  text="Let's talk about your project"
                  preset="lightBody"
                  color="white"
                />
              </Row>
              <Column fullWidth>
                <Column className={classes.item} alignItems="flex-start">
                  <Input
                    name="name"
                    className={classes.inputContainer}
                    classNameInput={classes.input}
                    placeholder="..."
                    placeholderTx="contact.yourNameSurname"
                    defaultValue={contact.name}
                    onChange={handleOnChange('name')}
                  />
                </Column>
                <Column className={classes.item} alignItems="flex-start">
                  <Input
                    name="email"
                    className={classes.inputContainer}
                    classNameInput={classes.input}
                    type="email"
                    placeholder="..."
                    placeholderTx="contact.yourEmail"
                    defaultValue={contact.email}
                    onChange={handleOnChange('email')}
                    onBlur={handleOnBlur}
                  />
                </Column>
                {/* <Column className={classes.item} alignItems="flex-start">
                  <Input
                    name="skype_contact"
                    className={classes.inputContainer}
                    classNameInput={classes.input}
                    placeholder="..."
                    placeholderTx="contact.yourSkype"
                    defaultValue={contact.skype}
                    onChange={handleOnChange('skype')}
                  />
                </Column> */}
              </Column>
              <Column
                className={classes.textarea}
                alignItems="flex-start"
                fullWidth
              >
                <Textarea
                  name="message"
                  className={classes.message}
                  placeholder="Message"
                  placeholderTx="contact.yourMessage"
                  defaultValue={contact.message}
                  onChange={handleOnChange('message')}
                />
              </Column>
              <Row
                className={classes.agree}
                fullWidth
                justifyContent="space-between"
              >
                <Checkbox
                  width="24"
                  height="24"
                  id="privacy"
                  text="I agree to the Privacy Policy."
                  tx="contact.privacy"
                  preset="lightCaption"
                  defaultChecked={contact.agree}
                  disabled={false}
                  onChange={handleOnCheckboxChange('agree')}
                />
                <Button
                  className={classes.button}
                  name="Send message"
                  disabled={isButtonDisabled}
                  preset="accent"
                  onClick={handleOnClick}
                >
                  <Text
                    preset="largeBadge"
                    color="white"
                    tx="btn.sendMessage"
                    text="Send message"
                  />
                  <Row className={classes.arrowRight}>
                    <FontAwesomeIcon
                      icon={faLongArrowRight}
                      height="30"
                      width="30"
                    />
                  </Row>
                </Button>
              </Row>
            </form>
            <Navigation />
          </Row>
        </Row>
      </Content>
      {openModal && (
        <AgreeModal
          ref={ref}
          isLoaded={isLoaded}
          isSent={isSent}
          titleProps={{
            text: 'Do you confirm sending?',
            tx: 'modal.areYouAgree'
          }}
          onSubmit={handleOnSubmit}
          onClose={handleOnOpenModal}
          onAutoClose={handleOnAutoClose}
        />
      )}
    </Column>
  )
}
