import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  link: {
    display: 'block',
    width: 50
  },
  arrowDown: {
    height: 50,
    width: 50,
    border: `1px solid ${theme.colors.white}`,
    borderRadius: '50%',
    transitionDuration: '600ms',
    '& > *': {
      cursor: 'pointer',
      color: theme.colors.white
    },
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      boxShadow: '2px 2px 20px rgba(255, 255, 255, 0.6)',
    }
  }
}))
