import React from 'react'

import {
  Column,
  Text,
  Row,
  Content,
  concatClassNames,
  Link
} from '../../../components'

import { PriceProps } from './price.types'
import { useStyle } from './price.styles'

export const Price = ({ onClick }: PriceProps) => {
  const classes = useStyle()

  return (
    <Column id="price" className={classes.background} fullWidth>
      <Content className={classes.price}>
        <Row
          className={classes.content}
          fullWidth
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Column id="development-price">
            <Text
              className={classes.text}
              text="Development Cost"
              tx="services.price.development"
              preset="button"
              color="mostlyBlack"
            />
            <Row className={classes.values}>
              <Text
                className={classes.text}
                text="from"
                tx="from"
                preset="lightMark"
                color="mostlyBlack"
              />
              <Text
                className={concatClassNames(classes.text, classes.value)}
                text="$500"
                preset="header"
                color="mostlyBlack"
              />
            </Row>
          </Column>

          <Column id="advertisement-price">
            <Text
              className={classes.text}
              text="Advertising Cost"
              tx="services.price.advertisement"
              preset="button"
              color="mostlyBlack"
            />
            <Row className={classes.values}>
              <Column>
                <Text
                  className={concatClassNames(classes.text, classes.value)}
                  text="$100"
                  tx="services.price.advertisement.value1"
                  preset="header"
                  color="mostlyBlack"
                />
                <Text
                  className={classes.description}
                  text="(setup)"
                  tx="services.price.advertisement.desc1"
                  preset="buttonSmall"
                  color="straitGreen"
                />
              </Column>
              <Text
                className={concatClassNames(classes.text, classes.value)}
                text="plus"
                tx="plus"
                color="mostlyBlack"
                preset="button"
              />
              <Text
                className={classes.text}
                text="from"
                tx="from"
                preset="lightMark"
                color="mostlyBlack"
              />
              <Column>
                <Text
                  className={concatClassNames(classes.text, classes.value)}
                  text="$400"
                  preset="header"
                  color="mostlyBlack"
                />
                <Text
                  className={classes.description}
                  text="(budget)"
                  tx="services.price.advertisement.desc2"
                  preset="buttonSmall"
                  color="straitGreen"
                />
              </Column>
            </Row>
          </Column>
        </Row>

        <Row>
          <Row className={classes.orderButton} fullWidth>
            <Link
              id="price-list"
              name="Pricing"
              href="#pricing"
              className={classes.button}
              onClick={onClick}
            >
              <Text
                tx="btn.start"
                text="Choose service and Start now"
                preset="buttonSmall"
                color="white"
              />
            </Link>
          </Row>
        </Row>
      </Content>
    </Column>
  )
}
