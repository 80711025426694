import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  link: {
    textDecoration: 'none',
    '& > *': {
      cursor: 'pointer',
      height: 20
    }
  },
  button: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 178,
    height: 52,
    borderRadius: 32,
    border: 'none',
    fontWeight: 500,
    outline: 'none',
    textDecoration: 'none',
    transition: 'all 300ms ease-in-out',
    cursor: theme.cursor,
    '& > *': {
      padding: '0 8px',
      cursor: theme.cursor,
      textDecoration: 'none',
      whiteSpace: 'nowrap'
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      cursor: 'default',
      '& > *': {
        cursor: 'default'
      }
    },
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      transition: 'all 0.5s ease-in'
    }
  },
  secondary: {
    width: 200,
    background: theme.colors.straitGreen,
    '& > *': {
      color: theme.colors.white
    },
    '&:hover': {
      '& > *': {
        color: theme.colors.white
      }
    },
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      background: theme.colors.straitGreen,
      '& > *': {
        color: theme.colors.text
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.text
        }
      }
    },
    '&:active, &[active]': {
      backgroundColor: theme.colors.white,
      '& > *': {
        color: theme.colors.white
      },
      '&:hover': {
        '& > *': {
          color: theme.colors.white
        }
      }
    }
  }
}))
