import React, { memo, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import {
  RoundedArrowLink,
  Link,
  Column,
  Row,
  Text,
  removeHash
} from '../../components'
import { useResponsive } from '../../providers'
import {
  useNavigateLocation,
  usePath,
  useScrollToTop,
  useStoredLocale
} from '../../hooks'

import { ContactUs, ProjectScreens, Questions } from '../main'
import { Offers } from './offers'
import { Qualities } from './qualities'
import { DevelopmentService } from './development-service'
import { Consultation } from './consultation'
import { PerformanceExample } from './performance-example'
import { AdvertisementService } from './advertisement-service'
import { Price } from './price'
import { Advantages } from './advantages'
import { Steps } from './steps'
import { WhatYouGet } from './what-you-get'
import { Suggestion } from './suggestion'

import { useStyle } from './services.styles'

export const Services = memo(() => {
  const classes = useStyle()
  const isMobile = useResponsive()
  const [isOffersVisible, changeIsOffersVisible] = useState(false)
  const developmentRef = useRef(null)

  const intl = useIntl()
  const translatedTitle = intl.formatMessage({ id: 'seo.services.title' })
  const translatedDescription = intl.formatMessage({
    id: 'seo.services.description'
  })
  const translatedKeywords = intl.formatMessage({ id: 'seo.services.keywords' })

  const titlePreset = isMobile ? 'description' : 'regularHeader'
  const { hash, location } = usePath()

  const handleOnStartBtnClick = () => {
    if (!isOffersVisible) {
      changeIsOffersVisible(!isOffersVisible)
    }
  }

  useScrollToTop()
  useNavigateLocation()
  useStoredLocale()

  useEffect(() => {
    if (hash) {
      removeHash()
    }
  }, [hash, location])

  return (
    <Column fullWidth>
      <Helmet>
        <title>{translatedTitle}</title>
        <meta name="description" content={translatedDescription} />
        <meta name="keywords" content={translatedKeywords} />
        <link rel="canonical" href="https://holovach.pro/services" />
        <link rel="alternate" href="https://holovach.pro/services/" />
        <link
          rel="alternate"
          href="https://holovach.pro/en/services"
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href="https://holovach.pro/ua/services"
          hrefLang="uk-UA"
        />
        <link
          rel="alternate"
          href="https://holovach.pro/en/services/"
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href="https://holovach.pro/ua/services/"
          hrefLang="uk-UA"
        />
      </Helmet>

      <Column fullHeight fullWidth>
        <Column id="services" fullHeight fullWidth className={classes.content}>
          <Text
            className={classes.h1}
            preset="h1"
            tx="seo.services.description"
            text="Landing pages, complex and multi-page web application development. Paid Search Advertising with Google Ads."
          />
          <Text
            className={classes.title}
            text="Order web development and \nGoogle's advertising setup here"
            tx="services.title"
            preset={titlePreset}
          />
          <Column fullWidth className={classes.container} />
          <Row className={classes.ctaBtns}>
            <Link
              className={classes.item}
              name="Go to price list"
              href={'#price'}
              isButton
            >
              <Text
                tx="btn.priceList"
                text="Go to price list"
                preset="buttonSmall"
                color="white"
              />
            </Link>
          </Row>
          <RoundedArrowLink
            className={classes.arrow}
            name="Next screen"
            href="#development"
          />
        </Column>
        <Row className={classes.shadow} />
      </Column>

      <Row ref={developmentRef} />
      <DevelopmentService />
      <PerformanceExample />
      <AdvertisementService />
      <Advantages />
      <Price onClick={handleOnStartBtnClick} />
      <Offers isVisible={isOffersVisible} />
      <Steps />
      <WhatYouGet />
      <ProjectScreens isServices />
      <Qualities />
      <Consultation />
      <Questions isServicesLink={false} />
      <Suggestion />
      <ContactUs />
    </Column>
  )
})
