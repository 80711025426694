import { images } from '../../../../assets'

export enum REVIEWS {
  REVIEW_1 = 'review-1',
  REVIEW_2 = 'review-2',
  REVIEW_3 = 'review-3',
  REVIEW_4 = 'review-4',
  REVIEW_5 = 'review-5',
}

export const REVIEWS_IMAGES = {
  [REVIEWS.REVIEW_1]: images.Review1,
  [REVIEWS.REVIEW_2]: images.Review2,
  [REVIEWS.REVIEW_3]: images.Review3,
  [REVIEWS.REVIEW_4]: images.Review4,
  [REVIEWS.REVIEW_5]: images.Review5
}
