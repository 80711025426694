import React, { FC } from 'react'

import { Column } from '../column'
import { Row } from '../row'

import { SpinnerProps } from './spinner.types'
import { useStyle } from './spinner.styles'

export const Spinner: FC<SpinnerProps> = ({
  className = '',
  preset = 'small',
  isLoading = true,
  isBlue = false
}) => {
  const classes = useStyle({ isBlue })

  return isLoading ? (
    <Row className={`${className} ${classes[preset]}`}>
      <Column fullHeight fullWidth className={classes.spinner}></Column>
    </Row>
  ) : null
}
