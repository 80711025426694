import React from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import {
  Column,
  Content,
  Icon,
  IconName,
  Row,
  Text,
  concatClassNames
} from '../../../components'
import { LightTheme } from '../../../theme'

import { useStyle } from './what-you-get.styles'

export const WhatYouGet = () => {
  const classes = useStyle()
  const theme: LightTheme = useTheme()

  return (
    <Column className={classes.values} fullWidth>
      <Content id="value" className={classes.indicators}>
        <Row>
          <Text
            className={classes.title}
            text="What will you get?"
            tx="value.title"
            preset="button"
            color="darkBlue"
          />
        </Row>
        <Row className={classes.content} fullWidth alignItems="flex-start">
          <Row className={classes.container} justifyContent="space-around">
            <Column alignItems="flex-start">
              <Row className={classes.box} justifyContent="flex-start">
                <Row className={classes.circleIcon}>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    color={theme.colors.straitGreen}
                  />
                </Row>
                <Text
                  className={concatClassNames(
                    classes.description,
                    classes.text
                  )}
                  tx="value.first"
                  text="{focus} Honest Service"
                  color="white"
                  preset="mark"
                  values={{
                    focus: (
                      <Text
                        tx="value.first.focus"
                        text="High-Quality"
                        color="white"
                        preset="badge"
                      />
                    )
                  }}
                />
              </Row>

              <Row className={classes.box} justifyContent="flex-start">
                <Row className={classes.circleIcon}>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    color={theme.colors.straitGreen}
                  />
                </Row>
                <Text
                  className={classes.description}
                  tx="value.second"
                  text="Solution customized to meet your {focus}"
                  color="white"
                  preset="mark"
                  values={{
                    focus: (
                      <Text
                        tx="value.second.focus"
                        text="your business challenges and objectives"
                        color="white"
                        preset="badge"
                      />
                    )
                  }}
                />
              </Row>

              <Row className={classes.box} justifyContent="flex-start">
                <Row className={classes.circleIcon}>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    color={theme.colors.straitGreen}
                  />
                </Row>
                <Text
                  className={classes.description}
                  tx="value.third"
                  text="Modern {focus} Web Application"
                  color="white"
                  preset="mark"
                  values={{
                    focus: (
                      <Text
                        tx="value.third.focus"
                        text="Responsive"
                        color="white"
                        preset="badge"
                      />
                    )
                  }}
                />
              </Row>

              <Row className={classes.box} justifyContent="flex-start">
                <Row className={classes.circleIcon}>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    color={theme.colors.straitGreen}
                  />
                </Row>
                <Text
                  className={concatClassNames(
                    classes.description,
                    classes.text
                  )}
                  tx="value.fourth"
                  text="{focus} After Release"
                  color="white"
                  preset="mark"
                  values={{
                    focus: (
                      <Text
                        tx="value.fourth.focus"
                        text="Personal Website Assistance"
                        color="white"
                        preset="badge"
                      />
                    )
                  }}
                />
              </Row>

              <Row className={classes.box} justifyContent="flex-start">
                <Row className={classes.circleIcon}>
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faCheck}
                    color={theme.colors.straitGreen}
                  />
                </Row>
                <Text
                  className={concatClassNames(
                    classes.description,
                    classes.text
                  )}
                  tx="value.fifth"
                  text="Web Application {focus} Aligned with Google Standards"
                  color="white"
                  preset="mark"
                  values={{
                    focus: (
                      <Text
                        tx="value.fifth.focus"
                        text="Performance"
                        color="white"
                        preset="badge"
                      />
                    )
                  }}
                />
              </Row>
            </Column>
            <Column className={classes.image}>
              <Icon name={IconName.WHY_ME} height={250} width={250} />
            </Column>
          </Row>
        </Row>
      </Content>
    </Column>
  )
}
