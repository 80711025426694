import React from 'react'

import {
  Column,
  Content,
  GoBtn,
  Text,
  Icon,
  Row,
  IconName
} from '../../../../components'
import { useResponsive } from '../../../../providers'

import { useStyle } from './promo-price.styles'

export const PromoPrice = () => {
  const classes = useStyle()
  const { isMobile } = useResponsive()
  const textPreset = isMobile ? 'buttonSmall' : 'body'

  return (
    <Column id="promo-price" className={classes.promo} fullWidth>
      <Row className={classes.background} fullWidth>
        <Icon name={IconName.PROGRAMMING} />
      </Row>
      <Content className={classes.container}>
        <Text
          preset={textPreset}
          tx="promo.first.tilte"
          text="Need a website or a web-based business solution?"
        />
        <Column className={classes.info}>
          <GoBtn name="Read more" tx="btn.getMore" text="Read more" />
        </Column>
      </Content>
      <Row className={classes.shadow} />
    </Column>
  )
}
