import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { CheckboxProps } from './checkbox.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  checkbox: {
    cursor: 'pointer'
  },
  value: {
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    verticalAlign: 'center'
  },
  text: {
    marginLeft: 16,
    '&:hover': {
      cursor: ({ description }: CheckboxProps) =>
        !description ? 'default' : 'help'
    }
  },
  disabled: {
    color: `${theme.colors.border} !important`
  }
}))
