import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MainRoutes } from '../routes'
import { usePath } from './usePath'
import { getPathFlags } from './useCurrentPage'

export const useNavigateLocation = () => {
  const { path } = usePath()
  const navigate = useNavigate()
  const [flags] = useState(getPathFlags(path))
  const {
    isLocale
  } = flags

  useEffect(() => {
    // reset locale in path
    if (isLocale && path.includes(MainRoutes.SERVICES)) {
      navigate(MainRoutes.SERVICES)
    }

    if (isLocale && path.includes(MainRoutes.PRIVACY)) {
      navigate(MainRoutes.PRIVACY)
    }

    if (isLocale && path.includes(MainRoutes.REFUND)) {
      navigate(MainRoutes.REFUND)
    }
  }, [])
}
