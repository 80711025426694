export const lightTheme = {
  colors: {
    white: '#FFFFFF',
    button: 'linear-gradient(90deg, #7a63d2, #b266d2)',
    amethyst: '#dc8aff',
    black: '#000000',
    text: 'rgba(255, 255, 255, 0.9)',
    lightWhite: 'rgba(255, 255, 255, 0.1)',
    violet: '#a992ff',
    moderateBlue: '#35fa6c4',
    cyan: '#25caac',
    mostlyBlack: '#1f1840',
    grayishBlue: '#aba7c3',
    darkBlue: '#392c76',
    lightAmethyst: '#b266d233',
    lightBackground: 'linear-gradient(0deg, #392c76, rgba(36, 32, 59, 0))',
    border: 'rgba(178, 102, 210, 0.2)',
    cancel: '#c6c6c6',
    disabledGradient: 'linear-gradient(90deg, #605d6c, #816c8b)',
    background: '#2b2354',
    cyanGradient: 'linear-gradient(90deg, #b1ede2, #25caac)',
    telegram: '#2aa7dd',
    linkedin: '#0077b7',
    burgundi: '#423184',
    hawkesBlue: '#d4d0e4',
    straitGreen: '#258a77'
  },
  fonts: {
    main: ['Montserrat', 'sans-serif'],
    defaultSize: 16
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 90,
      letterSpacing: '-0.392308px'
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 54,
      lineHeight: 1,
      letterSpacing: '-0.05em',
      textAlign: 'left'
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: 48,
      fontWeight: 700,
      letterSpacing: '-0.05em',
      lineHeight: 1,
      margin: 0
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 46,
      letterSpacing: '-0.392308px',
      margin: 0
    },
    largeText: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 90,
      letterSpacing: '-0.392308px'
    },
    letter: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 54,
      lineHeight: 1,
      letterSpacing: '-0.05em',
      textAlign: 'left'
    },
    title: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 42,
      lineHeight: 1,
      letterSpacing: '-0.392308px',
      margin: 0
    },
    boldTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 32,
      letterSpacing: '-0.392308px'
    },
    description: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 28,
      letterSpacing: '-0.392308px',
      lineHeight: 1.2
    },
    subTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 28,
      letterSpacing: '-0.392308px'
    },
    header: {
      fontFamily: 'Montserrat',
      fontSize: 40,
      fontWeight: 600,
      letterSpacing: '-0.05em',
      lineHeight: 1
    },
    regularHeader: {
      margin: 0,
      fontSize: 36,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      lineHeight: 1
    },
    boldHeader: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 36,
      letterSpacing: '-0.39 px',
      margin: 0
    },
    body: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 24,
      letterSpacing: '-0.39 px',
      margin: 0
    },
    lightBody: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 20,
      letterSpacing: '-0.392308px',
      textAlign: 'left'
    },
    button: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 19,
      letterSpacing: '-0.392308px',
      textAlign: 'left'
    },
    buttonSmall: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 18,
      letterSpacing: '-0.392308px'
    },
    largeBadge: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 17,
      lineHeight: 1.4
    },
    badge: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 16
      // letterSpacing: '-0.392308px'
    },
    markTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 16,
      margin: 0
    },
    mark: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
      textAlign: 'left'
    },
    regularMark: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 15,
      letterSpacing: '-0.392308px',
      margin: 0
    },
    lightMark: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 15,
      margin: 0
    },
    caption: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 14,
      letterSpacing: '0.45px'
    },
    lightCaption: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.392308px'
    },
    setting: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 12,
      lineHeight: 1.4
    }
  },
  breakpoints: {
    xs: {
      flex: 1,
      minWidth: 244,
      maxWidth: 244
    },
    sm: {
      flex: 2,
      minWidth: 244,
      maxWidth: 328
    },
    md: {
      flex: 3,
      minWidth: 244,
      maxWidth: 620
    },
    lg: {
      minWidth: 244,
      maxWidth: 980,
      flex: 4
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  spacing: {
    unit: 16
  },
  content: {
    maxWidth: 1216,
    margin: 'auto'
  },
  boxShadow: '2px 4px 10px rgba(196, 205, 213, 0.5)',
  link: {
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '.25em 1em'
  },
  cursor: 'pointer'
}

export type Color = keyof typeof lightTheme.colors

type LightType = typeof lightTheme

export interface LightTheme extends LightType {}
