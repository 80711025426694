import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    background: theme.colors.background,
    bottom: 0,
    zIndex: 3,
    padding: '8px 0',
    '-webkit-box-shadow': '0px -16px 61px -29px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '0px -16px 61px -29px rgba(0,0,0,0.75)',
    boxShadow: '0px -16px 61px -29px rgba(0,0,0,0.75)'
  },
  content: {
    padding: 0,
  },
  button: {
    marginLeft: 24
  }
}))
