import { ReactNode } from 'react'

export enum Orientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}
export interface ScreenProps {
  isFullDisabled?: boolean
  src: string
  alt: string
  orientation?: Orientation
  children: ReactNode
  onModalOpen: (e: any) => void
}
