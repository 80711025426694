import React from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import { Column, Content, Link, Row } from '../../../../components'
import { LightTheme } from '../../../../theme'

import { useStyle } from './bot-contact.styles'

export const BotContact = () => {
  const classes = useStyle()
  const theme: LightTheme = useTheme()

  return (
    <Column id="bot_contact">
      <Content className={classes.botContent}>
        <Column fullWidth>
          <Column alignContent="center">
            <Link
              title="Telegram"
              name="Telegram"
              rel="nofollow"
              target="_blank"
              href="https://t.me/holovachpro"
            >
              <Row className={classes.iconContainer}>
                <Row className={classes.telegramIcon}>
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    height="25"
                    width="25"
                    color={theme.colors.white}
                  />
                </Row>
              </Row>
            </Link>
          </Column>
        </Column>
      </Content>
    </Column>
  )
}
