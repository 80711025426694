import React from 'react'
import { useTheme } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import { LightTheme } from '../../theme'
import { concatClassNames } from '../helpers'

import { Row } from '../row'
import { Text } from '../text'

import { BadgeProps } from './badge.types'
import { useStyle } from './badge.styles'

export const Badge = ({
  className = '',
  preset = 'accent',
  text = '',
  tx
}: BadgeProps) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const { [preset]: modifier } = classes

  return (
    <Row
      className={concatClassNames(className, modifier, classes.badge)}
      fullWidth
    >
      <FontAwesomeIcon
        icon={faCircle}
        height="30"
        width="30"
        color={theme.colors.text}
      />
      <Text text={text} tx={tx} />
    </Row>
  )
}
