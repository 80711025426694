import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { Column } from '../column'
import { Icon } from '../icon'
import { Text } from '../text'
import { Row } from '../row'
import { concatClassNames, hasWhiteSpace } from '../helpers'

import { InputProps } from './input.types'
import { useStyle } from './input.styles'

export const Input: FC<InputProps> = ({
  className = '',
  classNameInput,
  children,
  type = '',
  name = '',
  isColored = true,
  placeholder = '',
  placeholderTx,
  defaultValue,
  icon,
  iconFill,
  onChange,
  onBlur,
  onIconClick,
  ...props
}) => {
  const intl = useIntl()
  const classes = useStyle()
  const ref = useRef<HTMLInputElement>(null)
  const [error, changeError] = useState({ text: '', tx: '' })
  const [value, changeValue] = useState(defaultValue)
  const colorClasses = isColored ? classes.filled : classes.unfilled

  useEffect(() => {
    changeValue(defaultValue)
  }, [defaultValue])

  const formatedPlaceholder = placeholderTx
    ? intl.formatMessage({
        id: placeholderTx,
        defaultMessage: placeholderTx
      })
    : placeholder

  const handleOnChangeOnBlur = (flag: boolean) => {
    if (onBlur) {
      onBlur(flag)
    }
  }

  const handleOnBlur = (event: any) => {
    // if type = email => this checker
    // if type = password => this checker
    // and changeError with correct text, tx values
    const { value: inputValue } = event.currentTarget

    if (!inputValue) {
      changeError({ text: '', tx: '' })
      handleOnChangeOnBlur(false)
      return
    }

    if (type === 'email') {
      const regexp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
      const isEmailCorrect = regexp.test(inputValue)

      if (!isEmailCorrect) {
        changeError({ text: 'Incorrect email', tx: 'error.email' })
        handleOnChangeOnBlur(true)
      } else {
        changeError({ text: '', tx: '' })
        handleOnChangeOnBlur(false)
      }
      return
    }

    if (type === 'name') {
      const isWhiteSpace = hasWhiteSpace(inputValue)
      // TODO: check if there is a word after white space

      if (!isWhiteSpace) {
        changeError({ text: 'Add name or surname', tx: 'error.name' })
        handleOnChangeOnBlur(true)
      } else {
        changeError({ text: '', tx: '' })
        handleOnChangeOnBlur(false)
      }
      return
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.currentTarget
    if (onChange) {
      // @ts-ignore
      onChange(inputValue)
      // checkOnError(inputValue)
    }
    changeValue(inputValue)
  }

  return (
    <Column fullHeight className={className}>
      <input
        {...props}
        ref={ref}
        name={name}
        className={concatClassNames(
          classNameInput,
          colorClasses,
          classes.input
        )}
        placeholder={formatedPlaceholder}
        type={type}
        value={value}
        autoComplete="off"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <Row className={classes.error} fullWidth justifyContent="flex-start">
        <Text
          text={error.text}
          // @ts-ignore
          tx={error.tx}
          preset="lightCaption"
          color="violet"
        />
      </Row>
      {children}
      {icon && (
        <Icon
          className={classes.icon}
          name={icon}
          fill={iconFill}
          onClick={onIconClick}
        />
      )}
    </Column>
  )
}
