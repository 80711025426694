import React, { FC, useEffect, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { Icon, IconName } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { CheckboxProps } from './checkbox.types'
import { useStyle } from './checkbox.styles'
import { concatClassNames } from '../helpers'

export const Checkbox: FC<CheckboxProps> = ({
  className = '',
  id,
  width = 16,
  height = 16,
  defaultChecked,
  value,
  color = 'text',
  preset,
  disabled = false,
  isUndo = false,
  tx,
  text = '',
  description = '',
  onChange,
  onBlur
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme, description })
  const [checked, changeChecked] = useState(defaultChecked)

  const icon = checked ? IconName.CHECK_BOX_ACTIVE : IconName.CHECK_BOX_INACTIVE
  const iconColor = isUndo ? theme.colors.white : theme.colors.cyan

  const defDisabled = disabled ? 'disabled' : undefined
  const [isDisabled, changeIsDisabled] = useState(defDisabled)
  const disabledStyle = isDisabled ? classes.disabled : ''
  const disabledIconColor = isDisabled ? theme.colors.black : iconColor

  useEffect(() => {
    changeChecked(defaultChecked)
  }, [defaultChecked])

  useEffect(() => {
    const updatedStatus = disabled ? 'disabled' : undefined
    changeIsDisabled(updatedStatus)
  }, [disabled])

  const handleOnChange = () => {
    if (!isDisabled) {
      const newStatus = !checked
      changeChecked(newStatus)

      if (onChange) {
        onChange(newStatus)
      }
    }
  }

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(checked)
    }
  }

  return (
    <Row
      id={id}
      className={concatClassNames(classes.checkbox, className)}
      justifyContent="flex-start"
      onClick={handleOnChange}
    >
      <Icon
        width={width}
        height={height}
        name={icon}
        fill={disabledIconColor}
        onMouseLeave={handleOnBlur}
      />
      {value && (
        <Text
          className={`${classes.value} ${disabledStyle}`}
          color={color}
          preset="lightCaption"
          text={value}
        />
      )}
      {text && (
        <Text
          text={text}
          tx={tx}
          preset={preset}
          color={color}
          className={classes.text}
        />
      )}
    </Row>
  )
}
