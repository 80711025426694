import { lazy } from 'react'

export type ClassName = string | undefined

export const getComponent = (name: string) => (mod: any) => ({
  default: mod[name]
})

export const lazyComponent = <T>(
  componentImport: Promise<T>,
  component: string
) => lazy(() => componentImport.then(getComponent(component)))

export const concatClassNames = (...arr: ClassName[]) =>
  arr.reduce((acc, className) => {
    if (acc && className) {
      return `${acc} ${className}`
    }

    if (className) {
      return className
    }

    return acc
  }, '')

export const hasWhiteSpace = (text: string) => /\s/g.test(text)

export const removeHash = () => {
  setTimeout(() => {
    const loc = window.location

    if (loc.hash) {
      window.history.replaceState('', document.title, loc.pathname)
    }
  }, 5)
}

export const scrollToTop = () => {
  document.documentElement.scrollTo({
    top: 0,
    behavior: 'smooth'
  })

  document.querySelector('body')?.scrollTo(0, 0)
}

export const getIsAllFilled = (obj: any) =>
  Object.values(obj).every((value) => Boolean(value) === true)
