import { useEffect, useState } from 'react'

import { MainRoutes } from '../routes'
import { usePath } from './usePath'

export const getPathFlags = (path: string) => {
  const isHomeUA = path === MainRoutes.HOME_UA
  const isHomeEN = path === MainRoutes.HOME_EN
  const isHomeGeneral = path === MainRoutes.HOME

  const isHome = isHomeGeneral || isHomeUA || isHomeEN
  const isTerms = path === MainRoutes.TERMS || path.includes(MainRoutes.TERMS)
  const isPrivacy =
    path === MainRoutes.PRIVACY || path.includes(MainRoutes.PRIVACY)
  const isServices =
    path === MainRoutes.SERVICES || path.includes(MainRoutes.SERVICES)
  const isRefund =
    path === MainRoutes.REFUND || path.includes(MainRoutes.REFUND)
  const isServingLinks = isPrivacy || isRefund

  const isUALocale = path.includes('/ua')
  const isENLocale = path.includes('/en')
  const isRULocale = path.includes('/ru')
  const isLocale = isUALocale || isENLocale || isRULocale
  const isRoute = isServices || isPrivacy || isTerms || isRefund

  return {
    isHome,
    isTerms,
    isPrivacy,
    isServices,
    isRefund,
    isServingLinks,
    isUALocale,
    isENLocale,
    isRULocale,
    isLocale,
    isRoute
  }
}

export const useCurrentPage = () => {
  const { path } = usePath()

  const [flags, changeFlags] = useState(getPathFlags(path))
  const {
    isHome,
    isTerms,
    isPrivacy,
    isServices,
    isRefund,
    isServingLinks,
    isUALocale,
    isENLocale,
    isRULocale
  } = flags

  useEffect(() => {
    const updFlags = getPathFlags(path)
    changeFlags(updFlags)
  }, [path])

  return {
    isHome,
    isTerms,
    isPrivacy,
    isServices,
    isRefund,
    isServingLinks,
    isUALocale,
    isENLocale,
    isRULocale
  }
}
