import React from 'react'

import {
  Badge,
  Column,
  concatClassNames,
  Content,
  GradientTitle,
  Row,
  Text
} from '../../../../components'
import { Details } from './components'

import { useStyle } from './projects-list.styles'

export const ProjectsList = () => {
  const classes = useStyle()

  return (
    <Row
      id="projects"
      className={classes.projects}
      alignItems="flex-start"
      fullWidth
    >
      <Row className={classes.background} fullWidth />
      <Content className={classes.content}>
        <Row
          className={classes.container}
          fullWidth
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Column className={classes.brief} alignItems="flex-start">
            <GradientTitle tx="projects.title" text="Projects list" />
            <Text
              tx="projects.intro"
              text="Experience by domains"
              color="white"
              preset="header"
            />
            <Text
              tx="projects.desc"
              text="Work on long-term projects in different domains. Front-end development, UX improvement, requirements clarification, negotiation with clients on scope and features."
              color="text"
              preset="markTitle"
            />
          </Column>
          <Row className={classes.list}>
            <Column
              className={classes.project}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Badge
                preset="secondary"
                tx="projects.badge1"
                text="Social Network"
              />
              <Text
                className={classes.description}
                tx="projects.name1"
                text="Themes, applications for a social network platform"
                color="white"
                preset="body"
              />
              <Text
                className={classes.extra}
                tx="projects.desc1"
                text="Full-stack application development"
                color="text"
                preset="mark"
              />
              <Details />
            </Column>
            <Column
              className={concatClassNames(classes.project, classes.small)}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Badge tx="projects.badge2" text="Travel" />
              <Text
                className={classes.description}
                tx="projects.name2"
                text="US-based flight search and booking engine"
                color="white"
                preset="body"
              />
              <Text
                className={classes.extra}
                tx="projects.desc2"
                text="Creating features, fixing main functionality. Work with client team."
                color="text"
                preset="mark"
              />
              <Details />
            </Column>
            <Column
              className={concatClassNames(classes.project, classes.small)}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Badge tx="projects.badge3" text="Travel" />
              <Text
                className={classes.description}
                tx="projects.name3"
                text="Middle East based flight search and booking engine"
                color="white"
                preset="body"
              />
              <Text
                className={classes.extra}
                tx="projects.desc3"
                text="Creating UI, maintaining functionality."
                color="text"
                preset="mark"
              />
              <Details />
            </Column>
            <Column
              className={classes.project}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Badge
                preset="button"
                tx="projects.badge4"
                text="Online service"
              />
              <Text
                className={classes.description}
                tx="projects.name4"
                text="Data entry and manipulations from UI. Application management system."
                color="white"
                preset="body"
              />
              <Text
                className={classes.extra}
                tx="projects.desc4"
                text="Projects from scratch"
                color="text"
                preset="mark"
              />
              <Details remote />
            </Column>
          </Row>
        </Row>
      </Content>
    </Row>
  )
}
