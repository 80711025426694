import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.button,
    borderRadius: 32,
    flex: 'none',
    height: 5,
    marginLeft: 16,
    width: 40,
    maxWidth: 'calc(100% - 15px)'
  }
}))
