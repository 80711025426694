import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    border: `2px solid ${theme.colors.amethyst}`,
    borderRadius: 12,
    padding: 24,
    minWidth: 280,
    maxWidth: 280,
    minHeight: 512,
    '& > *:not(:first-child)': {
      marginTop: 24
    }
  },
  name: {},
  review: {},
  modalIcon: {
    position: 'absolute',
    right: 12,
    bottom: 12,
    zIndex: 4,
    color: theme.colors.amethyst,
    cursor: 'pointer'
  },
  '@media screen and (max-width: 800px)': {
    container: {
      minWidth: 250,
      maxWidth: 250,
      minHeight: 560,
    }
  },
  '@media screen and (max-width: 500px)': {
    container: {
      minWidth: 220,
      maxWidth: 220,
      minHeight: 'auto',
    }
  },
}))
