import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Text, concatClassNames } from '../../components'
import { MainRoutes } from '../../routes'

import { GoBtnProps } from './go-btn.types'
import { useStyle } from './go-btn.styles'

export const GoBtn = ({
  className = '',
  route = MainRoutes.SERVICES,
  name,
  tx = 'btn.go',
  text = 'Go'
}: GoBtnProps) => {
  const classes = useStyle()
  const navigate = useNavigate()
  const [link, changeLink] = useState(route)

  const handleOnNavigate = (to: MainRoutes) => () => {
    navigate(to)
  }

  useEffect(() => {
    changeLink(route)
  }, [route])

  return (
    <Button
      name={name}
      preset="secondary"
      className={concatClassNames(className, classes.button)}
      onClick={handleOnNavigate(link)}
    >
      <Text tx={tx} text={text} />
    </Button>
  )
}
