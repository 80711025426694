import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import { Column, ProjectsList } from '../../components'
import { useStoredLocale } from '../../hooks'
import {
  Indicators,
  AboutMe,
  ContactUs,
  Technologies,
  Testimonials,
  ProjectScreens,
  Banner,
  PromoPrice
} from './compontents'

import { useStyle } from './main.styles'

export const Landing = () => {
  const classes = useStyle()

  const intl = useIntl()
  const translatedTitle = intl.formatMessage({ id: 'seo.main.title' })
  const translatedDescription = intl.formatMessage({
    id: 'seo.main.description'
  })
  const translatedKeywords = intl.formatMessage({ id: 'seo.main.keywords' })

  useStoredLocale()

  return (
    <Column fullWidth>
      <Helmet>
        <title>{translatedTitle}</title>
        <meta name="description" content={translatedDescription} />
        <meta name="keywords" content={translatedKeywords} />
        <link rel="alternate" href="https://holovach.pro/en" hrefLang="en-US" />
        <link rel="alternate" href="https://holovach.pro/en" hrefLang="en-UA" />
        <link rel="alternate" href="https://holovach.pro/ua" hrefLang="uk-UA" />
        <link
          rel="alternate"
          href="https://holovach.pro/en/"
        />
        <link
          rel="alternate"
          href="https://holovach.pro/ua/"
          hrefLang="uk-UA"
        />
        <link rel="alternate" href="https://holovach.pro/" hrefLang="uk-UA" />
        <link rel="canonical" href="https://holovach.pro" />
      </Helmet>

      <Column fullHeight fullWidth className={classes.container}>
        <Banner />
        <Technologies />
        <Indicators />
        <AboutMe />
        <ProjectsList />
        <ProjectScreens />
        <PromoPrice />
        <Testimonials />
        <ContactUs />
      </Column>
    </Column>
  )
}
