import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ArrowLink,
  Column,
  Row,
  Content,
  GradientTitle,
  Icon,
  IconName,
  Text
} from '../../../components'
import { MainRoutes } from '../../../routes'

import { useStyle } from './advantages.styles'

export const Advantages = () => {
  const classes = useStyle()
  const navigate = useNavigate()

  const handleOnMoreAboutClick = () => {
    navigate(MainRoutes.HOME)
  }

  return (
    <Column id="advantages" className={classes.background} fullWidth>
      <Content className={classes.advantages}>
        <Row
          className={classes.container}
          alignItems="flex-start"
          justifyContent='space-between'
          fullHeight
          fullWidth
        >
          <Icon className={classes.dots} name={IconName.DOTS} />
          <Column className={classes.info} alignItems="flex-start">
            <GradientTitle tx="advantages.acquaintance" text="Знакомство" />
            <Column className={classes.description} alignItems="flex-start">
              <Text
                tx="advantages.experience"
                text="10+ years of experience in web development"
                preset="button"
              />
            </Column>
            <Column className={classes.description} alignItems="flex-start">
              <Text
                tx="advantages.projects"
                text="Projects"
                preset="button"
                color="amethyst"
              />
              <Column className={classes.text} alignItems="flex-start">
                <Text
                  tx="advantages.projects.desc1"
                  text="More than 21 developed projects and 4 of them were long-term."
                  preset="lightBody"
                />
              </Column>
            </Column>

            <Column className={classes.description} alignItems="flex-start">
              <Text
                tx="advantages.achievements"
                text="Achievements"
                preset="button"
                color="amethyst"
              />
              <Column className={classes.text} alignItems="flex-start">
                <ul className={classes.list}>
                  <li className={classes.item}>
                    <Text
                      tx="advantages.achievements.desc1"
                      text="Direct work with an international team."
                      preset="lightBody"
                    />
                  </li>
                  <li className={classes.item}>
                    <Text
                      tx="advantages.achievements.desc2"
                      text="Direct work with an international team."
                      preset="lightBody"
                    />
                  </li>
                  <li className={classes.item}>
                    <Text
                      tx="advantages.achievements.desc3"
                      text="Direct work with an international team."
                      preset="lightBody"
                    />
                  </li>
                </ul>
              </Column>
            </Column>

            <Column className={classes.description} alignItems="flex-start">
              <Text
                tx="advantages.benefits"
                text="Benefits"
                preset="button"
                color="amethyst"
              />
              <Column className={classes.text} alignItems="flex-start">
                <ul className={classes.list}>
                  <li className={classes.item}>
                    <Text
                      tx="advantages.benefits.desc1"
                      text="First product version (SPA) 1 week after development begins."
                      preset="lightBody"
                    />
                  </li>
                  <li className={classes.item}>
                    <Text
                      tx="advantages.benefits.desc2"
                      text="Development and promotion from a single source. Don't waste your time \nsearching for multiple vendors."
                      preset="lightBody"
                    />
                  </li>
                  <li className={classes.item}>
                    <Text
                      tx="advantages.benefits.desc3"
                      text="Results-driven work ethic."
                      preset="lightBody"
                    />
                  </li>
                  <li className={classes.item}>
                    <Text
                      tx="advantages.benefits.desc4"
                      text="1 month of free technical support for the developed web application."
                      preset="lightBody"
                    />
                  </li>
                </ul>
              </Column>
            </Column>

            <ArrowLink
              className={classes.link}
              name="More about"
              onClick={handleOnMoreAboutClick}
            >
              <Text
                text="More about me"
                tx="nav.moreAbout"
                preset="buttonSmall"
                color="violet"
              />
            </ArrowLink>
          </Column>

          <Column className={classes.image}>
            <Icon name={IconName.INTRO} height={400} width={200} />
          </Column>
        </Row>
      </Content>
    </Column>
  )
}
