import { DetailedHTMLProps, HTMLAttributes, MouseEvent, ReactNode } from 'react'

import { Color } from '../../theme'
import { TextTranslate } from '../text'

export enum ButtonTypeName {
  accent = 'accent',
  secondary = 'secondary',
  disabled = 'disabled',
  cancel = 'cancel',
  empty = 'empty',
  transparent = 'transparent'
}

export interface ButtonPresetName {
  button: string
  accent: string
  secondary: string
  disabled: string
  cancel: string
  empty: string
  transparent: string
}

export interface ButtonProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: ReactNode
  preset?: keyof ButtonPresetName
  type?: 'submit' | 'button' | 'reset'
  name?: string
  disabled?: boolean
  className?: string
  isSmall?: boolean
  isLoaded?: boolean
  color?: Color
  text?: string
  tx?: TextTranslate
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}
