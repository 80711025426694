import { images } from '../../../../assets'

export interface IProjectScreensProps {
  isServices?: boolean
}

export enum PROJECTS {
  BUCKET_LIST = 'bucket-list',
  LOGIN_UI = 'login-ui',
  PHOTO_GALLERY = 'photo-gallery',
  GENERATE_USERS = 'generate-users',
  SOLAR = 'solar',
  PERSONAL = 'personal'
}

export const PROJECT_IMAGES = {
  [PROJECTS.BUCKET_LIST]: images.BucketListApp,
  [PROJECTS.LOGIN_UI]: images.LoginUIPreview,
  [PROJECTS.PHOTO_GALLERY]: images.PhotoGalleryPreview,
  [PROJECTS.GENERATE_USERS]: images.GenerateUsersPreview,
  [PROJECTS.SOLAR]: images.SolarLanding,
  [PROJECTS.PERSONAL]: images.PersonalLanding
}