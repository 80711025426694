import React from 'react'
import {
  Column,
  Row,
  Text,
  Content,
  concatClassNames,
  GradientTitle
} from '../../../components'

import { useStyle } from './steps.styles'

export const Steps = () => {
  const classes = useStyle()

  return (
    <Column id="steps" className={classes.background} fullWidth>
      <Content className={classes.steps}>
        <Column fullWidth>
          <GradientTitle tx="stages.title" text="Work steps" />
          <Column className={classes.content}>
            <Row className={classes.row} fullWidth>
              <Column className={classes.cell}>
                <Row>
                  <Text
                    className={classes.number}
                    text="1"
                    preset="letter"
                    color="cancel"
                  />
                  <Column className={classes.item}>
                    <Text
                      className={classes.text}
                      text="First call"
                      tx="stages.first"
                      preset="buttonSmall"
                      color="white"
                    />
                  </Column>
                </Row>
                <Column
                  className={concatClassNames(
                    classes.item,
                    classes.description
                  )}
                >
                  <Text
                    className={classes.text}
                    text="Clarifying the purpose, answering questions"
                    tx="stages.first.text"
                    preset="mark"
                    color="white"
                  />
                </Column>
              </Column>

              <Column className={classes.cell}>
                <Row>
                  <Text
                    className={classes.number}
                    text="2"
                    preset="letter"
                    color="cancel"
                  />
                  <Column>
                    <Column className={classes.item}>
                      <Text
                        className={classes.text}
                        text="Brief"
                        tx="stages.second"
                        preset="buttonSmall"
                        color="white"
                      />
                    </Column>
                  </Column>
                </Row>
                <Column
                  className={concatClassNames(
                    classes.item,
                    classes.description
                  )}
                >
                  <Text
                    className={classes.text}
                    text="Question list for understanding your business"
                    tx="stages.second.text"
                    preset="mark"
                    color="white"
                  />
                </Column>
              </Column>

              <Column className={classes.cell}>
                <Row>
                  <Text
                    className={classes.number}
                    text="3"
                    preset="letter"
                    color="cancel"
                  />
                  <Column className={classes.item}>
                    <Text
                      className={classes.text}
                      text="Analysis"
                      tx="stages.third"
                      preset="buttonSmall"
                      color="white"
                    />
                  </Column>
                </Row>
                <Column
                  className={concatClassNames(
                    classes.item,
                    classes.description
                  )}
                >
                  <Text
                    className={classes.text}
                    text="Requirements analysis, \nsolutions suggestion based \non the brief"
                    tx="stages.third.text"
                    preset="mark"
                    color="white"
                  />
                </Column>
              </Column>

              <Column className={classes.cell}>
                <Row>
                  <Text
                    className={classes.number}
                    text="4"
                    preset="letter"
                    color="cancel"
                  />
                  <Column className={classes.item}>
                    <Text
                      className={classes.text}
                      text="Planning"
                      tx="stages.fourth"
                      preset="buttonSmall"
                      color="white"
                    />
                  </Column>
                </Row>
                <Column
                  className={concatClassNames(
                    classes.item,
                    classes.description
                  )}
                >
                  <Text
                    className={classes.text}
                    text="Requirements clarification, \ndesigning, content approval"
                    tx="stages.fourth.text"
                    preset="mark"
                    color="white"
                  />
                </Column>
              </Column>

              <Column className={classes.cell}>
                <Row>
                  <Text
                    className={classes.number}
                    text="5"
                    preset="letter"
                    color="cancel"
                  />
                  <Column className={classes.item}>
                    <Text
                      className={classes.text}
                      text="Development"
                      tx="stages.fifth"
                      preset="buttonSmall"
                      color="white"
                    />
                  </Column>
                </Row>
                <Column
                  className={concatClassNames(
                    classes.item,
                    classes.description
                  )}
                >
                  <Text
                    className={classes.text}
                    text="Start of development or launching your campaign"
                    tx="stages.fifth.text"
                    preset="mark"
                    color="white"
                  />
                </Column>
              </Column>

              <Column className={classes.cell}>
                <Row>
                  <Text
                    className={classes.number}
                    text="6"
                    preset="letter"
                    color="cancel"
                  />
                  <Column className={classes.item}>
                    <Text
                      className={classes.text}
                      text="Release"
                      tx="stages.sixth"
                      preset="buttonSmall"
                      color="white"
                    />
                  </Column>
                </Row>
                <Column
                  className={concatClassNames(
                    classes.item,
                    classes.description
                  )}
                >
                  <Text
                    className={classes.text}
                    text="Deployment after testing, system monitoring"
                    tx="stages.sixth.text"
                    preset="mark"
                    color="white"
                  />
                </Column>
              </Column>
            </Row>
          </Column>
        </Column>
      </Content>
    </Column>
  )
}
