import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    background: theme.colors.darkBlue
  },
  container: {
    scrollMarginTop: '50px',
    scrollBehavior: 'smooth',
    marginTop: 40,
    padding: 0
  },
  services: {
    flexWrap: 'wrap',
    gap: '32px'
  },
  filledService: {
    background: theme.colors.mostlyBlack,
    border: `2px solid ${theme.colors.mostlyBlack}!important`
  },
  service: {
    flex: 1,
    alignItems: 'flex-start',
    border: `2px solid ${theme.colors.border}`,
    boxShadow: '2px 4px 6px 1px #00000033',
    borderRadius: 10,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: 400,
    width: 360,
    justifyContent: 'flex-start',
    padding: 40,
    transitionDuration: '1000ms'
  },
  circleIcon: {
    minWidth: 74,
    minHeight: 74,
    border: '2px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '50%',
    transition: 'all ease 2s',
    boxShadow: '2px 4px 6px 1px #00000033',
    '& > *': {
      height: '1.5em'
    },
    '&:hover': {
      transform: 'rotateY(180deg)',
      transition: 'all ease 2s'
    }
  },
  title: {
    marginTop: 28,
    textAlign: 'center',
    width: '100%'
  },
  list: {
    marginTop: 28,
    paddingLeft: 22
  },
  filledIcon: {
    border: 'none',
    background: 'linear-gradient(90deg, #7a63d2, #b266d2)'
  },
  item: {
    '&::marker': {
      content: '',
      borderLeft: '4px solid #CCC',
      paddingLeft: 10,
      color: theme.colors.text
    }
  },
  '@media screen and (max-width: 1100px)': {
    service: {
      padding: 14
    }
  },
  '@media screen and (min-width: 970px) and (max-width: 1320px)': {
    service: {
      maxWidth: 330,
      minWidth: 200,
      height: 460
    }
  },
  '@media screen and (max-width: 970px)': {
    services: {
      flexDirection: 'column'
    },
    service: {
      flex: 1,
      width: 460
    }
  },
  '@media screen and (max-width: 640px)': {
    service: {
      width: 'calc(100% - 28px)'
    }
  }
}))
