import React from 'react'

import { Column } from '../column'

import { GradientRectangleProps } from './gradient-rectangle.types'
import { useStyle } from './gradient-rectangle.styles'

export const GradientRectangle = ({ className }: GradientRectangleProps) => {
  const classes = useStyle()

  return (
    <Column
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    />
  )
}
