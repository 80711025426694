import React, { Suspense } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'

import {
  Column,
  Header,
  Footer,
  lazyComponent,
  concatClassNames,
  CookiesBanner
} from '../components'
import { useCurrentPage, useScrollToTop } from '../hooks'
import { Terms as TermsPage } from '../pages'

import { MainRoutes } from './main.types'
import { useStyle } from './main.styles'

const LandingPage = lazyComponent(import('../pages'), 'Landing')
const PrivacyPage = lazyComponent(import('../pages'), 'Privacy')
const ServicesPage = lazyComponent(import('../pages'), 'Services')
const RefundPage = lazyComponent(import('../pages'), 'Refund')

export const MainRouter = () => {
  const classes = useStyle()
  const navigate = useNavigate()
  const { isServices } = useCurrentPage()
  const mainContainerStyles = isServices ? classes.servicesPage : ''

  useScrollToTop()

  const handleOnNavigate = (to: string) => () => {
    navigate(to)
  }

  const fallback = <Column fullFilledHeight fullWidth></Column>

  return (
    <Column
      className={concatClassNames(classes.container, mainContainerStyles)}
      justifyContent="space-between"
    >
      <Header
        className={classes.header}
        onLogoClick={handleOnNavigate(MainRoutes.HOME)}
      />

      <Column fullWidth className={classes.contentContainer}>
        <Suspense fallback={fallback}>
          <Routes>
            <Route path={MainRoutes.HOME} element={<LandingPage />} />

            <Route path={MainRoutes.HOME_UA}>
              <Route index element={<LandingPage />} />
              <Route path={'services'} element={<ServicesPage />} />
            </Route>

            <Route path={MainRoutes.HOME_EN}>
              <Route index element={<LandingPage />} />
              <Route path={'services'} element={<ServicesPage />} />
            </Route>

            <Route path={MainRoutes.SERVICES} element={<ServicesPage />} />
            <Route path={MainRoutes.TERMS} element={<TermsPage />} />
            <Route path={MainRoutes.PRIVACY} element={<PrivacyPage />} />
            <Route path={MainRoutes.REFUND} element={<RefundPage />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </Column>

      <Footer
        aboutTx="footer.name"
        aboutText="Iryna Holovach"
        termsTx="footer.terms"
        termsText="Terms"
      />

      <CookiesBanner />
    </Column>
  )
}
