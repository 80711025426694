import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  link: {
    marginTop: 42,
    height: 60,
    width: 'auto',
    transitionDuration: '600ms',
    '& > *': {
      cursor: 'pointer'
    },
    '&:hover': {
      transform: 'scale(1.05, 1.05)'
    }
  },
  arrowRight: {
    marginLeft: 12,
    '& > *': {
      color: theme.colors.violet
    }
  }
}))
