import React, { SetStateAction, useEffect } from 'react'

import { Locale, useLocale } from '../../providers'
import { useCurrentPage } from '../../hooks'
import { Color } from '../../theme'
import { MainRoutes } from '../../routes'

import { Content } from '../content'
import { Row } from '../row'
import { Icon } from '../icon'
import { Column } from '../column'
import { IconName } from '../icon'
import { Text, TextPresets } from '../text'
import { Link } from '../link'

import { HeaderProps } from './header.types'
import { useStyle } from './header.styles'
import { concatClassNames } from '../helpers'

export const Header = ({
  backgroundColor,
  className = '',
  leftIcon,
  onLogoClick
}: HeaderProps) => {
  const { isHome, isServices, isServingLinks, isRefund } = useCurrentPage()

  const classes = useStyle({ backgroundColor })
  const { currentLocale, changeCurrentLocale } = useLocale()
  const headerStyle = !isHome ? classes.customHeader : ''
  const aboutTextColor = isServingLinks ? 'burgundi' : 'white'
  const isAboutMeLink = !isHome || isRefund

  const menuList = [
    {
      tx: 'header.about',
      text: 'About',
      id: '#about-me'
    },
    { tx: 'header.projects', text: 'Projects', id: '#projects' },
    { tx: 'header.services', text: 'Services', id: MainRoutes.SERVICES },
    { tx: 'header.contact', text: 'Contact', id: '#contact' }
  ]

  const getLangColor = (locale: Locale) => {
    const isActive = locale === currentLocale
    return {
      isActive,
      color: isActive ? 'amethyst' : ('cancel' as Color),
      preset: isActive ? 'button' : ('mark' as TextPresets)
    }
  }

  const onChangeLocale = (locale: SetStateAction<Locale>) => () => {
    localStorage.setItem('locale', JSON.stringify(locale))
    changeCurrentLocale(locale)
  }

  useEffect(() => {
    // TODO: rewrite as a helper, and add removeListener
    let anchorlinks = document.querySelectorAll('a[href^="#"]')

    //@ts-ignore
    for (let item of anchorlinks) {
      // relitere
      item.addEventListener('click', (e: any) => {
        let hashval = item.getAttribute('href')
        let target = document.querySelector(hashval)
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
        history.pushState(null, '', hashval)
        e.preventDefault()
      })
    }
  }, [])

  return (
    <Column className={`${className} ${headerStyle}`} fullWidth>
      <Content className={classes.langs}>
        <Row className={classes.langList} fullWidth justifyContent="flex-end">
          {/* TODO: create a new component */}
          <Row onClick={onChangeLocale(Locale.EN)}>
            <Text
              className={classes.lang}
              text={Locale.EN}
              color={getLangColor(Locale.EN).color}
              preset={getLangColor(Locale.EN).preset}
            />
          </Row>
          <Row onClick={onChangeLocale(Locale.UA)}>
            <Text
              className={classes.lang}
              text={Locale.UA}
              color={getLangColor(Locale.UA).color}
              preset={getLangColor(Locale.UA).preset}
            />
          </Row>
          <Row onClick={onChangeLocale(Locale.RU)}>
            <Text
              className={classes.lang}
              text={Locale.RU}
              color={getLangColor(Locale.RU).color}
              preset={getLangColor(Locale.RU).preset}
            />
          </Row>
        </Row>
      </Content>

      <Content className={classes.header}>
        <Row className={classes.logo} onClick={onLogoClick}>
          {leftIcon || <Icon name={IconName.LOGO} width={130} height={60} />}
        </Row>

        <Row
          className={concatClassNames(classes.links, classes.rightContainer)}
        >
          {isHome && (
            <Row className={classes.links}>
              {menuList.map((item: any) => (
                <Link
                  className={classes.item}
                  key={item.text}
                  name={item.text}
                  href={`${item.id}`}
                >
                  <Text
                    tx={item.tx}
                    text={item.text}
                    preset="buttonSmall"
                    color="white"
                  />
                </Link>
              ))}
            </Row>
          )}
          {isAboutMeLink && (
            <Row>
              <Link
                className={concatClassNames(classes.item, classes.link)}
                name="About me"
                href=""
                onClick={onLogoClick}
              >
                <Text
                  text="About me"
                  tx="nav.aboutMe"
                  preset="buttonSmall"
                  color={aboutTextColor}
                />
              </Link>
            </Row>
          )}
          {isServingLinks && (
            <Row>
              <Link
                className={concatClassNames(classes.item, classes.link)}
                name="Services"
                href={MainRoutes.SERVICES}
                onClick={onLogoClick}
              >
                <Text
                  text="Services"
                  tx="header.services"
                  preset="buttonSmall"
                  color={aboutTextColor}
                />
              </Link>
            </Row>
          )}
          {isServices && (
            <Row className={classes.links}>
              <Link
                className={concatClassNames(classes.item, classes.link)}
                name="Contact"
                href="#contact"
              >
                <Text
                  text="Contact"
                  tx="header.contact"
                  preset="buttonSmall"
                  color={aboutTextColor}
                />
              </Link>
              <Link
                className={concatClassNames(classes.item, classes.link)}
                name="Book call"
                href="#consultation"
              >
                <Text
                  text="Free call"
                  tx="nav.consultation"
                  preset="buttonSmall"
                  color={aboutTextColor}
                />
              </Link>
            </Row>
          )}
        </Row>
      </Content>
    </Column>
  )
}
