import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  fbShare: {
    display: 'flex',
    alignItems: 'center'
  },
  fbIcon: {
    marginLeft: 14
  },
})
