import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Column, Row, Text, Content } from '../../../components'
import { images } from '../../../assets'

import { useStyle } from './advertisement-service.styles'

export const AdvertisementService = () => {
  const classes = useStyle()

  return (
    <Column id="advertising" className={classes.background} fullWidth>
      <Content className={classes.advertising}>
        <Row
          className={classes.container}
          justifyContent="space-around"
          alignItems="flex-start"
          fullHeight
          fullWidth
        >
          <Row className={classes.imgContainer}>
            <LazyLoadImage
              className={classes.img}
              src={images.Analytics}
              width={'100%'}
              height={'100%'}
              alt="Analytics"
            />
          </Row>
          <Column className={classes.suggestion}>
            <Row>
              <Text
                className={classes.number}
                text="2"
                preset="letter"
                color="cancel"
              />
              <Column>
                <Column className={classes.item}>
                  <Text
                    text="Google Advertising"
                    tx="services.ads"
                    preset="buttonSmall"
                    color="burgundi"
                  />
                  <Text
                    className={classes.label}
                    text="1 month support"
                    //text="*SEO optimization"
                    tx="services.ads.feature"
                    preset="lightCaption"
                    color="white"
                  />
                </Column>
              </Column>
            </Row>
            <Column fullWidth alignItems="flex-start">
              <Text
                className={classes.serviceTitle}
                text="Setup"
                tx="service.setup"
                color="burgundi"
              />
              <Text
                className={classes.serviceText}
                text="Создание и сопровождение поисковых и торговых рекламных кампаний; настройка инструментов Google Ads, Analytics, Merchant Center, Tag Manager, Search Console."
                tx="service.setup.description"
                preset="mark"
                color="mostlyBlack"
              />

              <Text
                className={classes.serviceTitle}
                text="Аудит"
                tx="service.audit"
                color="burgundi"
              />
              <Text
                className={classes.serviceText}
                text="Базовый, а также комплексный аудит рекламного аккаунта и сайта"
                tx="service.audit.description"
                preset="mark"
                color="mostlyBlack"
              />
            </Column>
          </Column>
        </Row>
      </Content>
    </Column>
  )
}
