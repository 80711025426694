import React from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'
import { useResponsive } from '../../providers'

import { Column } from '../column'
import { Content } from '../content'
import { Row } from '../row'
import { Text, TextTranslate } from '../text'

import { FooterProps } from './footer.types'
import { useStyle } from './footer.styles'

export const Footer = <T extends TextTranslate>({
  className = '',
  aboutText = 'About Us',
  aboutTx,
  onAboutUsNavigate,
  ...props
}: FooterProps<T>) => {
  const theme: LightTheme = useTheme()
  const { isMobile } = useResponsive()
  const classes = useStyle({ theme })

  const currentYear = new Date().getFullYear()
  const FlexibleContainer = isMobile ? Column : Row
  const containerClasses = isMobile ? classes.columnItems : ''

  return (
    <Column className={classes.content} fullWidth>
      <Content {...props} className={`${classes.separator} ${className}`}>
        <FlexibleContainer
          className={containerClasses}
          fullWidth
          justifyContent="space-between"
        >
          <Row alignItems="flex-start">
            <Row>
              <Text
                text={aboutText}
                tx={aboutTx}
                color="text"
                preset="badge"
                onClick={onAboutUsNavigate}
              />
            </Row>
          </Row>
          <Row alignItems="flex-start">
            <Text
              tx="footer.copyright"
              text={`© Copyright ${currentYear}. All Rights Reserved.`}
              values={{
                currentYear
              }}
              preset="regularMark"
            />
          </Row>
        </FlexibleContainer>
      </Content>
    </Column>
  )
}
