import { createUseStyles } from 'react-jss'

import { images } from '../../../../assets'
import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    height: '1000px'
  },
  container: {
    backgroundImage: `url(${images.Main})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    flex: 1
  },
  h1: {
    display: 'none'
  },
  shadow: {
    alignItems: 'center',
    background: theme.colors.lightBackground,
    flex: 'none',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '101%',
    justifyContent: 'flex-end',
    left: 0,
    margin: '0 0 0 0',
    position: 'absolute',
    top: 0,
    width: '100%',
    maxWidth: '100%'
  },
  link: {
    marginTop: 42,
    height: 60,
    width: 'auto',
    transitionDuration: '600ms',
    '& > *': {
      cursor: 'pointer',
      padding: '0 18px'
    },
    '&:hover': {
      transform: 'scale(1.05, 1.05)'
    }
  },
  arrowRight: {
    marginLeft: 12,
    '& > *': {
      color: theme.colors.white
    }
  },
  brief: {
    alignItems: 'flex-start',
    zIndex: 2
  },
  column: {
    width: '50%'
  },
  about: {
    marginTop: 0
  },
  '@media screen and (max-width: 970px)': {
    column: {
      width: '100%'
    }
  },
  '@media screen and (max-width: 740px)': {
    container: {
      backgroundImage: `url(${images.MainMobile})`,
    }  
  },
  '@media screen and (min-width: 1590px)': {
    content: {
      height: '1600px'
    }
  }
}))
