import React, { ChangeEvent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { concatClassNames } from '../helpers'

import { TextareaProps } from './textarea.types'
import { useStyle } from './textarea.styles'
import { Column } from '../column'

export const Textarea = ({
  name = '',
  className = '',
  placeholder = '',
  placeholderTx,
  defaultValue,
  onChange,
  ...props
}: TextareaProps) => {
  const intl = useIntl()
  const classes = useStyle()

  const [message, changeMessage] = useState(defaultValue)
  const formatedPlaceholder = placeholderTx
    ? intl.formatMessage({
        description: 'mark',
        id: placeholderTx,
        defaultMessage: placeholderTx
      })
    : placeholder

  const handleChangeValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = event
    const { value } = target
    onChange(value)
    changeMessage(value)
  }

  useEffect(() => {
    changeMessage(defaultValue)
  }, [defaultValue])

  return (
    <Column fullWidth>
      <textarea
        {...props}
        name={name}
        className={concatClassNames(className, classes.textarea)}
        placeholder={formatedPlaceholder}
        value={message}
        onChange={handleChangeValue}
      />
    </Column>
  )
}
