import React from 'react'

import { Row } from '../row'
import { Text } from '../text'
import { GradientBox } from '../gradient-box'

import { GradientTitleProps } from './gradient-title.types'
import { useStyle } from './gradient-title.styles'

export const GradientTitle = ({
  className,
  tx,
  text,
  color = 'violet'
}: GradientTitleProps) => {
  const classes = useStyle()

  return (
    <Row className={className} fullWidth justifyContent="flex-start">
      <Text tx={tx} text={text} preset="button" color={color} />
      <GradientBox className={classes.gradientBox} />
    </Row>
  )
}
