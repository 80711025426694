import React, { createContext, FC, useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'

import {
  Locale,
  LocaleContextValue,
  LocaleProviderProps,
  StructuredMessages
} from './locale.types'

const localeEN: StructuredMessages = require('../../i18n/en.json')
const localeUA: StructuredMessages = require('../../i18n/ua.json')
const localeRU: StructuredMessages = require('../../i18n/ru.json')

const localeData: Record<Locale, Record<string, string>> = {
  [Locale.EN]: localeEN, // Default language
  [Locale.UA]: localeUA,
  [Locale.RU]: localeRU
}

export const localeNames: Record<Locale, string> = {
  [Locale.EN]: 'ENG',
  [Locale.UA]: 'UA',
  [Locale.RU]: 'RU'
}

const DEFAULT_LOCALE = Locale.UA

export const LocaleContext = createContext<LocaleContextValue>({
  localeData,
  currentLocale: Locale.UA,
  changeCurrentLocale: () => {}
})

export const LocaleProvider: FC<LocaleProviderProps> = ({
  locale: localeOverride,
  children
}) => {
  const locale = localeOverride || DEFAULT_LOCALE
  const [currentLocale, changeCurrentLocale] = useState(locale)
  const [locales] = useState(localeData)

  const contextState = {
    localeData: locales,
    currentLocale,
    changeCurrentLocale
  }

  const handleOnError = () => {}

  return (
    <LocaleContext.Provider value={contextState}>
      <IntlProvider
        defaultLocale={DEFAULT_LOCALE}
        locale={currentLocale}
        messages={locales[currentLocale]}
        key={locale}
        onError={handleOnError}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)
