import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import {
  Column,
  Content,
  GradientTitle,
  Text,
  Row,
  concatClassNames
} from '../../../../components'

import { TListProps, TQuestionsProps } from './questions.types'
import { useStyle } from './questions.styles'

export const Questions = ({
  isQuestions = true
}: TQuestionsProps) => {
  const classes = useStyle({ isQuestions })
  const [list, changeList] = useState<TListProps>({
    case1: false,
    case2: false,
    case3: false
  })

  const handleOnItemClick = (id: string) => () => {
    changeList({ ...list, [id]: !list[id] })
  }

  const getArrowClass = (isClicked: boolean) =>
    isClicked ? concatClassNames(classes.rotate, classes.icon) : classes.icon

  return (
    <Column id="questions" className={classes.questions} fullWidth>
      <Content className={classes.container}>
        {isQuestions && (
          <Column fullWidth>
            <GradientTitle tx="faq.title" text="Questions" />
            <Column className={classes.list} fullWidth>
              <Column
                alignItems="flex-start"
                fullWidth
                className={classes.item}
                onClick={handleOnItemClick('case1')}
              >
                <Row justifyContent="space-between" fullWidth>
                  <Text
                    tx="faq.case1"
                    text="If you have an online store (from 20 items)..."
                    color="white"
                  />
                  <FontAwesomeIcon
                    className={getArrowClass(list.case1)}
                    icon={faChevronDown}
                    height="30"
                    width="30"
                    color="white"
                  />
                </Row>
                {list.case1 && (
                  <Text
                    className={classes.text}
                    preset="mark"
                    tx="faq.case1.text"
                    text="If you have an online store (from 20 items)..."
                  />
                )}
              </Column>

              <Column
                alignItems="flex-start"
                fullWidth
                className={classes.item}
                onClick={handleOnItemClick('case2')}
              >
                <Row justifyContent="space-between" fullWidth>
                  <Text
                    tx="faq.case2"
                    text="If you have an online store (from 20 items)..."
                    color="white"
                  />
                  <FontAwesomeIcon
                    className={getArrowClass(list.case2)}
                    icon={faChevronDown}
                    height="30"
                    width="30"
                    color="white"
                  />
                </Row>

                {list.case2 && (
                  <Text
                    className={classes.text}
                    preset="mark"
                    tx="faq.case2.text"
                    text="If you have an online page as a professional or a small store..."
                  />
                )}
              </Column>

              <Column
                alignItems="flex-start"
                fullWidth
                className={classes.item}
                onClick={handleOnItemClick('case3')}
              >
                <Row justifyContent="space-between" fullWidth>
                  <Text
                    tx="faq.case3"
                    text="If you have an online store (from 20 items)..."
                    color="white"
                  />
                  <FontAwesomeIcon
                    className={getArrowClass(list.case3)}
                    icon={faChevronDown}
                    height="30"
                    width="30"
                    color="white"
                  />
                </Row>
                {list.case3 && (
                  <Text
                    className={classes.text}
                    preset="mark"
                    tx="faq.case3.text"
                    text="If you are a professional fitness trainer or photographer or ..."
                  />
                )}
              </Column>
            </Column>
          </Column>
        )}
      </Content>
    </Column>
  )
}
