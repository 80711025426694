import React from 'react'

import { concatClassNames, removeHash } from '../helpers'

import { LinkProps } from './link.types'
import { useStyle } from './link.styles'

export const Link = ({
  className = '',
  target = '',
  href = '',
  name,
  title = '',
  rel = '',
  children,
  isButton = false,
  onClick,
  ...props
}: LinkProps) => {
  const classes = useStyle()
  const linkClasses = isButton
    ? concatClassNames(classes.button, classes.secondary)
    : ''

  const onLinkClick = () => {
    removeHash()

    if (onClick) {
      onClick()
    }
  }

  return (
    <a
      rel={rel}
      title={title}
      target={target}
      href={href}
      // @ts-ignore
      name={name}
      aria-label={name}
      className={concatClassNames(className, classes.link, linkClasses)}
      onClick={onLinkClick}
      {...props}
    >
      {children}
    </a>
  )
}
