import React, { useState, useRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import {
  Column,
  Content,
  Text,
  Row,
  GradientTitle,
  ImageModal
} from '../../../../components'
import { useResponsive } from '../../../../providers'
import { images } from '../../../../assets'
import { useOutsideAlerter } from '../../../../hooks'
import { Screens, Orientation } from './screen'

import {
  IProjectScreensProps,
  PROJECTS,
  PROJECT_IMAGES
} from './project-screens.types'
import { useStyle } from './project-screens.styles'

export const ProjectScreens = ({
  isServices = false
}: IProjectScreensProps) => {
  const classes = useStyle()
  const screensClasses = isServices ? classes.screens : ''
  const titleColor = isServices ? 'darkBlue' : undefined
  const descriptionColor = isServices ? 'black' : 'text'

  const { isTablet } = useResponsive()
  const [isImageModal, changeIsImageModal] = useState(false)
  const [modalImageSrc, changeModalImageSrc] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  const preset = isTablet ? 'badge' : 'button'

  const handleOnOpenModal = (id: PROJECTS) => (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    changeModalImageSrc(PROJECT_IMAGES[id])
    changeIsImageModal(true)
  }
  const handleOnCloseModal = () => changeIsImageModal(false)

  useOutsideAlerter(ref, isImageModal, handleOnCloseModal)

  return (
    <Column id="screens" className={screensClasses} fullWidth>
      <Content className={classes.container}>
        <Column fullWidth>
          <GradientTitle
            tx="projects.screens"
            text="Screens"
            color={titleColor}
          />
          <Text
            className={classes.description}
            tx="projects.screens.desc"
            text="Скріни деяких проєктів із портфоліо для ознайомлення з моїми роботами. Короткий опис над зображенням та розширений вигляд в окремому віконці. Зображення мають водяний знак, як захист від копіювання конкурентами."
            color={descriptionColor}
            preset="markTitle"
          />
          <Row
            className={classes.list}
            fullWidth
            alignItems="flex-start"
            justifyContent="space-around"
          >
            <Screens
              src={images.BucketListPreview}
              alt="Bucket list web application"
              orientation={Orientation.VERTICAL}
              onModalOpen={handleOnOpenModal(PROJECTS.BUCKET_LIST)}
            >
              <Text
                className={classes.text}
                tx="project.example1"
                text="Bucket list web application"
                preset={preset}
                color="white"
              />
            </Screens>

            <Screens
              isFullDisabled
              src={images.GenerateUsersPreview}
              alt="Social media plarform application for generation users"
              orientation={Orientation.VERTICAL}
              onModalOpen={handleOnOpenModal(PROJECTS.GENERATE_USERS)}
            >
              <Text
                className={classes.text}
                tx="project.example4"
                text="Social media plarform application for generating users"
                preset={preset}
                color="white"
              />
            </Screens>

            <Screens
              isFullDisabled
              src={images.LoginUIPreview}
              alt="Creation of Login User Interface"
              orientation={Orientation.VERTICAL}
              onModalOpen={handleOnOpenModal(PROJECTS.LOGIN_UI)}
            >
              <Text
                className={classes.text}
                tx="project.example2"
                text="Creation of Login User Interface"
                preset={preset}
                color="white"
              />
            </Screens>

            <Screens
              isFullDisabled
              src={images.PhotoGalleryPreview}
              alt="Creation of photo gallery mobile application"
              orientation={Orientation.VERTICAL}
              onModalOpen={handleOnOpenModal(PROJECTS.PHOTO_GALLERY)}
            >
              <Text
                className={classes.text}
                tx="project.example3"
                text="Creation of photo gallery mobile application"
                preset={preset}
                color="white"
              />
            </Screens>

            <Screens
              src={images.PersonalPreview}
              alt="Personal Landing Page"
              orientation={Orientation.VERTICAL}
              onModalOpen={handleOnOpenModal(PROJECTS.PERSONAL)}
            >
              <Text
                className={classes.text}
                tx="project.example6"
                text=""
                preset={preset}
                color="white"
              />
            </Screens>

            <Screens
              src={images.SolarPreview}
              alt="Installing solar panels company"
              orientation={Orientation.VERTICAL}
              onModalOpen={handleOnOpenModal(PROJECTS.SOLAR)}
            >
              <Text
                className={classes.text}
                tx="project.example5"
                text="Creating landing page for Solar panels installation company"
                preset={preset}
                color="white"
              />
            </Screens>
          </Row>
        </Column>

        {isImageModal && (
          <ImageModal ref={ref} onClose={handleOnCloseModal}>
            <LazyLoadImage
              className={classes.img}
              src={modalImageSrc}
              width="100%"
              height="100%"
              alt="test"
            />
          </ImageModal>
        )}
      </Content>
    </Column>
  )
}
