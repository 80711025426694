import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { TextStyleProps } from './text.types'

const colorByProps =
  (theme: LightTheme) =>
  ({ color }: TextStyleProps) =>
    color ? theme.colors[color] : theme.colors.text

export const useStyle = createUseStyles((theme: LightTheme) => ({
  h1: {
    ...theme.typography.h1,
    color: colorByProps(theme)
  },
  h2: {
    ...theme.typography.h2,
    color: colorByProps(theme)
  },
  h3: {
    ...theme.typography.h3,
    color: colorByProps(theme)
  },
  h4: {
    ...theme.typography.h4,
    color: colorByProps(theme)
  },
  title: {
    ...theme.typography.title,
    color: colorByProps(theme)
  },
  boldTitle: {
    ...theme.typography.boldTitle,
    color: colorByProps(theme)
  },
  description: {
    ...theme.typography.description,
    color: colorByProps(theme)
  },
  subTitle: {
    ...theme.typography.subTitle,
    color: colorByProps(theme)
  },
  header: {
    ...theme.typography.header,
    color: colorByProps(theme)
  },
  regularHeader: {
    ...theme.typography.regularHeader,
    color: colorByProps(theme)
  },
  boldHeader: {
    ...theme.typography.boldHeader,
    color: colorByProps(theme)
  },
  body: {
    ...theme.typography.body,
    color: colorByProps(theme)
  },
  lightBody: {
    ...theme.typography.lightBody,
    color: colorByProps(theme)
  },
  button: {
    ...theme.typography.button,
    color: colorByProps(theme)
  },
  buttonSmall: {
    ...theme.typography.buttonSmall,
    color: colorByProps(theme)
  },
  largeBadge: {
    ...theme.typography.largeBadge,
    color: colorByProps(theme)
  },
  badge: {
    ...theme.typography.badge,
    color: colorByProps(theme)
  },
  markTitle: {
    ...theme.typography.markTitle,
    color: colorByProps(theme)
  },
  mark: {
    ...theme.typography.mark,
    color: colorByProps(theme)
  },
  regularMark: {
    ...theme.typography.regularMark,
    color: colorByProps(theme)
  },
  lightMark: {
    ...theme.typography.lightMark,
    color: colorByProps(theme)
  },
  caption: {
    ...theme.typography.caption,
    color: colorByProps(theme)
  },
  lightCaption: {
    ...theme.typography.lightCaption,
    color: colorByProps(theme)
  },
  setting: {
    ...theme.typography.setting,
    color: colorByProps(theme)
  },
  largeText: {
    ...theme.typography.largeText,
    color: colorByProps(theme)
  },
  letter: {
    ...theme.typography.letter,
    color: colorByProps(theme)
  },
  defaultCursor: {
    cursor: 'default',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none',
    whiteSpace: 'pre-line'
  },
  textWithHint: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hint: {
    position: 'absolute',
    top: '-10px',
    left: 0,
    bottom: 0,
    visibility: ({ hover }: TextStyleProps) => (hover ? 'visible' : 'hidden'),
    zIndex: 2
  }
}))
