import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  contact: {
    background: theme.colors.mostlyBlack
  },
  container: {
    marginTop: 80,
    padding: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  brief: {
    marginTop: 24
  },
  circleIcon: {
    width: 70,
    height: 70,
    border: '2px solid rgba(255, 255, 255, 0.05)',
    borderRadius: '50%'
  },
  row: {
    '& > :not(:first-child)': {
      marginLeft: 36
    }
  },
  img: {
    width: 420,
    borderRadius: '0 30px 0 0',
    zIndex: 1
  },
  gradientBox: {
    marginLeft: 8
  },
  rectangle: {
    left: -14,
    bottom: -14
  },
  title: {
    marginTop: 32
  },
  descr: {
    marginTop: 32
  },
  item: {
    width: '100%'
  },
  inputContainer: {
    marginTop: 10,
    width: '100%'
  },
  input: {
    width: 'calc(100% - 50px)'
  },
  textarea: {
    marginTop: 0
  },
  agree: {
    marginTop: 20
  },
  message: {
    width: '100%',
    height: 210,
    lineHeight: 1.4,
    padding: 25,
    marginTop: 10,
    maxWidth: '100%',
    boxSizing: 'border-box',
    color: theme.colors.text,
    border: `2px solid ${theme.colors.border}`,
    borderRadius: 10,
    backgroundColor: 'transparent'
  },
  button: {
    height: 60,
    width: 'auto',
    padding: '0 28px',
    marginLeft: 24
  },
  arrowRight: {
    marginLeft: 24
  },
  modal: {
    width: 480,
    height: 480
  },
  form: {
    width: '100%'
  },
  invisible: {
    display: 'none'
  },
  '@media screen and (max-width: 970px)': {
    item: {
      width: '100%'
    },
    row: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0
      }
    },
  },
  '@media screen and (max-width: 640px)': {
    agree: {
      flexDirection: 'column',
      '& > *:not(:first-child)': {
        marginTop: 24
      }
    }
  }
}))
