import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  background: {
    background: 'rgba(45, 18, 151, 0.5)',
    overflow: 'hidden'
  },
  advantages: {
    zIndex: 2,
    padding: '80px 0'
  },
  description: {
    '& >:not(:first-child)': {
      marginTop: 10
    },
    '& > *': {
      marginTop: 28
    }
  },
  image: {
    marginLeft: 48
  },
  text: {
    lineHeight: 1.3
  },
  container: {},
  info: {},
  dots: {
    position: 'absolute'
  },
  list: {
    paddingLeft: 20,
    margin: 0
  },
  link: {
    marginTop: -14
  },
  item: {
    '&::marker': {
      color: theme.colors.amethyst
    }
  },
  '@media screen and (max-width: 900px)': {
    container: {
      flexDirection: 'column-reverse'
    },
    image: {
      display: 'none'
    },
    info: {
      marginTop: 24
    }
  }
}))
