import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  textarea: {
    color: theme.colors.white,
    resize: 'none',
    outline: 'none',
    '&:focus, &:focus-visible': {
      border: `2px solid ${theme.colors.amethyst} `
    },
    ...theme.typography.markTitle,
    '&::placeholder': {
      color: theme.colors.grayishBlue,
      ...theme.typography.markTitle
    },
    '&:-internal-autofill-selected, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
    {
      backgroundColor: 'transparent!important',
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.colors.darkBlue} inset`
    },
  }
}))
