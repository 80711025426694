import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const usePath = () => {
  const location = useLocation()
  const { pathname, hash } = location
  const [path, changePath] = useState({ pathname, hash })

  useEffect(() => {
    changePath({ pathname, hash })
  }, [pathname, hash, location])

  return { path: path.pathname, hash: path.hash, location }
}
