import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Row, Link } from '../../components'

import { IArrowLinkProps } from './arrow-link.types'
import { useStyle } from './arrow-link.styles'

export const ArrowLink = ({ className, href, name, onClick, children }: IArrowLinkProps) => {
  const classes = useStyle()

  return (
    <Link className={className} name={name} href={href} onClick={onClick}>
      <Row className={classes.link}>
        {children}
        <Row className={classes.arrowRight}>
          <FontAwesomeIcon icon={faLongArrowRight} height="30" width="30" />
        </Row>
      </Row>
    </Link>
  )
}
