import React from 'react'
import { ThemeProvider } from 'react-jss'
import { BrowserRouter } from 'react-router-dom'

import { LocaleProvider, ResponsiveProvider } from './providers'
import { MainRouter } from './routes'
import { lightTheme, LightTheme } from './theme'

const App = () => {
  const theme: LightTheme = lightTheme
  
  const storedLocale = localStorage.getItem('locale')
  const locale = storedLocale && JSON.parse(storedLocale)

  return (
    <BrowserRouter>
      <LocaleProvider locale={locale}>
        <ResponsiveProvider>
          <ThemeProvider theme={theme}>
            <MainRouter />
            <div id="modal" />
            <div id="image-modal" />
          </ThemeProvider>
        </ResponsiveProvider>
      </LocaleProvider>
    </BrowserRouter>
  )
}

export default App
