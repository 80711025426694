import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

import { TQuestionsStyleProps } from './questions.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  questions: {
    background: 'rgba(45, 18, 151, 0.5)'
  },
  container: {
    marginTop: ({ isQuestions }: TQuestionsStyleProps) => isQuestions ? 80 : 0,
    padding: 0
  },
  list: {
    padding: '28px 0 80px 0',
    width: 'calc(100% - 40px)',
    '& > *:not(:first-child)': {
      marginTop: 16
    }
  },
  item: {
    border: `2px solid ${theme.colors.lightWhite}`,
    borderRadius: 14,
    padding: 20,
    height: 'auto',
    transition: 'height 0.5s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '2px 4px 6px 0px #00000033'
    }
  },
  text: {
    marginTop: 14,
    height: 'auto',
    transition: 'height 0.3s ease-in-out'
  },
  icon: {
    transition: 'all 0.3s ease-in-out'
  },
  rotate: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease-in-out'
  }
}))
