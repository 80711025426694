import React from 'react'

import { Content, Row } from '../../components'

import { useStyle } from './terms.styles'

export const Terms = () => {
  const classes = useStyle()

  return (
    <Content className={classes.terms}>
      <Row fullWidth justifyContent="space-between">
        Terms
      </Row>
    </Content>
  )
}
