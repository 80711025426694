import React, { ForwardedRef, forwardRef, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { TextComponentProps, TextProps } from './text.types'
import { useStyle } from './text.styles'

const spanComponent = ({ children, ...props }: TextComponentProps) => (
  <span {...props}>{children}</span>
)
const divComponent = ({ children, ...props }: TextComponentProps) => (
  <div {...props}>{children}</div>
)

export const presetDivComponents = {
  h1: ({ children, ...props }: TextComponentProps) => (
    <h1 {...props}>{children}</h1>
  ),
  h2: ({ children, ...props }: TextComponentProps) => (
    <h2 {...props}>{children}</h2>
  ),
  h3: ({ children, ...props }: TextComponentProps) => (
    <h3 {...props}>{children}</h3>
  ),
  h4: ({ children, ...props }: TextComponentProps) => (
    <h4 {...props}>{children}</h4>
  ),
  title: divComponent,
  boldTitle: divComponent,
  description: divComponent,
  subTitle: divComponent,
  header: divComponent,
  regularHeader: divComponent,
  boldHeader: divComponent,
  body: divComponent,
  lightBody: divComponent,
  button: divComponent,
  buttonSmall: divComponent,
  largeBadge: divComponent,
  badge: divComponent,
  markTitle: divComponent,
  mark: divComponent,
  regularMark: divComponent,
  lightMark: divComponent,
  caption: divComponent,
  lightCaption: divComponent,
  setting: divComponent,
  largeText: divComponent,
  letter: divComponent
}

export const presetTextComponents = {
  h1: ({ children, ...props }: TextComponentProps) => (
    <h1 {...props}>{children}</h1>
  ),
  h2: ({ children, ...props }: TextComponentProps) => (
    <h2 {...props}>{children}</h2>
  ),
  h3: ({ children, ...props }: TextComponentProps) => (
    <h3 {...props}>{children}</h3>
  ),
  h4: ({ children, ...props }: TextComponentProps) => (
    <h4 {...props}>{children}</h4>
  ),
  title: spanComponent,
  boldTitle: spanComponent,
  description: spanComponent,
  subTitle: spanComponent,
  header: spanComponent,
  regularHeader: spanComponent,
  boldHeader: spanComponent,
  body: spanComponent,
  lightBody: spanComponent,
  button: spanComponent,
  buttonSmall: spanComponent,
  largeBadge: spanComponent,
  badge: spanComponent,
  markTitle: spanComponent,
  mark: spanComponent,
  regularMark: spanComponent,
  lightMark: spanComponent,
  caption: spanComponent,
  lightCaption: spanComponent,
  setting: spanComponent,
  largeText: spanComponent,
  letter: spanComponent
}

export const Text = forwardRef(
  (
    {
      className = '',
      description,
      text = '',
      tx,
      values,
      preset = 'badge',
      maxLength = 15,
      hint,
      ...props
    }: TextProps,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    const [hover, changeHover] = useState(false)
    const classes = useStyle({ ...props, hover })

    const Component = presetTextComponents[preset]
    const hideHint = useMemo(
      () => text && text?.length < maxLength,
      [text?.length, maxLength]
    )
    const hintTextOverflow = useMemo(
      () => (hint && !hideHint ? classes.textWithHint : ''),
      [hint, hideHint]
    )

    const handleOnHoverState = (state: boolean) => () => {
      changeHover(state)
    }

    if (!tx) {
      return (
        <Component
          className={`${className} ${hintTextOverflow} ${classes.defaultCursor} ${classes[preset]}`}
          ref={ref}
          onMouseOver={handleOnHoverState(true)}
          onMouseOut={handleOnHoverState(false)}
          {...props}
        >
          {text}
        </Component>
      )
    }

    return (
      <Component
        className={`${className} ${hintTextOverflow} ${classes.defaultCursor} ${classes[preset]}`}
        ref={ref}
        onMouseOver={handleOnHoverState(true)}
        onMouseOut={handleOnHoverState(false)}
        {...props}
      >
        <FormattedMessage
          id={tx || text}
          description={description}
          defaultMessage={text}
          values={values}
        />
      </Component>
    )
  }
)
