import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  botContent: {
    padding: 0
  },
  gradientBox: {
    marginLeft: 8
  },
  title: {
    textAlign: 'center'
  },
  telegramIcon: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    background: theme.colors.telegram,
    '&': {
      transition: 'height 0.3s ease-in-out, width 0.3s ease-in-out'
    },
    '&:hover': {
      width: 40,
      height: 40,
      transition: 'height 0.3s ease-in-out, width 0.3s ease-in-out'
    }
  },
  separator: {
    borderTop: '4px solid rgb(60 57 74 / 41%)',
    marginTop: 60
  },
  iconContainer: {
    width: 45,
    height: 45,
  }
}))
