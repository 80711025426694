import React, { forwardRef } from 'react'

import { Modal } from '../modal'
import { Column } from '../column'

import { ImageModalProps } from './image-modal.types'
import { useStyle } from './image-modal.styles'
import { concatClassNames } from '../helpers'

export const ImageModal = forwardRef<HTMLDivElement, ImageModalProps>(
  ({ className = '', children, onClose, onAutoClose = onClose }, ref) => {
    const classes = useStyle()

    return (
      <Modal ref={ref} className={classes.modal} modal="image-modal" onClose={onAutoClose}>
        <Column
          className={concatClassNames(className, classes.content)}
          fullWidth
          justifyContent="space-between"
        >
          <Column fullWidth>{children}</Column>
        </Column>
      </Modal>
    )
  }
)
